import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { taskService } from "../../services/taskService";
import { utilService } from "../../services/utilService";
import AskDeletePopup from "./components/AskDeletePopup";
import AskEditPopup from "./components/AskEditPopup";
import { employeeService } from "../../services/employeeService";
import { authService } from "../../services/authService";

export const taskOptionsMap = {
    Done: "בוצע",
    Pending: "ממתין",
    PendingReview: "ממתין לביקורת",
    InProgress: "בטיפול",
    Stuck: "תקוע"
}

var searchTimeOut = null;
export default function ManageTasks({ }) {
    const [allTasks, setAllTasks] = useState([]);
    const [filterTasks, setFilterTasks] = useState([]);
    const [search, setSearch] = useState('');
    const [askDeleteTask, setAskDeleteTask] = useState(null);
    const [askEditTask, setAskEditTask] = useState(null);
    const [employees, setEmployees] = useState([]);
    let navigate = useNavigate();
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);
    const [permissions, setPermissions] = useState(authService.getPermissions())

    console.log(allTasks);

    useEffect(() => {
        document.title = "Step By Step - Tasks";
    }, [])

    useEffect(() => {
        updateAllTasks();
        updateEmployees();
    }, [])

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterTasks();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allTasks, employees])

    const updateAllTasks = async () => {
        setIsTableLoad(true);
        const task = await taskService.get();
        setAllTasks(task);
    }

    const updateEmployees = async () => {
        const employees = await employeeService.get();
        setEmployees(employees);
    }

    const updateFilterTasks = async () => {
        setIsTableLoad(false);
        const filteredTask = allTasks
            .filter(task => task.title.includes(search))
            .sort((task1, task2) => {
                console.log(task1);
                if (task1.status === "Done" && task2.status !== "Done") return 1;
                if (task2.status === "Done" && task1.status !== "Done") return -1;
                if (task1.priority > task2.priority) return -1;
                if (task1.priority < task2.priority) return 1;
                if (task1.deadline > task2.deadline) return 1;
                if (task1.deadline < task2.deadline) return -1;
                else return 0;
            })
            .map(task => {
                const employee = employees.find(emoloyee => emoloyee.id === task.owner)
                return {
                    ...task,
                    deadline: utilService.timestampToDDMMYYYY(task.deadline),
                    priority: <span className="primary-color" >{utilService.priorityNumberToStars(task.priority)}</span>,
                    owner: employee ? ((employee.firstName || employee.lastName) ? `${employee.firstName} ${employee.lastName}` : employee.email) : '',
                    status: (
                        <select
                            className="sbs-select type"
                            value={task.status}
                            onChange={({ target }) => updateTaskStatus(target.value, task.id)}
                        >
                            {
                                Object.keys(taskOptionsMap).map(key => (
                                    <option key={key} value={key}>{taskOptionsMap[key]}</option>
                                ))
                            }
                        </select>
                    )

                }
            })
        setFilterTasks(filteredTask);
    }

    const onOptionAskDeleteTask = (id) => {
        setAskDeleteTask(allTasks.find(task => task.id === id));
    }

    const onOptionAskEditTask = (id) => {
        setAskEditTask(allTasks.find(task => task.id === id));
    }

    const deleteAskedDeletedTask = async () => {
        await taskService.remove(askDeleteTask.id);
        updateAllTasks();
        setAskDeleteTask(null);
    }

    const editAskedEditdTask = async (task) => {
        if (task.id) await taskService.edit(task);
        else await taskService.add(task)
        updateAllTasks();
        setAskEditTask(null);
    }

    const updateTaskStatus = async (status, taskId) => {
        const editedTask = { ...allTasks.find(task => task.id === taskId), status }
        await taskService.edit(editedTask)
        updateAllTasks();
    }


    return (
        <div className="main-container page">
            <div className="manage-tasks-page">
                <div className='header'>
                    <span className='sbs-title'>ניהול משימות</span>
                    <span className='sbs-subtitle'>סה"כ {allTasks.length} משימות</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש משימה"
                />

                <SbsTable
                    columnMap={{ "title": "משימה", "owner": "מבצע המשימה", "status": "סטטוס", "priority": "עדיפות", deadline: 'תאריך סיום' }}
                    isHaveOptions={permissions.some(permission => permission === 'ManageTasks')}
                    isLoad={isTableLoad}
                    metaData={filterTasks}
                    options={[
                        { icon: 'delete', cb: onOptionAskDeleteTask },
                        { icon: 'edit', cb: onOptionAskEditTask },
                    ]}
                ></SbsTable>


                <AskDeletePopup
                    askDeleteTask={askDeleteTask}
                    deleteAskedDeletedTask={deleteAskedDeletedTask}
                    setAskDeleteTask={setAskDeleteTask}
                />

                <AskEditPopup
                    askEditTask={askEditTask}
                    employees={employees}
                    editAskedEditdTask={editAskedEditdTask}
                    setAskEditTask={setAskEditTask}
                    taskOptionsMap={taskOptionsMap}
                />




                {
                    permissions.some(permission => permission === 'ManageTasks') &&
                    <button onClick={() => { setAskEditTask(taskService.getEmpty()) }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>
                }

            </div>
        </div >
    )
}