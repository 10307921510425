import { useEffect, useState } from "react"
import { workClockService } from "../../services/workClockService"
import { employeeService } from "../../services/employeeService"
import { utilService } from "../../services/utilService"
import SbsIcons from "../../components/SbsIcons"
import DatesProgressBar from "../../components/DatesProgressBar"
import { globalVariable } from "../../services/globalVariable"
import SbsTable from "../../components/SbsTable"
import WorkClock from "./components/WorkClock"
import AskEditPopup from "./components/AskEditPopup"
import { useNavigate, useParams } from 'react-router-dom';


export default function ViewTimeClock({ }) {
    const [employeeWorks, setEmployeeWorks] = useState([])
    const [employeeWorksMonthArr, setEmployeeWorsMonthArr] = useState([])
    const [employee, setEmployee] = useState(employeeService.getEmpty())
    const [dateShared, setDateShared] = useState(utilService.getDateShared())
    const [askEditMonth, setAskEditMonth] = useState(null);
    const { id } = useParams();
    const [isTableLoad, setIsTableLoad] = useState(false);
    // const [askEditMonthWorkClocks, setAskEditMonthWorkClocks] = useState([])
    const [currMonthClocks, setCurrMonthClock] = useState(null);
    const [currMonth, setCurrMonth] = useState({
        start: +new Date(utilService.getDateShared().year, utilService.getDateShared().month - 1, 1),
        end: +new Date(utilService.getDateShared().year, utilService.getDateShared().month, 0)
    });

    useEffect(() => {
        document.title = "Step By Step - Time Clock";
    }, [])

    useEffect(() => {
        updateEmployee()
    }, [id])

    // useEffect(() => {
    //     if (dateShared && workClock) {
    //         setSumCurrWorkClockInHours(Math.floor(sumMonthWorkClock(dateShared.month, dateShared.year) / 1000 / 60 / 60))
    //     }
    // }, [workClock, dateShared])




    useEffect(() => {
        console.log(employeeWorks);
        const monthWorks = employeeWorks.reduce((acc, work) => {
            const workDateShared = utilService.getDateShared(work.from);
            const accMonth = acc.find(monthWork => monthWork.month === workDateShared.month && monthWork.year === workDateShared.year)
            if (accMonth) accMonth.works.push(work)
            else acc.push({
                year: workDateShared.year,
                month: workDateShared.month,
                works: [work]
            })
            return acc

        }, [])
        const currMonthClocksItem = monthWorks.find(monthWork => (monthWork.month === dateShared.month && monthWork.year === dateShared.year))
        setCurrMonthClock({
            // ...currMonthClocksItem,
            year: dateShared.year,
            id: `${dateShared.month}_${dateShared.year}`,
            month: globalVariable.monthNumberHebrewMap[dateShared.month],
            workClock: (
                <WorkClock
                    showButton={!id}
                    dateShared={dateShared}
                    monthWork={currMonthClocksItem ?? { year: dateShared.year, month: dateShared.month, works: [] }}
                    sumMonth={sumMonthWorkClock(dateShared.month, dateShared.year)}
                    updateWorkClock={updateUserWorkClock}

                />
            )
        }

        )

		console.log('dateShared.month, dateShared.year', dateShared.month, dateShared.year, sumMonthWorkClock(dateShared.month, dateShared.year, true))

        setEmployeeWorsMonthArr(
            monthWorks
                .filter(monthWork => !(monthWork.month === dateShared.month && monthWork.year === dateShared.year))
                .map(monthWork => {
                    const sumMonth = sumMonthWorkClock(monthWork.month, monthWork.year)
                    return {
                        ...monthWork,
                        id: `${monthWork.month}_${monthWork.year}`,
                        month: globalVariable.monthNumberHebrewMap[monthWork.month],
                        workClock: (
                            <WorkClock
                                dateShared={dateShared}
                                monthWork={monthWork}
                                sumMonth={sumMonth}
                                updateWorkClock={updateUserWorkClock}

                            />
                        )
                    }
                })
        )
    }, [employeeWorks])





    const updateUserWorkClock = async () => {
        setIsTableLoad(true);
        const updatedEmployeeWorks = await workClockService.getByEmployeeId(id ? id : 'myself');
        setEmployeeWorks(updatedEmployeeWorks);
        setIsTableLoad(false);
    }

    const updateEmployee = async () => {
        const currEmployee = await employeeService.getById(id ? id : 'myself');
        setEmployee(currEmployee);
        updateUserWorkClock()
    }

    const sumMonthWorkClock = (month, year, ss = false) => {
        const sumInMs = employeeWorks
            .filter(work => {
                const workDateShared = utilService.getDateShared(work.from);
                return workDateShared.year === year && workDateShared.month === month
            })
            .reduce((acc, item) => {
                acc += (item.to - item.from)
				ss && console.log('acc', acc)
                return acc
            }, 0)
			ss && console.log('sumInMs', sumInMs)
        return sumInMs
    }

    const onEditMonthClock = (monthAndYearStr) => {
        const [month, year] = monthAndYearStr.split('_');
        setAskEditMonth({ month: +month, year: +year })
    }





    return (
        <div className='time-clock-container'>
            <div className='main-container page'>

                <div className='view-time-clock-page flex column gap-1'>
                    <span className="sbs-title">שעון נוכחות של {employee.firstName} {employee.lastName} {employee.role}</span>


                    <SbsTable
                        columnMap={{ "year": "שנה", "month": "חודש", workClock: 'שעון עבודה נוכחי' }}
                        isHaveOptions={true}
                        isLoad={isTableLoad}
                        metaData={currMonthClocks ? [currMonthClocks] : []}
                        options={[
                            { icon: 'edit', cb: onEditMonthClock },
                        ]}
                    />

                    {
                        <SbsTable
                            columnMap={{ "year": "שנה", "month": "חודש", workClock: 'סהכ' }}
                            isHaveOptions={true}
                            isLoad={isTableLoad}
                            metaData={employeeWorksMonthArr}
                            options={[
                                { icon: 'edit', cb: onEditMonthClock },
                            ]}
                        />
                    }
                </div>

            </div>
            <div className='footer-container'>
                <div className='main-container footer'>
                    <div className="flex justify-space-between">
                        {/* <div className="flex column align-center gap-1 sum-container" >
                            <SbsIcons icon="clock" />
                            <span className="clock-text" >סה"כ שעות עבודה החודש {sumCurrWorkClockInHours}</span>
                        </div> */}
                        <div className="flex column align-center gap-1 date-progress-container" >
                            <SbsIcons icon="date" />
                            <div>
                                <DatesProgressBar
                                    startDate={currMonth.start}
                                    endDate={currMonth.end}
                                />
                            </div>
                        </div>

                        <AskEditPopup
                            askEditMonth={askEditMonth}
                            setAskEditMonth={setAskEditMonth}
                            workClock={employeeWorks}
                            updateWorkClock={updateUserWorkClock}
                            employeeId={employee.id}
                        />



                        <div className="flex align-center justify-center month-container">
                            <span> {globalVariable.monthNumberHebrewMap[dateShared.month]},{dateShared.year}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}