import { useEffect } from "react"
import { Draggable } from "react-beautiful-dnd"
import SbsIcons from "../../../components/SbsIcons"
import TrainExercisePreview from "./TrainExercisePreview"

export default function TrainExerciseList({
    exerciseTypeObject,
    trainExercisesMap,
    execisesTitleMap,
    updateTrainExeciseByKey,
    train,
    deleteTrainExecise,
    innerRef,
    placeholeder,
    execisesMap
}) {
    console.log('DEV TRAINS', train, exerciseTypeObject);


    return (
        <div
            className="min-height"
            ref={innerRef}
        >
            {
                exerciseTypeObject.execiseIds.map((execiseId, index) => (
                    // <Draggable  draggableId={execiseId} index={index} >
                    // {(provided) => (
                    <TrainExercisePreview
                        key={execiseId}
                        execiseId={execiseId}
                        execisesTitleMap={execisesTitleMap}
                        trainExercisesMap={trainExercisesMap}
                        updateTrainExeciseByKey={updateTrainExeciseByKey}
                        train={train}
                        deleteTrainExecise={deleteTrainExecise}
                        exerciseTypeObject={exerciseTypeObject}
                        index={index}
                        execisesMap={execisesMap}
						execiseIds={exerciseTypeObject.execiseIds}
                    // {...provided.dragHandleProps}
                    // {...provided.draggableProps}
                    // innerRef={provided.innerRef}
                    />
                    // )}
                    // </Draggable>
                ))
            }
            {placeholeder}
        </div>
    )
}