import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SbsStepper from "../../components/SbsStepper";
import { planService } from "../../services/planService";
import TrainsCreation from "./components/TrainsCreation";
import TrainList from "./components/TrainList";
import { useParams } from "react-router-dom";
import { customerService } from "../../services/customerService";
import { useNavigate } from "react-router-dom";
import PlanSummary from "./components/PlanSummary";
import { templateService } from "../../services/templateService";
import SbsIcons from "../../components/SbsIcons";

export default function ViewPlan() {
  const [currStep, setCurrStep] = useState(0);
  const [plan, setPlan] = useState(planService.getEmpty());
  const [customer, setCustomer] = useState(customerService.getEmpty());
  const [isStep2Disabled, setIsStep2Disabled] = useState(true);
  const [isStep3Disabled, setIsStep3Disabled] = useState(true);
  const nextStep = () => setCurrStep((currStep) => ++currStep);
  const previousStep = () => setCurrStep((currStep) => --currStep);
  const { planId, customerId, templateId } = useParams();
  let navigate = useNavigate();

  const isCanContinue = () => {
    const trains = plan.trains.map((train) => train.exerciseTypesMap);
    return trains.every((train) => {
      const excs = Object.entries(train).map(
        ([key, value]) => value.execiseIds
      );
      return !excs.some((ex) => ex?.length < 1);
    });
  };

  useEffect(() => {
    document.title = "Step By Step - Plan";
  }, []);

  useEffect(() => {
    if (!plan.trains.length)
      setIsStep2Disabled("יש להוסיף לפחות אימון אחד לתוכנית");
    else if (
      plan.trains.some(
        (train) => !train.title || !train.exerciseTypesOrder.length
      )
    )
      setIsStep2Disabled("יש למלא את כל השדות");
    else if (plan.weeklyAerobicInMinutes && plan.weeklyAerobicInMinutes <= 0)
      setIsStep2Disabled("יש להזין ערך חיובי");
    else setIsStep2Disabled(false);

    if (!plan.trains.length)
      setIsStep3Disabled("יש להוסיף לפחות אימון אחד לתוכנית");
    else if (
      plan.trains.some(
        (train) => !train.title || !train.exerciseTypesOrder.length
      )
    )
      setIsStep3Disabled("יש למלא את כל השדות");
    else if (
      plan.trains.some((train) =>
        Object.keys(train.trainExercisesMap).some(
          (key) =>
            +train.trainExercisesMap[key].numSet <= 0 ||
            +train.trainExercisesMap[key].numRepeatMin <= 0
        )
      )
    )
      setIsStep3Disabled("יש להזין ערכים חיוביים");
    else setIsStep3Disabled(false);
  }, [plan]);

  useEffect(() => {
    if (customerId) {
      setPlan((plan) => ({
        ...plan,
        customerId: customerId,
      }));
      updateCustomer(customerId);
    } else if (planId) {
      updatePlan(planId);
    } else if (templateId) {
      updateTemplate(templateId);
    }
  }, [planId, customerId, templateId]);

  const updateCustomer = async (customerId) => {
    const currCustomer = await customerService.getById(customerId);
    setCustomer(currCustomer);
  };

  const updatePlan = async (planId) => {
    console.log("planId", planId);
    const currPlan = await planService.getById(planId);
    if (currPlan) {
      setPlan(currPlan);
      if (currPlan.customerId) {
        const currCustomer = await customerService.getById(currPlan.customerId);
        setCustomer(currCustomer);
      }
    }
  };

  const updateTemplate = async (templateId) => {
    const currTemplate = await templateService.getById(templateId);
    if (currTemplate) {
      setPlan(currTemplate);
    }
  };

  const onSave = async () => {
    if (isStep2Disabled || isStep3Disabled) return;
    if (customer.id && !plan.id) {
      await planService.add(plan);
      navigate(`/view-customer/${customer.id}`);
    } else if (customer.id && plan.id) {
      await planService.edit(plan);
      navigate(`/view-customer/${customer.id}`);
    } else if (!customer.id && plan.id) {
      await templateService.edit(plan);
      navigate(`/manage-templates`);
    } else if (!customer.id && !plan.id) {
      console.log("hap");
      await templateService.add(plan);
      console.log("after");
      navigate(`/manage-templates`);
    }
  };

  const onChangesSave = async () => {
    if (isStep2Disabled || isStep3Disabled) return;
    if (customer.id && !plan.id) {
      await planService.add(plan);
    } else if (customer.id && plan.id) {
      await planService.edit(plan);
    } else if (!customer.id && plan.id) {
      await templateService.edit(plan);
    } else if (!customer.id && !plan.id) {
      await templateService.add(plan);
    }
  };

  return (
    <div className="main-container page">
      <div className="view-plan-page">
        <SbsStepper
          stepsLengh={3}
          currStep={currStep}
          setCurrStep={setCurrStep}
          disableSteps={[false, isStep2Disabled, isStep3Disabled]}
        />

        <div style={{ position: "absolute", right: "20%" }}>
          <Link
            className="button-primary"
            to={`/view-customer/${customer.id}`}
            style={{
              borderRadius: "100%",
              padding: "0.8rem",
              display: "inline-flex",
            }}
          >
            <SbsIcons icon="back" />
          </Link>
        </div>

        <br />

        <div className="page-steps">
          {currStep === 0 && (
            <TrainsCreation
              plan={plan}
              setPlan={setPlan}
              nextStep={nextStep}
              customer={customer}
              disableNextStep={isStep2Disabled}
              onSave={onSave}
              onChangesSave={onChangesSave}
            />
          )}
          {currStep === 1 && (
            <TrainList
              plan={plan}
              setPlan={setPlan}
              nextStep={nextStep}
              previousStep={previousStep}
              disableNextStep={isStep3Disabled}
              onSave={onSave}
              onChangesSave={onChangesSave}
            />
          )}
          {currStep === 2 && (
            <PlanSummary
              plan={plan}
              customer={customer}
              previousStep={previousStep}
              onSave={onSave}
              onChangesSave={onChangesSave}
			  isCanContinue={isStep2Disabled || isStep3Disabled || isCanContinue()}
            />
          )}
        </div>
      </div>
    </div>
  );
}
