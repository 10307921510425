import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const taskService = {
    get,
    remove,
    edit,
    add,
    getById,
    getEmpty
}

let tasks = [
    {
        "id": "t101",
        "title": "שדגשדג",
        "owner": "אור דמרי",
        "status": "done",
        "priority": 1,
        "deadline": Date.now()
    },
    {
        "id": "t102",
        "title": "שדגשדג",
        "owner": "אור דמרי",
        "status": "done",
        "priority": 3,
        "deadline": Date.now()
    },
    {
        "id": "t103",
        "title": "שדגשדג",
        "owner": "אור דמרי",
        "status": "done",
        "priority": 2,
        "deadline": Date.now()
    },

]

function getEmpty() {
    return {
        "id": "",
        "title": "",
        "owner": "",
        "status": "Pending",
        "priority": 1,
        "deadline": Date.now()
    }
}

const ENDPOINT = "users/tasks";

async function get() {
    const response = await httpService.get(ENDPOINT);
    const tasks = response.data.map(task => {
        return {
            id: task.id,
            title: task.description,
            owner: task.assigneeID,
            status: task.status,
            priority: task.priority,
            deadline: task.deadline
        }
    });
    return tasks;
}


async function remove(id) {
    try {

        const response = await httpService.delete(ENDPOINT + `/${id}`);
        errorService.updateError({ msg: 'המשימה נמחקה', type: 'warning', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(editedTask) {
    try {
        const body = {
            id: editedTask.id,
            description: editedTask.title,
            assigneeUserID: editedTask.owner,
            status: editedTask.status,
            priority: editedTask.priority,
            deadline: editedTask.deadline
        }
        const response = await httpService.put(ENDPOINT, body);
        errorService.updateError({ msg: 'משימה עודכנה', type: 'success', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(newTask) {
    try {
        const body = {
            description: newTask.title,
            assigneeUserID: +newTask.owner,
            status: newTask.status,
            priority: newTask.priority,
            deadline: newTask.deadline
        }
        const response = await httpService.post(ENDPOINT, body);
        errorService.updateError({ msg: 'משימה נוספה', type: 'success', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getById(id) {
    return tasks.find(task => task.id === id)
}