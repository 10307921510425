import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { menuTemplateService } from "../../services/menuTemplateService";
import AskDeletePopup from "./components/AskDeletePopup";

export function ManageMenuTemlates() {
  const [allMenuTemplates, setAllMenuTemplates] = useState([]);
  const [filterMenuTemplates, setFilterMenuTemplates] = useState([]);
  const [search, setSearch] = useState("");
  const [isTableLoad, setIsTableLoad] = useState(false);
  const [isSearchLoad, setIsSearchLoad] = useState(false);
  const [askDeleteMenuTemplate, setAskDeleteMenuTemplate] = useState(false);
  let navigate = useNavigate();

  const onOptionAskDeleteMenuTemlate = (id) => {
    const clickedMenuTemplate = allMenuTemplates.find(
      (menuTemplate) => menuTemplate.id === id
    );
    setAskDeleteMenuTemplate(clickedMenuTemplate);
  };

  const onOptionAskEditMenuTemlate = (id) => {
    navigate(`/view-menu-template/${id}`);
  };

  const loadMenuTemplates = async () => {
    setIsTableLoad(true);
    const menuTemplates = await menuTemplateService.get();
    console.log(menuTemplates);
    setAllMenuTemplates(menuTemplates);
    setFilterMenuTemplates(menuTemplates);
    setIsTableLoad(false);
  };

  const deleteAskedDeletedMeal = async () => {
    setIsTableLoad(true);
    await menuTemplateService.remove(askDeleteMenuTemplate.id);
    setAskDeleteMenuTemplate(null);
    loadMenuTemplates();
    setIsTableLoad(false);
  };

  useEffect(() => {
    document.title = "Step By Step - Menu Templates";
    loadMenuTemplates();
  }, []);

  const handleReOrder = async (newReOrder) => {
    setFilterMenuTemplates(newReOrder);

    const newOrder = newReOrder.map((template, index) => {
      return parseInt(template.id);
    });

    await menuTemplateService.reOrder(newOrder);
  };

  return (
    <div className="main-container page">
      <div className="manage-menu-templates-page">
        <div className="header">
          <span className="sbs-title">ניהול תבניות תפריטים</span>
          <span className="sbs-subtitle">
            סה"כ {allMenuTemplates.length} תבניות ארוחות
          </span>
        </div>

        <input
          className={`sbs-input search ${isSearchLoad ? "load" : "no-load"}`}
          type="text"
          value={search}
          onChange={({ target }) => {
            setSearch(target.value);
          }}
          placeholder="חפש מוצר"
        />

        <SbsTable
          columnMap={{ title: "שם התבנית" }}
          isHaveOptions={true}
          isLoad={isTableLoad}
          metaData={filterMenuTemplates}
          options={[
            { icon: "delete", cb: onOptionAskDeleteMenuTemlate },
            { icon: "edit", cb: onOptionAskEditMenuTemlate },
          ]}
          reOrder={handleReOrder}
        />

        <AskDeletePopup
          askDeleteMenuTemplate={askDeleteMenuTemplate}
          deleteAskedDeletedMeal={deleteAskedDeletedMeal}
          setAskDeleteMenuTemplate={setAskDeleteMenuTemplate}
        />

        <button
          onClick={() => {
            navigate("/view-menu-template");
          }}
          className="sbs-flow-btn"
        >
          <SbsIcons icon="add" />
        </button>
      </div>
    </div>
  );
}
