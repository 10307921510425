import { Draggable } from "react-beautiful-dnd";
import SbsIcons from "../../../components/SbsIcons";
import { TimePicker } from "../../../components/TimePicker";

export default function TrainExercisePreview({
  execiseId,
  trainExercisesMap,
  execisesTitleMap,
  updateTrainExeciseByKey,
  train,
  deleteTrainExecise,
  exerciseTypeObject,
  execisesMap,
  // innerRef,
  // dragHandleProps,
  execiseIds,
  index,
}) {
  const isSuperSetChild = () => {
    console.log({
      trainExercisesMap: trainExercisesMap,
	  exerciseTypeObject: exerciseTypeObject,
	  execiseIds: execiseIds,
    });

    const item = trainExercisesMap[execiseId];
    const index = execiseIds.findIndex(
      (item) => item === execiseId.toString()
    );

    const superSet = item.superSet;
    if (!superSet) return false;
    const superSetIndex = execiseIds.findIndex(
      (item) => item === superSet.toString()
    );

    console.log({
      item: item,
      superSet: superSet,
      index: index,
      superSetIndex: superSetIndex,
      execisesMap: execisesMap,
    });

    return index > superSetIndex;
    // return trainExercisesMap[execiseId].superSetChild

    const indexes = Object.keys(trainExercisesMap);
    const excs = Object.values(train.trainExercisesMap);

    if (!excs || !excs.find((item) => item.id === execiseId)) return false;
    if (!excs.find((item) => item.id === execiseId)?.type === "SuperSet")
      return false;

    const current = trainExercisesMap[execiseId];
    if (!current.superSet) return false;

    console.log("current.superSet", current, current.superSet);
    const connectedId = current.superSet;

    const currentIndex = indexes.findIndex(
      (item) => item === execiseId.toString()
    );
    const connectedIndex = indexes.findIndex(
      (item) => item === connectedId.toString()
    );

    return !!(currentIndex > connectedIndex);
  };

  console.log("TEST", `${isSuperSetChild() ? "superset-child" : ""}`);

  return (
    <Draggable
      draggableId={execiseId}
      index={index}
      isDragDisabled={
        trainExercisesMap &&
        trainExercisesMap[execiseId] &&
        trainExercisesMap[execiseId]?.type &&
        trainExercisesMap[execiseId]?.type === "SuperSet"
      }
    >
      {(provided) => (
        <div
          className={`flex gap-0-5 align-center train-execise-preview ${
            trainExercisesMap[execiseId]?.type
          } ${isSuperSetChild() ? "superset-child" : ""}`}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div className="flex column full flex-1">
            <div
              title={execisesTitleMap[trainExercisesMap[execiseId].execiseId]}
              className="title w100 grow"
            >
              {execisesTitleMap[trainExercisesMap[execiseId].execiseId]}
            </div>
            <div
              className="flex-1 flex gap-0-5"
              style={{
                alignItems: "flex-end",
              }}
            >
              <div className="flex column">
                <span>מס סטים</span>
                <input
                  className="sbs-input-show-arrow sets"
                  type="number"
                  value={trainExercisesMap[execiseId].numSet}
                  onChange={({ target }) => {
                    updateTrainExeciseByKey(
                      "numSet",
                      train.id,
                      execiseId,
                      Math.abs(+target.value % 100)
                    );
                  }}
                  placeholder="מס' סטים"
                />
              </div>

              <div className="flex row">
                <div className="group sbs-checkbox-container2">
                  <input
                    name={`max-${train.id}-${execiseId}`}
                    type="checkbox"
                    id={`max-${train.id}-${execiseId}`}
                    checked={trainExercisesMap[execiseId].isMax}
                    onChange={() => {
                      updateTrainExeciseByKey(
                        "isMax",
                        train.id,
                        execiseId,
                        !trainExercisesMap[execiseId].isMax
                      );
                    }}
                  />
                  <label htmlFor={`max-${train.id}-${execiseId}`}>MAX </label>
                </div>
              </div>
              <div className="flex column">
                <span>מס חזרות</span>
                <input
                  className="sbs-input-show-arrow repeats"
                  type="text"
                  value={
                    trainExercisesMap[execiseId].isMax
                      ? "MAX"
                      : trainExercisesMap[execiseId].numRepeatMin
                  }
                  onChange={({ target }) => {
                    updateTrainExeciseByKey(
                      "numRepeatMin",
                      train.id,
                      execiseId,
                      target.value
                    );
                  }}
                  disabled={trainExercisesMap[execiseId].isMax}
                  placeholder="מס' חזרות"
                />
              </div>
              <div className="flex column">
                <span>עד</span>
                <input
                  className="sbs-input-show-arrow repeats"
                  type="text"
                  value={
                    trainExercisesMap[execiseId].isMax
                      ? "MAX"
                      : trainExercisesMap[execiseId].numRepeatMax || "0"
                  }
                  onChange={({ target }) => {
                    updateTrainExeciseByKey(
                      "numRepeatMax",
                      train.id,
                      execiseId,
                      target.value
                    );
                  }}
                  disabled={trainExercisesMap[execiseId].isMax}
                  placeholder="עד "
                />
              </div>
              <div className="flex column">
                <span>מנוחה עד</span>
                <TimePicker
                  time={trainExercisesMap[execiseId].rest}
                  onChange={(time) =>
                    updateTrainExeciseByKey("rest", train.id, execiseId, time)
                  }
                />
              </div>

              <div className="flex column">
                <span>סוג</span>
                <select
                  className="sbs-select type"
                  value={trainExercisesMap[execiseId].type}
                  onChange={({ target }) =>
                    updateTrainExeciseByKey(
                      "type",
                      train.id,
                      execiseId,
                      target.value
                    )
                  }
                >
                  <option key="Normal" value="Normal">
                    Normal
                  </option>
                  <option key="SuperSet" value="SuperSet">
                    SuperSet
                  </option>
                  <option key="DropSet" value="DropSet">
                    DropSet
                  </option>
                  <option key="DropSet_Triple" value="DropSet_Triple">
                    DropSet Triple
                  </option>
                </select>
              </div>
              <div className="flex column grow">
                <span>תיאור</span>
                <input
                  className="sbs-input description"
                  type="text"
                  value={trainExercisesMap[execiseId].description}
                  onChange={({ target }) => {
                    updateTrainExeciseByKey(
                      "description",
                      train.id,
                      execiseId,
                      target.value
                    );
                  }}
                  placeholder="תיאור"
                />
              </div>
              <div className="flex column grow">
                <span>הערה</span>
                <input
                  className="sbs-input description"
                  type="text"
                  value={trainExercisesMap[execiseId].comment}
                  onChange={({ target }) => {
                    updateTrainExeciseByKey(
                      "comment",
                      train.id,
                      execiseId,
                      target.value
                    );
                  }}
                  placeholder="הערה"
                />
              </div>
            </div>
          </div>
          <div
            onClick={() =>
              deleteTrainExecise(train.id, execiseId, exerciseTypeObject.id)
            }
            className="close pointer white-svg"
          >
            <SbsIcons icon={"close"} />
          </div>
        </div>
      )}
    </Draggable>
  );
}
