import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { utilService } from "../../../services/utilService";

export default function AskDeletePopup({ askDeleteMenuTemplate, deleteAskedDeletedMeal, setAskDeleteMenuTemplate }) {
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        if (askDeleteMenuTemplate) setIsOpen(true);
        else setIsOpen(false);
    }, [askDeleteMenuTemplate])

    return (
        <div onClick={() => setAskDeleteMenuTemplate(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>אתה בטוח שאתה רוצה למחוק {askDeleteMenuTemplate?.title}</span>
                    <div className='close-popup' onClick={() => setAskDeleteMenuTemplate(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <span>אתה בטוח שאתה רוצה למחוק {askDeleteMenuTemplate?.title}</span>
                </div>
                <div className='popup-footer'>
                    <button className='button-primary' onClick={() => deleteAskedDeletedMeal()} >מחק</button>
                    {/* <button className='button-secondary' onClick={() => setAskDeleteMenuTemplate(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}