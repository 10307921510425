import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { globalVariable } from "../../../services/globalVariable";
import { groupService } from "../../../services/groupService";
import { utilService } from "../../../services/utilService";
import Calendar from 'react-calendar';
import { workClockService } from "../../../services/workClockService";
import { authService } from "../../../services/authService";

export default function AskEditPopup({
    askEditMonth,
    setAskEditMonth,
    workClock,
    updateWorkClock,
    employeeId
}) {

    const [isOpen, setIsOpen] = useState(false);
    const [monthWorkClocks, setMonthWorkClock] = useState([]);
    const [currWork, setCurrWork] = useState(null);
    const [calendarDate, setCalendarDate] = useState(new Date())
    const [from, setFrom] = useState({ hour: 0, minute: 0 })
    const [to, setTo] = useState({ hour: 0, minute: 0 })
    const [test, setTest] = useState(null);
    const [permissions, setPermissions] = useState([]);


    // ManageWorkClocks
    useEffect(() => {
        if (askEditMonth) {
            setIsOpen(true);
            setMonthWorkClock(workClock
                .filter(work => {
                    const workDateShared = utilService.getDateShared(work.from)
                    return workDateShared.month === askEditMonth.month && workDateShared.year === workDateShared.year
                })
                .map(work => ({
                    ...work,
                    day: new Date(work.from).getDate()
                }))
            )
        }
        else {
            setIsOpen(false);
            setMonthWorkClock([]);
            setCurrWork(null)
            setFrom({ hour: 0, minute: 0 })
            setTo({ hour: 0, minute: 0 })
        }
    }, [askEditMonth, workClock])

    useEffect(() => {
        authService.getPermissions();
        authService.currPermissions$.subscribe(permissions => setPermissions(permissions))
    }, [])



    const onEditWork = (work) => {
        setCurrWork(work)
        setCalendarDate(new Date(work.from))
        const fromTimeShard = utilService.getTimeShared(work.from)
        const toTimeShard = utilService.getTimeShared(work.to)
        setFrom({ ...fromTimeShard })
        setTo({ ...toTimeShard })
    }

    const onDeleteWork = async (work) => {
        await workClockService.deleteWorkClock(work.id);
        updateWorkClock()
    }

    const onSaveChanges = async () => {
        const updatedWork = {
            ...currWork,
            from: +calendarDate.setHours(from.hour, from.minute),
            to: +calendarDate.setHours(to.hour, to.minute)
        }
        if (currWork.id) await workClockService.updateWork(updatedWork);
        else await workClockService.addWork(updatedWork, employeeId);
        setCurrWork(null)
        updateWorkClock()
    }

    const betweenTime = () => {
        const toMinutes = (to.hour * 60) + to.minute
        const fromMinutes = (from.hour * 60) + from.minute
        const between = toMinutes - fromMinutes
        return `${utilService._addZeroIfNeed(Math.floor(between / 60))}:${utilService._addZeroIfNeed(between % 60)}`
    }



    return (
        <div onClick={() => setAskEditMonth(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>ערוך שעון נוכחות</span>
                    <div className='close-popup' onClick={() => setAskEditMonth(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body flex gap-1 column'>
                    {
                        askEditMonth &&
                        <div className="flex column gap-1">

                            {
                                !currWork &&
                                <>
                                    {
                                        permissions.some(permission => permission === "ManageWorkClocks") &&
                                        <div>
                                            <button onClick={() => { onEditWork({ id: '', from: Date.now(), to: Date.now() }) }} className="button-primary icon-only" ><SbsIcons icon="add" /></button>
                                        </div>
                                    }
                                    {
                                        monthWorkClocks.length ?
                                            monthWorkClocks.map(work => (
                                                <div
                                                    key={work.id}
                                                    className="flex gap-1 align-center"

                                                >
                                                    <div>{work.day} ב{globalVariable.monthNumberHebrewMap[askEditMonth.month]}</div>
                                                    <div>{utilService.timestampToClock(work.to)}</div>
                                                    <div>-</div>
                                                    <div>{utilService.timestampToClock(work.from)}</div>
                                                    <div>{utilService.msToHMMSS(work.to - work.from)}</div>
                                                    {
                                                        permissions.some(permission => permission === "ManageWorkClocks") &&
                                                        <>
                                                            <button onClick={() => onEditWork(work)} className="button-primary icon-only" ><SbsIcons icon="edit" /></button>
                                                            <button onClick={() => onDeleteWork(work)} className="button-primary icon-only" ><SbsIcons icon="delete" /></button>
                                                        </>
                                                    }
                                                </div>
                                            )) : (
                                                <span>אין שעונים</span>
                                            )
                                    }

                                </>

                            }
                            {
                                currWork &&
                                <div>
                                    <Calendar
                                        onChange={setCalendarDate}
                                        value={calendarDate}
                                        className='sbs-calendar'
                                        calendarType="Hebrew"
                                        locale="ISR"
                                    />
                                    <div className="flex justify-space-between align-center" >
                                        <div className="flex column">
                                            <span>מ</span>
                                            <input
                                                className='sbs-input'
                                                type="time"
                                                value={utilService.timeShardToTimeInput(from)}
                                                onChange={({ target }) => { setFrom(utilService.timeInputToTimeShard(target.value)) }}
                                                placeholder="חפש מוצר"
                                            />
                                        </div>
                                        <span>
                                            {
                                                (to.hour < from.hour || (to.hour === from.hour && to.minute <= from.minute)) ?
                                                    '00:00' :
                                                    betweenTime()
                                            }
                                        </span>

                                        <div className="flex column">
                                            <span>עד</span>
                                            <input
                                                className='sbs-input'
                                                type="time"
                                                value={utilService.timeShardToTimeInput(to)}
                                                onChange={({ target }) => { setTo(utilService.timeInputToTimeShard(target.value)) }}
                                                placeholder="חפש מוצר"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className='popup-footer'>
                    {
                        currWork ?
                            <>
                                <button
                                    disabled={to.hour < from.hour || (to.hour === from.hour && to.minute <= from.minute)}
                                    className='button-primary icon-only'
                                    onClick={() => onSaveChanges()}
                                >
                                    שמור
                                </button>
                                <button className='button-secondary icon-only' onClick={() => setAskEditMonth(null)} >סגור</button>
                            </>
                            :
                            <button className='button-secondary icon-only' onClick={() => setAskEditMonth(null)} >סגור</button>
                    }
                </div>
            </div>
        </div>
    )
}