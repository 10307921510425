import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SbsIcons from './SbsIcons';
import { utilService } from '../services/utilService';
import { authService } from '../services/authService';


export default function Navbar({ isMenuOpen, setIsMenuOpen }) {

    // const [isOpen, setIsOpen] = useState(false);
    const [currNavGroup, setCurrNavGroup] = useState(-1);
    const [token, setToken] = useState(authService.getToken());
    const [permissions, setPermissions] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currToken = authService.getToken();
        if (
            !currToken &&
            location.pathname !== '/forgot-password' &&
            location.pathname !== '/login' &&
            location.pathname !== '/restore-password'
        ) navigate('/login')
        else if (currToken) {
            setToken(currToken);
            if (location.pathname === '/login') {
                authService.clearToken();
                setToken(authService.getToken());
            }
        }
    }, [location])

    useEffect(() => {
        authService.getPermissions();
        authService.currPermissions$.subscribe(permissions => setPermissions(permissions))
    }, [])


    useEffect(() => {
        console.log(permissions);
    }, [permissions])


    const toggleIsOpen = () => {
        setIsMenuOpen(isMenuOpen => !isMenuOpen);
    }

    const toggleCurrGroup = (idx) => {
        setCurrNavGroup(currNavGroup === idx ? -1 : idx);
    }

    const onLogout = () => {
        // authService.clearToken();
        navigate('/login');
    }

    const navGroups = [
        {
            title: 'בית',
            icon: 'home',
            routes: [
                {
                    title: 'בית',
                    routeTo: '/',
                },
                {
                    title: 'ניהול משפטי מוטיבציה',
                    routeTo: '/manage-motivational-sentences'
                },
                {
                    title: 'ניהול שאלות ותשובת',
                    routeTo: '/manage-questions-and-answers'
                },
                {
                    title: 'ניהול משימות',
                    routeTo: '/manage-tasks'
                }
            ]
        },
        {
            title: 'עובדים',
            icon: 'employee',
            routes: [
                {
                    title: 'ניהול עובדים',
                    routeTo: '/manage-employees',
                    permission: 'ManageUsers'
                },
                {
                    title: 'שעון עבודה אישי',
                    routeTo: '/view-time-clock'
                },
                {
                    title: 'טבלת שעון עבודה',
                    routeTo: '/manage-work-clocks',
                    permission: 'ManageWorkClocks'
                }
            ]
        },
        {
            title: 'לקוחות',
            icon: 'customer',
            routes: [
                {
                    title: 'ניהול לקוחות',
                    routeTo: '/manage-customers',
                    permission: 'ManageCustomers'
                },
                {
                    title: 'ניהול קבוצות',
                    routeTo: '/manage-groups'
                },
            ]
        },
        {
            title: 'אימונים',
            icon: 'training2',
            permission: 'ManageTrains',
            routes: [
                {
                    title: 'ניהול תרגילים',
                    routeTo: '/manage-exercises'
                },
                {
                    title: 'ניהול אירובי',
                    routeTo: '/manage-aerobics'
                },
                {
                    title: 'ניהול תרגילי בטן',
                    routeTo: '/manage-abdominal'
                },
                {
                    title: 'ניהול תבניות',
                    routeTo: '/manage-templates'
                }
            ]
        },
        {
            title: 'תזונה',
            icon: 'meals',
            permission: 'ManageNutrition',
            routes: [
                {
                    title: 'ניהול מוצרים',
                    routeTo: '/manage-products'
                },
                {
                    title: 'ניהול ארוחות',
                    routeTo: '/manage-meals'
                },
                {
                    title: 'ניהול תבניות ארוחות',
                    routeTo: '/manage-menu-templates'
                },
            ]
        },
    ]





    return (
        token ? (
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='navbar'>
                <div className="header">
                    <FaIcons.FaBars onClick={toggleIsOpen} />
                </div>
                <nav className={isMenuOpen ? "open" : "close"}>
                    <div className='close-menu' onClick={toggleIsOpen} >
                        <SbsIcons icon="close" />
                    </div>
                    <div className='nav-groups'>
                        {
                            navGroups
                                .filter(navGroup => !navGroup.permission || permissions.some(permission => permission === navGroup.permission))
                                .map((navGroup, idx) => (
                                    <div key={idx} className={'nav-group ' + (currNavGroup === idx ? "open" : "close")} >
                                        <div onClick={() => toggleCurrGroup(idx)} className='nav-group-title'>
                                            <div className='title-and-icon' >
                                                <SbsIcons icon={navGroup.icon} />
                                                <span>{navGroup.title}</span>
                                            </div>
                                            <div className='icon-container'>
                                                <MdIcons.MdArrowDropDown />
                                            </div>
                                        </div>
                                        <div className='nav-group-routes'>
                                            {
                                                navGroup
                                                    .routes
                                                    .filter(route => !route.permission || permissions.some(permission => permission === route.permission))
                                                    .map((route, idx) => (
                                                        <div key={idx} className='route'>
                                                            <Link onClick={() => { setIsMenuOpen(false) }} to={route.routeTo}>{route.title}</Link>
                                                        </div>
                                                    ))
                                            }
                                        </div>


                                    </div>
                                ))
                        }
                    </div>
                    <button className='logout' onClick={() => { onLogout() }} > <SbsIcons icon="logout" />יציאה</button>
                </nav>
            </div>
        ) : (
            <div></div>
        )
    )
}