import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { exerciseService } from "../../../services/exerciseService";
import { utilService } from "../../../services/utilService";

export default function ExerciseTypesPopup({ openedExerciseTypesTrainId, setOpenedExerciseTypesTrainId, toggleExerciseType, plan, exerciseTypes }) {

    const [isOpen, setIsOpen] = useState(false);
    const hebrewTypes = exerciseService.getTypesHebrew();


    useEffect(() => {
        if (openedExerciseTypesTrainId) setIsOpen(true);
        else setIsOpen(false);
    }, [openedExerciseTypesTrainId])

    return (
        <div onClick={() => setOpenedExerciseTypesTrainId('')} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>ערוך סוגי תרגילים</span>
                    <div className='close-popup' onClick={() => setOpenedExerciseTypesTrainId('')} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <form className="exercise-types-container flex column gap-0-5">
                        {
                            openedExerciseTypesTrainId && exerciseTypes.map((exerciseType, exerciseIdx) => (
                                <div key={exerciseIdx} className="group sbs-checkbox-container">
                                    <input
                                        id={exerciseType}
                                        name={exerciseType}
                                        type="checkbox"
                                        checked={plan.trains.find(train => train.id === openedExerciseTypesTrainId).exerciseTypesOrder.some(exerciseTypeItem => exerciseTypeItem === exerciseType)}
                                        onChange={({ target }) => toggleExerciseType(exerciseType)}
                                    />
                                    <label htmlFor={exerciseType}>{hebrewTypes[exerciseIdx]}</label>
                                </div>
                            ))
                        }
                    </form>
                </div>
                <div className='popup-footer'>
                    <button className='button-secondary' onClick={() => setOpenedExerciseTypesTrainId('')} >סגור</button>
                </div>
            </div>
        </div>
    )
}