import { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import SbsIcons from "../../../components/SbsIcons";
import { exerciseService } from "../../../services/exerciseService";
import TrainExerciseList from "./TrainExerciseList";

export default function ExeciseTypePreview({
  trainExercisesMap,
  exerciseTypeObject,
  train,
  updateTrainExeciseByKey,
  deleteTrainExecise,
  setAskAddExerciseData,
  execises,
  dragHandleProps,
  draggableProps,
  innerRef,
  execisesMap,
}) {
  const [execisesTitleMap, setExecisesTitleMap] = useState({});

  useEffect(() => {
    setExecisesTitleMap(
      execises.reduce((acc, execise) => {
        acc[execise?.id] = execise?.title;
        return acc;
      }, {})
    );
  }, [execises]);

  console.log('exerciseTypeObject', exerciseTypeObject)
  return (
    <div className="type-train-execise-list" ref={innerRef} {...draggableProps}>
      <div className="train-exerise flex column gap-0-5 ">
        <div
          className="flex align-center gap-0-5 train-exerise-header"
          style={{ display: 'none' }}
          {...dragHandleProps}
        >
          <span className="sbs-subtitle">
            {exerciseService.getTypeHebrewMap()[exerciseTypeObject?.title]}
          </span>
          <button
            onClick={() => {
              setAskAddExerciseData({
                exerciseType: exerciseTypeObject.id,
                trainId: train.id,
              });
            }}
            className="button-primary"
          >
            הוסף תרגיל
          </button>
        </div>
        <Droppable droppableId={exerciseTypeObject?.id} type="execise">
          {(provided) => (
            <>
              <TrainExerciseList
                exerciseTypeObject={exerciseTypeObject}
                trainExercisesMap={train.trainExercisesMap}
                execisesTitleMap={execisesTitleMap}
                updateTrainExeciseByKey={updateTrainExeciseByKey}
                train={train}
                deleteTrainExecise={deleteTrainExecise}
                innerRef={provided.innerRef}
                placeholeder={provided.placeholder}
                execisesMap={execisesMap}
              />
              {/* {provided.placeholder} */}
            </>
          )}
        </Droppable>

        {/* {
                    exerciseTypeObject.execiseIds.map((execiseId) => (
                        <div key={execiseId} className='flex gap-0-5 align-center'  >
                            <div className='title' >
                                {execisesTitleMap[trainExercisesMap[execiseId].execiseId]}
                            </div>
                            <input
                                className='sbs-input sets'
                                type="number"
                                value={trainExercisesMap[execiseId].numSet}
                                onChange={({ target }) => { updateTrainExeciseByKey('numSet', train.id, execiseId, target.value) }}
                                placeholder="מס' סטים"
                            />

                            <input
                                className='sbs-input repeats'
                                type="number"
                                value={trainExercisesMap[execiseId].numRepeat}
                                onChange={({ target }) => { updateTrainExeciseByKey('numRepeat', train.id, execiseId, target.value) }}
                                placeholder="מס' חזרות"
                            />

                            <select
                                className="sbs-select type"
                                value={trainExercisesMap[execiseId].type}
                                onChange={({ target }) => updateTrainExeciseByKey('type', train.id, execiseId, target.value)}
                            >
                                <option key="רגיל" value="רגיל">רגיל</option>
                                <option key="לא רגיל" value="לא רגיל">לא רגיל</option>
                            </select>

                            <input
                                className='sbs-input description'
                                type="text"
                                value={trainExercisesMap[execiseId].description}
                                onChange={({ target }) => { updateTrainExeciseByKey('description', train.id, execiseId, target.value) }}
                                placeholder="תיאור"
                            />

                            <div onClick={() => deleteTrainExecise(train.id, execiseId, exerciseTypeObject.id)} className='close pointer' >
                                <SbsIcons icon={'close'} />
                            </div>
                        </div>
                    ))
                } */}
      </div>
    </div>
  );
}
