import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const motivationalSentenceService = {
    get,
    remove,
    getRandom,
    getEmpty,
    edit,
    add
}

let motivationalSentences = [
    {
        "id": "ms101",
        "sentence": "אור דמרי המלך",
        "groups": ["g102", "g103"],
    },
    {
        "id": "ms102",
        "sentence": "2אור דמרי המלך",
        "groups": ["g102", "g104"],
    },
    {
        "id": "ms103",
        "sentence": "3אור דמרי המלך",
        "groups": ["g102"],
    },
    {
        "id": "ms104",
        "sentence": "4אור דמרי המלך",
        "groups": ["g104"],
    },
]

function getEmpty() {
    return {
        "id": "",
        "sentence": "",
        "groups": "",
    };
}

const ENDPOINT = "general/quotes";

async function get() {
    const res = await httpService.get(ENDPOINT);
    var motivationalSentences = [];
    res.data.forEach(sentence => {
        const { message, groups, id } = sentence;
        var groupsID = groups.map(group => { return group.id });
        motivationalSentences.push({ id, sentence: message, groups: groupsID });
    });
    return motivationalSentences;
}

async function remove(id) {
    try {
        const response = await httpService.delete(ENDPOINT + "/" + id);
        errorService.updateError({ msg: 'משפט המוטיבציה נמחק', type: 'warning', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(motivationalSentence) {
    try {
        const response = await httpService.put(ENDPOINT, { id: motivationalSentence.id, newMessage: motivationalSentence.sentence, GroupsID: motivationalSentence.groups });
        errorService.updateError({ msg: 'משפט המוטיבציה עודכן', type: 'success', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(motivationalSentence) {
    try {
        const response = await httpService.post(ENDPOINT, { message: motivationalSentence.sentence, GroupsID: motivationalSentence.groups });
        errorService.updateError({ msg: 'משפט מוטיבציה נוסף', type: 'success', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getRandom() {
    var motivationalSentences = await get();
    return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
}
