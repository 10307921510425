import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MealCreator from "../../components/meal-creator/MealCreator";
import { mealService } from "../../services/mealService";

export default function ViewMeal({ }) {

    const [meal, setMeal] = useState(mealService.getEmpty())
    const [isSaveLoad, setIsSaveLoad] = useState(false)
    const { mealId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if (mealId) updateMeal(mealId);
    }, [mealId])

    useEffect(() => {
        document.title = "Step By Step - Meals";
    }, [])

    const onSave = async () => {
        setIsSaveLoad(true)
        if (meal.id) await mealService.edit(meal);
        else await mealService.add(meal);
        setIsSaveLoad(false)
        navigate('/manage-meals')
    }

    const updateMeal = async (mealId) => {
        if (mealId) {
            const editMeal = await mealService.getById(mealId);
            console.log(editMeal);
            setMeal(editMeal)
        }
    }

    return (
        <div className="main-container page">
            <div className="view-meal-page flex column gap-1">
                <span className="sbs-title" >תצוגת ארוחה</span>

                <div className="flex gap-1" >
                    <input
                        className='sbs-input'
                        type="text"
                        value={meal.title}
                        onChange={({ target }) => { setMeal({ ...meal, title: target.value }) }}
                        placeholder="שם הארוחה"
                    />

                    <input
                        className='sbs-input'
                        type="text"
                        value={meal.description}
                        onChange={({ target }) => { setMeal({ ...meal, description: target.value }) }}
                        placeholder="תיאור הארוחה"
                    />

                    <select
                        className="sbs-select"
                        value={meal.type}
                        onChange={({ target }) => setMeal({ ...meal, type: target.value })}
                    >
                        <option key="Breakfast" value="Breakfast">ארוחת בוקר</option>
                        <option key="Lunch" value="Lunch">ארוחת צהריים</option>
                        <option key="Dinner" value="Dinner">ארוחת ערב</option>
                        <option key="Snack" value="Snack">חטיף</option>
                    </select>
                </div>

                <MealCreator
                    meal={meal}
                    setMeal={setMeal}
                    isEditable={true}
                />

                <div>
                    <button
                        onClick={onSave}
                        className="button-primary"
                        disabled={isSaveLoad || !meal.title}
                    >
                        שמור ארוחה
                    </button>
                </div>
            </div>
        </div>
    )
}