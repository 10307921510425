import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import SbsTable from "../../../components/SbsTable";
import { templateService } from "../../../services/templateService";
import { utilService } from "../../../services/utilService";

var searchTimeOut = null
export default function LoadTemplatePopup({ isLoadTemplate, setIsLoadTemplate, setPlan }) {

    const [allTemplates, setAllTemplates] = useState([]);
    const [filterTemplates, setFilterTemplates] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (isLoadTemplate) {
            updateAllTemplates()
        }
    }, [isLoadTemplate])


    useEffect(() => {
        if (searchTimeOut) clearTimeout(searchTimeOut);
        searchTimeOut = setTimeout(() => {
            updateFilterTemplates();
        }, 500)
    }, [search, allTemplates])

    const updateAllTemplates = async () => {
        const template = await templateService.get();
        setAllTemplates(template);
    }

    const updateFilterTemplates = async () => {
        const filterMotivationalSentences = allTemplates
            .filter(template => template.title.includes(search))
        setFilterTemplates(filterMotivationalSentences);
    }

    const onOptionLoadTemplate = (id) => {
        let template = allTemplates.find(temlpateItem => temlpateItem.id === id)
        if (template) {
            console.log('template', template);
            setIsLoadTemplate(false);
            setPlan(plan => {
                console.log('plan', plan);
                return plan
            })

			setPlan(plan => ({
                ...plan,
                ...template,
				trains: [...plan.trains, ...template.trains],
                title: template.title + ' העתק',
                id: plan.id,
                startDate: plan.startDate,
                endDate: plan.endDate,

            }))

        }
    }



    return (
        <div onClick={() => setIsLoadTemplate(false)} className={isLoadTemplate ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>טען תבנית</span>
                    <div className='close-popup' onClick={() => setIsLoadTemplate(false)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body flex column gap-1 align-start'>
                    <input
                        className='sbs-input'
                        type="text"
                        value={search}
                        onChange={({ target }) => { setSearch(target.value) }}
                        placeholder="חפש תבנית"
                    />

                    <SbsTable
                        columnMap={{ "id": "מספר תבנית", "title": "שם התבנית", createBy: "יוצר התבנית" }}
                        isHaveOptions={true}
                        metaData={filterTemplates}
                        options={[
                            { icon: 'load', cb: onOptionLoadTemplate },
                        ]}
                    ></SbsTable>
                    {/* <span> אתה בטוח שאתה רוצה למחוק את המשפט {askDeleteSentence?.sentence} ששייך לקבוצות {askDeleteSentence?.groups}</span> */}
                </div>
                <div className='popup-footer'>
                    <button className='button-secondary' onClick={() => setIsLoadTemplate(false)} >סגור</button>
                </div>
            </div>
        </div>
    )
}