import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";

export default function TokenGuard(props) {

    const navigate = useNavigate();
    const [token, setToken] = useState(authService.getToken());

    useEffect(() => {
        if(!token){
            navigate('/login')
        } 
    }, [token])
    




    return (
        token &&
        <>
            {props.children}
        </>
    )
}