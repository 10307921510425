import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { customerService } from "../../services/customerService";
import { menuService } from "../../services/menuService";
import SbsStepper from "../../components/SbsStepper";
import MealsCreation from "./components/MealsCreation";
import MealList from "./components/MealList";
import MenuSummary from "./components/MenuSummary";
import SbsIcons from "../../components/SbsIcons";
import { errorService } from "../../services/errorService";

export default function ViewMenu() {
  const [currStep, setCurrStep] = useState(0);
  const [menu, setMenu] = useState(menuService.getEmpty());
  const [customer, setCustomer] = useState(customerService.getEmpty());
  const nextStep = () => setCurrStep((currStep) => ++currStep);
  const previousStep = () => setCurrStep((currStep) => --currStep);
  const [isStep2Disabled, setIsStep2Disabled] = useState(true);
  const [isStep3Disabled, setIsStep3Disabled] = useState(true);
  const { menuId, customerId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Step By Step - Menu";
  }, []);

  useEffect(() => {
    console.log(menu);
    setIsStep2Disabled(
      !menu.title ||
        !menu.meals.length ||
        menu.meals.some((meal) => !meal.title)
    );
    setIsStep3Disabled(
      !menu.title ||
        !menu.meals.length ||
        menu.meals.some((meal) => !meal.title) ||
        menu.meals.some((meal) => !meal.products.length)
    );
  }, [menu]);

  useEffect(() => {
    if (customerId) {
      setMenu((menu) => ({
        ...menu,
        customerId: customerId,
      }));
      updateCustomer(customerId);
    } else if (menuId) {
      updateMenu(menuId);
    }
  }, [menuId, customerId]);

  const updateCustomer = async (customerId) => {
    const currCustomer = await customerService.getById(customerId);
    setCustomer(currCustomer);
  };

  const updateMenu = async (menuId) => {
    const currMenu = await menuService.getById(menuId);
    if (currMenu) {
      setMenu(currMenu);
      if (currMenu.customerId) {
        const currCustomer = await customerService.getById(currMenu.customerId);
        setCustomer(currCustomer);
      }
    }
  };

  const onSave = async () => {
    if (menu.meals.filter((meal) => meal.products.length).length === 0)
      return errorService.updateError({
        msg: "יש להוסיף לפחות מוצר אחד",
        type: "danger",
        time: Date.now(),
      });

    if (menu.id) await menuService.edit(menu);
    else await menuService.add(menu);
    navigate(`/view-customer/${customer.id}`);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const onChangesSave = async () => {
    if (menu.meals.filter((meal) => meal.products.length).length === 0)
      return errorService.updateError({
        msg: "יש להוסיף לפחות מוצר אחד",
        type: "danger",
        time: Date.now(),
      });

    setIsLoading(true);
    if (menu.id) await menuService.edit(menu);
    else await menuService.add(menu);
    setIsLoading(false);
    setIsSaved(true);
  };

  console.log(
    menu,
    "menu.meals.length",
    menu.meals.filter((meal) => meal.products.length).length
  );

  return (
    <div className="main-container page">
      <div className="view-menu-page">
        <SbsStepper
          stepsLengh={3}
          currStep={currStep}
          setCurrStep={setCurrStep}
          disableSteps={[false, isStep2Disabled, isStep3Disabled]}
        />

        <div style={{ position: "absolute", right: "20%" }}>
          <Link
            className="button-primary"
            to={`/view-customer/${customer.id}`}
            style={{
              borderRadius: "100%",
              padding: "0.8rem",
              display: "inline-flex",
            }}
          >
            <SbsIcons icon="back" />
          </Link>
        </div>

        <br />

        <div className="page-steps">
          {currStep === 0 && (
            <MealsCreation
              menu={menu}
              setMenu={setMenu}
              nextStep={nextStep}
              customer={customer}
              isNextStepDisable={isStep2Disabled}
            />
          )}
          {currStep === 1 && (
            <MealList
              menu={menu}
              setMenu={setMenu}
              nextStep={nextStep}
              previousStep={previousStep}
              isNextStepDisable={isStep3Disabled}
            />
          )}
          {currStep === 2 && (
            <MenuSummary
              menu={menu}
              customer={customer}
              previousStep={previousStep}
              onSave={onSave}
            />
          )}
        </div>

        {menu.id && currStep !== 2 &&  (
          <button
            onClick={onChangesSave}
            className="button-primary"
            style={{
              width: "100%",
              marginTop: "1rem",
            }}
          >
            שמור שינויים
          </button>
        )}

        <div
          className={`data-situation ${isSaved ? "updated" : "not-updated"}`}
        >
          <SbsIcons icon={isSaved ? "check" : "update"} />
        </div>
      </div>
    </div>
  );
}
