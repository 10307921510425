import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import SbsTable from "../../../components/SbsTable";
import { menuTemplateService } from "../../../services/menuTemplateService";
import { utilService } from "../../../services/utilService";

var searchTimeOut = null;
export default function LoadMenuTemplatePopup({
    isLoadMenuTemplateOpen,
    setIsLoadMenuTemplateOpen,
    loadMenuTemplate
}) {

    const [allMenuTemplates, setAllMenuTemplates] = useState([]);
    const [filterMenuTemplates, setFilterMenuTemplates] = useState([]);
    const [search, setSearch] = useState('');



    useEffect(() => {
        updateAllMenuTemplates()
    }, [])

    useEffect(() => {
        if (searchTimeOut) clearTimeout(searchTimeOut);
        searchTimeOut = setTimeout(() => {
            updateFilterMeals();
        }, 500)
    }, [search, allMenuTemplates])



    const updateAllMenuTemplates = async () => {
        const menuTemplates = await menuTemplateService.get();
        console.log('menuTemplates', menuTemplates);
        setAllMenuTemplates(menuTemplates);
    }

    const updateFilterMeals = async () => {
        console.log('allMenuTemplates', allMenuTemplates);
        let filtered = allMenuTemplates
            .filter(template => template.title.includes(search))
        setFilterMenuTemplates(filtered);
    }


    const onLoadMenuTemplate = (id) => {
        const loadedMenuTemplate = allMenuTemplates.find(template => template.id == id);
        if (loadedMenuTemplate) loadMenuTemplate({ ...loadedMenuTemplate })
        setIsLoadMenuTemplateOpen(false);
    }

    return (
        <div onClick={() => setIsLoadMenuTemplateOpen(false)} className={isLoadMenuTemplateOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>טען ארוחה</span>
                    <div className='close-popup' onClick={() => setIsLoadMenuTemplateOpen(false)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body flex column gap-1'>
                    <div className="flex gap-1">
                        <input
                            className='sbs-input'
                            type="text"
                            value={search}
                            onChange={({ target }) => { setSearch(target.value) }}
                            placeholder="חפש ארוחה"
                        />

                    </div>
                    <SbsTable
                        columnMap={{ "title": "שם המוצר", description: "תיאור" }}
                        isHaveOptions={true}
                        metaData={filterMenuTemplates}
                        options={[
                            { icon: 'load', cb: onLoadMenuTemplate },
                        ]}
                    />
                </div>

            </div>
        </div>
    )
}