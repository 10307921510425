import { BehaviorSubject } from "rxjs"
import { utilService } from "./utilService";


const errors$ = new BehaviorSubject([
]);

function updateError(err) {
    err.id = utilService.genId();
    const errors = errors$.value;
    errors$.next([...errors, err])
    const timeOut = setTimeout(() => {
        removeError(err.id);
    }, 5000)
}

function removeError(id) {
    const errors = errors$.value;
    errors$.next(errors.filter(err => err.id !== id))
}


export const errorService = {
    updateError,
    errors$
}