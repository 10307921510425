export const utilService = {
    priorityNumberToStars,
    getRandomIntInclusive,
    genId,
    stopPropagation,
    datePickerTimeToTimestamp,
    timestampToDatePickerTime,
    timestampToDDMMYYYY,
    getDateShared,
    _addZeroIfNeed,
    timestampToClock,
    msToHMMSS,
    getTimeShared,
    timeShardToTimeInput,
    timeInputToTimeShard
}

function priorityNumberToStars(num, limit = 5) {
    let starPriority = ''
    for (let i = 1; i <= limit; i++) {
        if (num >= i) starPriority += '★';
        else starPriority += "☆"
    }
    return starPriority;
}

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function genId() {
    return "id" + Math.random().toString(16).slice(2);
}

function stopPropagation(ev) {
    ev.stopPropagation();
}

function datePickerTimeToTimestamp(string) {
    const [year, month, day] = string.split('-');
    var newDate = new Date(year, month - 1, day);
    return newDate.getTime()
}

function timestampToDatePickerTime(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year + '-' + _addZeroIfNeed(month) + '-' + _addZeroIfNeed(day)
}

function timestampToDDMMYYYY(timestamp) {
    console.log(timestamp);
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return _addZeroIfNeed(day) + '/' + _addZeroIfNeed(month) + '/' + year
}

function _addZeroIfNeed(number) {
    return number < 10 ? '0' + number : '' + number;
}

function getDateShared(timestamp) {
    if (!timestamp) timestamp = Date.now();
    const date = new Date(timestamp);
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    }
}

function timestampToClock(timestamp) {
    const date = new Date(timestamp);
    const hour = date.getHours();
    const minutes = date.getMinutes();
    return `${_addZeroIfNeed(hour)}:${_addZeroIfNeed(minutes)}`
}

function msToHMMSS(ms) {
    const hours = Math.floor(ms / (1000 * 60 * 60))
    const minutes = Math.floor(ms / (1000 * 60)) % 60
    const seconds = Math.floor(ms / 1000) % 60
    return `${hours}:${_addZeroIfNeed(minutes)}:${_addZeroIfNeed(seconds)}`
}

function getTimeShared(timestamp) {
    if (!timestamp) timestamp = Date.now();
    const date = new Date(timestamp);
    return {
        hour: date.getHours(),
        minute: date.getMinutes()
    }
}

function timeShardToTimeInput(timeShard) {
    return `${_addZeroIfNeed(timeShard.hour)}:${_addZeroIfNeed(timeShard.minute)}`
}

function timeInputToTimeShard(inputString) {
    const [hour, minute] = inputString.split(':');
    return { hour: +hour, minute: +minute }
}
