import { useEffect, useState } from 'react';
import SbsIcons from '../../../components/SbsIcons';
import SbsTable from '../../../components/SbsTable';
import { groupService } from '../../../services/groupService'
import uploadImg from '../../../assets/imgs/upload-img.png'
import { paymentService } from '../../../services/paymentService';
import AskDeletePopup from './AskDeletePopup';
import AskDeletePlan from './AskDeletePlan';
import AskDeleteMenu from './AskDeleteMenu';
import { customerService } from '../../../services/customerService';
import AskEditPopup from './AskEditPopup';
import { planService } from '../../../services/planService';
import DatesProgressBar from '../../../components/DatesProgressBar';
import { utilService } from '../../../services/utilService';
import { Link, useNavigate } from "react-router-dom";
import { menuService } from '../../../services/menuService';
import { globalVariable } from '../../../services/globalVariable';
import moment from 'moment';



export default function CustomerDataByLabels({ customer, setCustomer, label, isNew, getCustomer, log }) {

    const [groups, setGroups] = useState([]);
    const [customerPayments, setCustomerPayments] = useState([]);
    const [customerPlans, setCustomerPlans] = useState([]);
    const [customerMenus, setCustomerMenus] = useState([]);
    const [askDeletePayment, setAskDeletePayment] = useState(null);
    const [askDeletePlan, setAskDeletePlan] = useState(null);
    const [askDeleteMenu, setAskDeleteMenu] = useState(null);
    const [askEditPayment, setAskEditPayment] = useState(null);
    let navigate = useNavigate();
	console.log('daf', customerMenus.map(menu => menu.description))
    const paymentMathods = {
        Cash: 'מזומן',
        Check: "צ'ק",
        CreditCard: "כרטיס אשראי",
        BankTransfer: "העברה בנקאית",
        Bit: "ביט",
    }

    useEffect(() => {
        updateGroups();
    }, [])

    useEffect(() => {
        updatePayments();
        updatePlans();
        updateMenus();
    }, [customer])



    const deleteAskedDeletedPayment = async () => {
        await paymentService.remove(askDeletePayment.id);
        await customerService.removePayment(customer.id, askDeletePayment.id);
        const newPayments = customer.payments.filter(paymentId => paymentId !== askDeletePayment.id);
        setCustomer(customer => ({ ...customer, payments: [...newPayments] }))
        setAskDeletePayment(null)
    }

    const editAskedEditPayment = async (payment) => {
        if (!askEditPayment.id) await addAskedEditPayment(payment);
        else {
            await paymentService.edit(payment, customer.id);
        }
        updatePayments();
        setAskEditPayment(null);
    }

    const addAskedEditPayment = async (payment) => {
        const newPayment = await paymentService.add(payment, customer.id);
        if (newPayment) {
            setCustomer(customer => ({ ...customer, payments: [...customer.payments, newPayment.id] }))
            setAskEditPayment(null);
        }
    }

    const onOptionAskDeletePayment = (id) => {
        const payment = customerPayments.find(payment => payment.id === id);
        setAskDeletePayment(payment);
    }

    const onOptionAskEditPayment = (id) => {
        const payment = customerPayments.find(payment => payment.id === id);
        setAskEditPayment(payment);
    }

    const deleteAskedDeletedPlan = async () => {
        const newPlans = customerPlans.filter(plan => plan.id !== askDeletePlan.id);
        setCustomerPlans(newPlans);
        await planService.remove(askDeletePlan.id);
        setAskDeletePlan(null)
    }

    const deleteAskedDeletedMenu = async () => {
        const newMenus = customerMenus.filter(menu => menu.id !== askDeleteMenu.id);
        setCustomerMenus(newMenus);
        await menuService.remove(askDeleteMenu.id);
        setAskDeleteMenu(null)
    }

    const onOptionAskDeletePlan = (id) => {
        const plan = customerPlans.find(customerPlan => customerPlan.id === id);
        setAskDeletePlan(plan);
    }

    const onOptionAskEditPlan = (id) => {
        navigate(`/view-plan/plan/${id}`)
    }

    const onOptionDowloandPlan = async (id) => {
        const URL = `//sbs-dev.barpahima.co.il/api/training/plans/pdf/${id}`;
        window.open(URL, '_blank');
    }

    const onOptionAskDowlandMenu = async (id) => {
        const URL = `//sbs-dev.barpahima.co.il/api/nutrition/menus/pdf/${id}`;
        window.open(URL, '_blank');
    }

    const updateGroups = async () => {
        const groups = await groupService.get();
        setGroups(groups);
    }

    const updatePayments = async () => {
        const payments = await paymentService.get(customer.id);
        setCustomerPayments(payments);
    }

    const updatePlans = async () => {

        const plans = customer.id ? await planService.getByCustomerId(customer.id) : [];

        setCustomerPlans(plans.map(plan => ({
            ...plan,
            createAt: utilService.timestampToDDMMYYYY(plan.created),
            progress: <DatesProgressBar startDate={plan.startDate} endDate={plan.endDate} />
        })));
    }

    const updateMenus = async () => {
        const menus = await menuService.getByCustomerId(customer.id);
        setCustomerMenus(menus.map(menu => ({
            ...menu,
            createAt: utilService.timestampToDDMMYYYY(menu.createAt),
            progress: <DatesProgressBar startDate={menu.startDate} endDate={menu.endDate} />,
			description: menu.description !== '' ? menu.description : 'לא הוזן תיאור לתפריט'
        })))
    }

    const toggleGroup = (groupId) => {
        let updateGroups = null;
        if (customer.groups.some(groupIdItem => groupIdItem === groupId)) {
            updateGroups = customer.groups.filter(groupIdItem => groupIdItem !== groupId);
        } else updateGroups = [...customer.groups, groupId];
        setCustomer({ ...customer, groups: updateGroups });
    }

    const removeFile = async (fileName) => {
        // const files = employee.files.filter((file, fileIdx) => fileIdx !== idx);
        // setEmployee(employee => ({ ...employee, files }))
        await customerService.deleteFile(customer.id, fileName);
        await getCustomer()
    }

    const onUploadFiles = async (event) => {
        await customerService.addFiles(event.target.files, customer.id)
        await getCustomer()
        // const newFiles = Object.keys(event.target.files).map(key => event.target.files[key]);

        // setEmployee({ ...employee, files: [...employee.files, ...newFiles] })
    }

    const dowloandFile = (fileName) => {
        window.open(
            `https:${globalVariable.BASE_URL}customers/files/${customer.id}/${fileName}`,
            '_blank'
        );
    }
    const onOptionAskEditMenu = (id) => {
        navigate(`/view-menu/menu/${id}`)
    }

    const onOptionAskDeleteMenu = (id) => {
        const currMenu = customerMenus.find(menu => menu.id === id);
        if (currMenu) (
            setAskDeleteMenu(currMenu)
        )
    }



    switch (label) {
        case 'personalInformation':
            return (
                <>
                    {
                        !isNew &&
                        <span className=''>תאריך הצטרפות: <span>{customer.joinAt}</span></span>
                    }
                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={customer.idNumber}
                            onChange={({ target }) => { setCustomer(customer => ({ ...customer, idNumber: target.value })) }}
                            placeholder="תעודת זהות"
                        />
                        <div className="placeholder">תעודת זהות</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={customer.email}
                            onChange={({ target }) => { setCustomer(customer => ({ ...customer, email: target.value })) }}
                            placeholder='כתובת דוא"ל'
                        />
                        <div className="placeholder">כתובת דוא"ל</div>
                    </div>



                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={customer.address}
                            onChange={({ target }) => { setCustomer(customer => ({ ...customer, address: target.value })) }}
                            placeholder='כתובת מגורים'
                        />
                        <div className="placeholder">כתובת מגורים</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="number"
                            value={customer.weight}
                            onChange={({ target }) => { setCustomer(customer => ({ ...customer, weight: +target.value })) }}
                            placeholder='משקל'
                        />
                        <div className="placeholder">משקל</div>
                    </div>
                    <div className="">
                        <div className="placeholder">הערות</div>
                        <textarea
                            className='sbs-input'
                            type="text"
                            value={customer.comments}
                            onChange={({ target }) => { setCustomer(customer => ({ ...customer, comments: target.value })) }}
                            placeholder="הערות"
                        />
                    </div>

                    <span>קבוצות:</span>
                    <form className="groups-container">
                        {
                            groups.map(group => (
                                <div key={group.id} className="group sbs-checkbox-container">
                                    <input
                                        id={group.id}
                                        name={group.id}
                                        type="checkbox"
                                        checked={customer.groups.some(groupId => groupId === group.id)}
                                        onChange={({ target }) => toggleGroup(group.id)}
                                    />
                                    <label htmlFor={group.id}>{group.title}</label>
                                </div>
                            ))
                        }
                    </form>


                </>
            )
        case 'training':
            return (
                <div className='flex column gap-1'>

                    <SbsTable
                        columnMap={{ "id": "מספר תוכנית", "createAt": "תאריך יצירה", "progress": "התקדמות" }}
                        isHaveOptions={true}
                        metaData={customerPlans}
                        options={[
                            { icon: 'delete', cb: onOptionAskDeletePlan },
                            { icon: 'edit', cb: onOptionAskEditPlan },
                            { icon: 'pdf', cb: onOptionDowloandPlan }
                        ]}
                    ></SbsTable>

                    <Link className="add-to-table" to={`/view-plan/customer/${customer.id}`} >
                        <SbsIcons icon="add" />
                    </Link>


                    <AskDeletePlan
                        askDeletePlan={askDeletePlan}
                        setAskDeletePlan={setAskDeletePlan}
                        deleteAskedDeletedPlan={deleteAskedDeletedPlan}
                    />


                </div>
            )
        case 'nutrition':
            return (
                <div>
                    <div className='flex column gap-1'>

                        <SbsTable
                            columnMap={{ "id": "מספר תפריט", "description": "תיאור", "createAt": "תאריך יצירה", "progress": "התקדמות" }}
                            isHaveOptions={true}
                            metaData={customerMenus}
                            options={[
                                { icon: 'delete', cb: onOptionAskDeleteMenu },
                                { icon: 'edit', cb: onOptionAskEditMenu },
                                { icon: 'pdf', cb: onOptionAskDowlandMenu }

                            ]}
                        ></SbsTable>

                        <Link className="add-to-table" to={`/view-menu/customer/${customer.id}`} >
                            <SbsIcons icon="add" />
                        </Link>

                        <AskDeleteMenu
                            askDeleteMenu={askDeleteMenu}
                            setAskDeleteMenu={setAskDeleteMenu}
                            deleteAskedDeletedMenu={deleteAskedDeletedMenu}
                        />
                    </div>
                </div>
            )
        case 'files':
            return (
                <div className='files-data' >
                    {
                        !!customer.files.length && (
                            <div className='files-list'>
                                {
                                    customer.files.map((file, idx) => (
                                        <div key={idx} className='file-item'>
                                            <span onClick={() => { dowloandFile(file) }} >
                                                {file}
                                            </span>
                                            <div onClick={() => removeFile(file)} >
                                                <SbsIcons icon={'close'} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }

                    <div className="files-upload-container">
                        <div className="not-file-upload">
                            <img className="empty-image" src={uploadImg} alt="upload image" />
                            <span>בחר קבצים <span>או גרור אותם לכאן</span></span>
                        </div>
                        <input type="file" multiple={true} className="files-upload" onChange={onUploadFiles} />
                    </div>

                </div>
            )
        // case 'userManagment':
        //     return <MdIcons.MdOutlineManageAccounts />
        case 'userManagment':
            return (
                <>
                    {
                        customer.lastSeen &&
                        <div className='flex align-center' >
                            <span className=''>נראה לאחרונה: <span>{customer.lastSeen > 0 ? utilService.timestampToDDMMYYYY((customer.lastSeen)) : 'לא התחבר'}</span></span>
                        </div>
                    }
                    <div className='flex align-center gap-1' >
                        <span>לקוח פרימיום</span>
                        <label className="sbs-switch">
                            <input
                                type="checkbox"
                                checked={customer.isPremium}
                                onChange={() => { setCustomer(customer => ({ ...customer, isPremium: !customer.isPremium })) }}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                    {
                        customer.isPremium && customer.lastDateForPlans &&
                        <span>תאריך סוף תוכנית: {utilService.timestampToDDMMYYYY((customer.lastDateForPlans))}</span>
                    }
                </>
            )
        case 'paymentManagment':
            return (
                <>
                    <SbsTable
                        columnMap={{ "id": "מספר תשלום", "date": "תאריך תשלום", "type": "סוג תשלום", "amount": "סכום תשלום", "description": "תיאור" }}
                        isHaveOptions={true}
                        metaData={customerPayments.map(payment => ({
                            ...payment,
                            date: utilService.timestampToDDMMYYYY(payment.date),
                            type: paymentMathods[payment.type]
                        }))}
                        options={[
                            { icon: 'delete', cb: onOptionAskDeletePayment },
                            { icon: 'edit', cb: onOptionAskEditPayment }
                        ]}
                    ></SbsTable>
                    <button className="add-to-table" onClick={() => setAskEditPayment(paymentService.getEmpty())} >
                        <SbsIcons icon="add" />
                    </button>

                    <AskDeletePopup
                        askDeletePayment={askDeletePayment}
                        deleteAskedDeletedPayment={deleteAskedDeletedPayment}
                        setAskDeletePayment={setAskDeletePayment}
                    />

                    <AskEditPopup
                        askEditPayment={askEditPayment}
                        setAskEditPayment={setAskEditPayment}
                        editAskedEditPayment={editAskedEditPayment}
                    />


                </>
            )
        case 'log':
            return (
                <div>
                    <SbsTable
                        columnMap={{
                            date: 'תאריך',
                            text: 'פעילות'
                        }}
                        isHaveOptions={false}
                        // isHavePaginator={true}
                        isLoad={false}
                        metaData={log.map(item => ({ ...item, date: moment(item.time).format('DD/MM/YYYY HH:MM') }))}
                    />
                </div>
            )
        default:
            return null
    }
}