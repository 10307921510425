import { useEffect, useState } from 'react';
import { customerService } from '../../services/customerService';
import SbsIcons from '../../components/SbsIcons';
import CustomerDataByLabels from './components/CustomerDataByLabels';
import { useNavigate, useParams } from 'react-router-dom';
import { authService } from '../../services/authService';
import { syncValidation } from '../../services/validationService';
import { logService } from '../../services/logService';


var updateCostumerTimeOut = null;
export default function ViewCustomer(props) {

    const [customer, setCustomer] = useState(customerService.getEmpty());
    const [currLabel, setCurrLabel] = useState('personalInformation');
    const [isNew, setIsNew] = useState(false);
    const [isDataUpdated, setIsDataUpdated] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();
    const [permissions, setPermissions] = useState(authService.getPermissions())
    const [labelsMap, setLabelsMap] = useState({})
    const [log, setLog] = useState([]);

    useEffect(() => {
        document.title = "Step By Step - Customer";
    }, [])

    useEffect(() => {
        if (permissions.some(permission => permission === 'ManageUsers')) {

            if (permissions.some(permission => permission === 'ManageNutrition')) {
                if (permissions.some(permission => permission === 'ManageTrains')) {
                    setLabelsMap(
                        {
                            personalInformation: "פרטים אישיים",
                            training: "תוכניות אימונים",
                            nutrition: "תזונה",
                            files: "מסמכים",
                            userManagment: "ניהול משתמש",
                            paymentManagment: "ניהול תשלומים",
                            log: "יומן פעילות",
                        }
                    )
                } else {
                    setLabelsMap(
                        {
                            personalInformation: "פרטים אישיים",
                            nutrition: "תזונה",
                            files: "מסמכים",
                            userManagment: "ניהול משתמש",
                            paymentManagment: "ניהול תשלומים",
                        }
                    )
                }
            } else {
                if (permissions.some(permission => permission === 'ManageTrains')) {
                    setLabelsMap(
                        {
                            personalInformation: "פרטים אישיים",
                            training: "תוכניות אימונים",
                            files: "מסמכים",
                            userManagment: "ניהול משתמש",
                            paymentManagment: "ניהול תשלומים",
                        }
                    )
                } else {
                    setLabelsMap(
                        {
                            personalInformation: "פרטים אישיים",
                            files: "מסמכים",
                            userManagment: "ניהול משתמש",
                            paymentManagment: "ניהול תשלומים",
                        }
                    )
                }
            }
        }

    }, [permissions])


    useEffect(() => {
        if (id) getCustomer();
        if (id) getLog();
    }, [])




    useEffect(() => {
        if (customer.id) setIsNew(false)
        else setIsNew(true);
    }, [customer])

    useEffect(() => {
        if (!isNew) {
            setIsDataUpdated(false);
            if (updateCostumerTimeOut) clearTimeout(updateCostumerTimeOut);
            updateCostumerTimeOut = setTimeout(async () => {
                if (customer.firstName || customer.lastName || customer.phone) await customerService.edit(customer);
                setIsDataUpdated(true);
            }, 2000);
        }

        return () => {
            clearTimeout(updateCostumerTimeOut);
        }
    }, [customer])

    const getCustomer = async () => {
        const currCustomer = await customerService.getById(id);
        console.log('currCustomer', currCustomer);
        setCustomer(currCustomer);
    }

    const getLog = async () => {
        const resLog = await logService.get(id);
        setLog(resLog)
        console.log(resLog);
    }

    const addCustomer = async () => {
        try {

            const newCustomer = await customerService.add(customer);
            if (newCustomer) {
                navigate("" + newCustomer.id)
                setCustomer(newCustomer);
                setIsDataUpdated(true);
            }
        } catch {

        }
    }

    const deleteCustomer = async () => {
        await customerService.remove(customer.id);
        navigate('/manage-customers')
    }

    return (
        <div className='main-container page'>
            <div className='view-customer-page'>
                <span className='sbs-title'>{isNew ? 'לקוח חדש' : 'כרטיס לקוח'}</span>

                <div className='image-and-basic'>
                    <div className='profile-image'>
                        <img src={customer.profileImage ? customer.profileImage : 'https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif'} />
                    </div>
                    <div className='basic-data'>



                        <div className='flex column gap-0-5' >
                            <div className="sbs-input-placeholder">
                                <input
                                    type="text"
                                    value={customer.firstName}
                                    onChange={({ target }) => { setCustomer(customer => ({ ...customer, firstName: target.value })) }}
                                    placeholder="שם פרטי"
                                />
                                <div className="placeholder">שם פרטי</div>
                            </div>
                            <div className="sbs-input-placeholder">
                                <input
                                    type="text"
                                    value={customer.lastName}
                                    onChange={({ target }) => { setCustomer(customer => ({ ...customer, lastName: target.value })) }}
                                    placeholder="שם משפחה"
                                />
                                <div className="placeholder">שם משפחה</div>
                            </div>
                        </div>

                        <div>
                            <div className="sbs-input-placeholder">
                                <input
                                    type="text"
                                    value={customer.phone}
                                    onChange={({ target }) => { setCustomer(customer => ({ ...customer, phone: target.value })) }}
                                    placeholder="טלפון"
                                />
                                <div className="placeholder">טלפון</div>
                            </div>
                        </div>


                        <div className='flex align-center gap-1' >
                            <span>לקוח פעיל</span>
                            <label className="sbs-switch">
                                <input
                                    type="checkbox"
                                    checked={customer.isActive}
                                    onChange={() => { setCustomer(customer => ({ ...customer, isActive: !customer.isActive })) }}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>

                        {
                            !isNew &&
                            <div className='flex align-center' >
                                <span className=''>מספר לקוח: <span>{customer.id}</span></span>
                            </div>
                        }
                        {
                            isNew &&
                            <div className='flex align-center'>
                                <button
                                    onClick={() => addCustomer()}
                                    className='button-primary'
                                    disabled={
                                        !customer.firstName ||
                                        !customer.lastName ||
                                        !syncValidation.isPhoneNumberValid(customer.phone)
                                    }
                                >
                                    צור לקוח
                                </button>
                            </div>
                        }
                        {
                            !isNew &&
                            <>
                                <div>
                                    <button onClick={() => setCustomer(customer => ({ ...customer, isSuspended: !customer.isSuspended }))} className='button-primary'>
                                        {customer.isSuspended ? 'בטל השהית לקוח' : 'השהה לקוח'}

                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => deleteCustomer()} className='button-secondary'>
                                        מחק לקוח
                                    </button>
                                </div>
                            </>
                        }


                    </div>
                </div>
                {
                    !isNew &&
                    <div className='data-container'>
                        <div className='labels'>
                            {
                                Object.keys(labelsMap).map(labelKey => (
                                    <div onClick={() => { setCurrLabel(labelKey) }} key={labelKey} className={`label ${labelKey === currLabel ? 'curr' : ''}`}>
                                        <SbsIcons icon={labelKey} />
                                        <span>{labelsMap[labelKey]}</span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='data'>
                            <CustomerDataByLabels
                                customer={customer}
                                setCustomer={setCustomer}
                                label={currLabel}
                                isNew={isNew}
                                getCustomer={getCustomer}
                                log={log}
                            />
                        </div>
                    </div>
                }
                {!isNew &&
                    <div className={`data-situation ${isDataUpdated ? 'updated' : 'not-updated'}`}>
                        <SbsIcons icon={isDataUpdated ? 'check' : 'update'} />
                    </div>
                }
            </div>
        </div>
    )
}