import { useEffect, useState } from "react"
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { q_aService } from "../../services/q_aService";
import AskDeletePopup from "./components/AskDeletePopup";
import AskEditPopup from "./components/AskEditPopup";

var searchTimeOut = null;
export default function ManageQ_A() {
    const [allQ_A, setAllQ_A] = useState([]);
    const [filterQ_A, setFilterQ_A] = useState([]);
    const [search, setSearch] = useState('');
    const [askDeleteQ_A, setAskDeleteQ_A] = useState(null);
    const [askEditQ_A, setAskEditQ_A] = useState(null);
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);
    const [openedQuestionIds, setOpenQuestionIds] = useState([]);

    useEffect(() => {
        document.title = "Step By Step - Q&A";
    }, [])

    useEffect(() => {
        updateAllQ_A();
    }, [])

    useEffect(() => {
        console.log(openedQuestionIds);
    }, [openedQuestionIds])


    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterQ_A();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allQ_A])

    const updateAllQ_A = async () => {
        setIsTableLoad(true);
        const q_a = await q_aService.get();
        setAllQ_A(q_a);
    }

    const updateFilterQ_A = async () => {
        setIsTableLoad(false);
        const filterMotivationalSentences = allQ_A
            .filter(q_a => q_a.question.includes(search))
        setFilterQ_A(filterMotivationalSentences);
    }

    const onOptionAskDeleteQ_A = (id) => {
        setAskDeleteQ_A(allQ_A.find(q_a => q_a.id === id));
    }

    const onOptionAskEditQ_A = (id) => {
        setAskEditQ_A(allQ_A.find(q_a => q_a.id === id));
    }

    const deleteAskedDeletedQ_A = async () => {
        await q_aService.remove(askDeleteQ_A.id);
        updateAllQ_A();
        setAskDeleteQ_A(null);
    }

    const editAskedEditQ_A = async (question, answer) => {
        if (!askEditQ_A.id) addAskedEditQ_A(question, answer);
        else {
            const editedQ_A = { ...askEditQ_A, answer, question }
            await q_aService.edit(editedQ_A);
            updateAllQ_A();
            setAskEditQ_A(null);
        }
    }

    const addAskedEditQ_A = async (question, answer) => {
        const newQ_A = { question, answer }
        await q_aService.add(newQ_A);
        updateAllQ_A();
        setAskEditQ_A(null);
    }

    const toggleQuestion = (id) => {
        if (openedQuestionIds.some(qId => qId === id)) setOpenQuestionIds(openedQuestionIds.filter(qId => qId !== id))
        else setOpenQuestionIds([...openedQuestionIds, id])
    }

    return (
        <div className="main-container page">
            <div className="manage-q_a-page">
                <div className='header'>
                    <span className='sbs-title'>ניהול שאלות ותשובות</span>
                    <span className='sbs-subtitle'>סה"כ {allQ_A.length} שאלות ותשובות</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש שאלה"
                />

                {/* <SbsTable
                    columnMap={{ "question": "שאלה", "answer": "תשובה" }}
                    isHaveOptions={true}
                    isLoad={isTableLoad}
                    metaData={filterQ_A}
                    options={[
                        { icon: 'delete', cb: onOptionAskDeleteQ_A },
                        { icon: 'edit', cb: onOptionAskEditQ_A }
                    ]}
                ></SbsTable> */}

                {
                    isTableLoad ? (
                        <div className="loader"></div>

                    ) : (
                        <div className="accordion">
                            {/* {
                                filterQ_A.map(qa => (

                                    <div
                                        key={qa.id}
                                        className="accordion-item"
                                    >
                                        <div
                                            className="accordion-title"
                                            onClick={() => { toggleQuestion(qa.id) }}
                                        >
                                            {qa.question}
                                        </div>
                                        <div className={`accordion-description ${openedQuestionIds.some(qId => qId === qa.id )? 'open' : ''} `}>
                                            {qa.answer}
                                        </div>

                                    </div>
                                ))
                            } */}

                            {/* <div class="tabs">
                                <div class="tab">
                                    <input type="checkbox" id="chck1"/>
                                        <label class="tab-label" for="chck1">Item 1</label>
                                        <div class="tab-content">
                                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum, reiciendis!
                                        </div>
                                </div>
                                <div class="tab">
                                    <input type="checkbox" id="chck2"/>
                                        <label class="tab-label" for="chck2">Item 2</label>
                                        <div class="tab-content">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. A, in!
                                        </div>
                                </div>
                            </div>
                        </div> */}

                            <div className="tabs">
                                {
                                    filterQ_A.map(qa => (
                                        <div key={qa.id} className="tab">
                                            <input type="checkbox" id={qa.id} />
                                            <label className="tab-label" htmlFor={qa.id}>
                                                {qa.question}
                                                <div className="option-container">
                                                    <button onClick={()=>{onOptionAskEditQ_A(qa.id)}} className="button-primary icon-only" >
                                                        <SbsIcons icon="edit" />
                                                    </button>
                                                    <button onClick={()=>{onOptionAskDeleteQ_A(qa.id)}} className="button-primary icon-only" >
                                                        <SbsIcons icon="delete" />
                                                    </button>
                                                </div>
                                            </label>
                                            <div className="tab-content">
                                                {qa.answer}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                <AskDeletePopup
                    askDeleteQ_A={askDeleteQ_A}
                    deleteAskedDeletedQ_A={deleteAskedDeletedQ_A}
                    setAskDeleteQ_A={setAskDeleteQ_A}
                />

                <AskEditPopup
                    askEditQ_A={askEditQ_A}
                    editAskedEditQ_A={editAskedEditQ_A}
                    setAskEditQ_A={setAskEditQ_A}
                />

                <button onClick={() => { setAskEditQ_A({ question: "", answer: "" }) }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>

            </div>
        </div >
    )
}