import { utilService } from "../services/utilService"

export default function DatesProgressBar({
    startDate,
    endDate
}) {


    const precent = (Date.now() - startDate) / (endDate - startDate) * 100


    return (
        <div dir="ltr" className="progress-container" >
            <div className="progress" style={{ width: precent + '%'}} ></div>
            <span>{utilService.timestampToDDMMYYYY(startDate)}-{utilService.timestampToDDMMYYYY(endDate)}</span>
        </div>
    )
}