import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { productService } from "../../services/productService";
import AskDeletePopup from "./components/AskDeletePopup";
import { MeasureTypes } from "../../components/meal-creator/MealCreator";

var searchTimeOut = null;
export default function ManageProducts({}) {
  const [allProducts, setAllProducts] = useState([]);
  const [filterProductsMap, setFilterProductsMap] = useState({});
  const [search, setSearch] = useState("");
  const [askDeleteProduct, setAskDeleteProduct] = useState(null);
  let navigate = useNavigate();
  const [isSearchLoad, setIsSearchLoad] = useState(false);

  const productTypesHebrew = {
    Carbohydrate: "פחמימה",
    Protein: "חלבון",
    Fat: "שומן",
  };

  useEffect(() => {
    document.title = "Step By Step - Products";
  }, []);

  useEffect(() => {
    updateAllProducts();
  }, []);

  useEffect(() => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
      setIsSearchLoad(true);
    }
    searchTimeOut = setTimeout(() => {
      updateFilterProducts();
      setIsSearchLoad(false);
    }, 500);
  }, [search, allProducts]);

  const updateAllProducts = async () => {
    const product = await productService.get();
    console.log("product", product);
    setAllProducts(product);
  };

  const updateFilterProducts = () => {
    const filteredProductMap = allProducts
      .filter((product) => product.title.includes(search))
      .reduce((acc, product) => {
        if (acc[product.type]) acc[product.type].push(product);
        else acc[product.type] = [product];
        return acc;
      }, {});
    setFilterProductsMap(filteredProductMap);
  };

  const onOptionAskDeleteProduct = (id) => {
    setAskDeleteProduct(allProducts.find((product) => product.id === id));
  };

  const onOptionAskEditProduct = (id) => {
    navigate(`/view-product/${id}`);
  };

  const deleteAskedDeletedProduct = async () => {
    await productService.remove(askDeleteProduct.id);
    updateAllProducts();
    setAskDeleteProduct(null);
  };
  console.log(filterProductsMap);
  return (
    <div className="main-container page">
      <div className="manage-products-page">
        <div className="header">
          <span className="sbs-title">ניהול מוצרים</span>
          <span className="sbs-subtitle">סה"כ {allProducts.length} מוצרים</span>
        </div>

        <input
          className={`sbs-input search ${isSearchLoad ? "load" : "no-load"}`}
          type="text"
          value={search}
          onChange={({ target }) => {
            setSearch(target.value);
          }}
          placeholder="חפש מוצר"
        />
        <div className="flex column gap-1 table-container">
          {Object.keys(filterProductsMap).map((type) => (
            <div key={type}>
              <span className="sbs-title">{productTypesHebrew[type]}</span>
              <SbsTable
                columnMap={{
                  title: "שם המוצר",
                  amount: "כמות",
                  measureType2: "סוג כמות",
                }}
                isHaveOptions={true}
                metaData={filterProductsMap[type].map((product) => ({
                  ...product,
                  measureType2:
                    MeasureTypes.find(
                      (measureType) => measureType.value === product.measureType
                    ).label || "שגיאה!",
                }))}
                options={[
                  { icon: "delete", cb: onOptionAskDeleteProduct },
                  { icon: "edit", cb: onOptionAskEditProduct },
                ]}
              />
            </div>
          ))}
        </div>

        <AskDeletePopup
          askDeleteProduct={askDeleteProduct}
          deleteAskedDeletedProduct={deleteAskedDeletedProduct}
          setAskDeleteProduct={setAskDeleteProduct}
        />

        <button
          onClick={() => {
            navigate("/view-product");
          }}
          className="sbs-flow-btn"
        >
          <SbsIcons icon="add" />
        </button>
      </div>
    </div>
  );
}
