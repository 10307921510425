import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";

export default function PermissionWorkClockGuard(props) {

    const navigate = useNavigate();
    const [isWorkClockPermission, setIsWorkClockPermissions] = useState(authService.getPermissions().some(prem=>prem==='ManageWorkClocks'));

    useEffect(() => {
        if(!isWorkClockPermission){
            navigate('/')
        } 
    }, [isWorkClockPermission])
    




    return (
        isWorkClockPermission &&
        <>
            {props.children}
        </>
    )
}