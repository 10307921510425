import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/imgs/logo.png'
import { authService } from '../../services/authService';
import { syncValidation } from '../../services/validationService';


export default function Login(props) {

    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Step By Step - Login";
    }, [])

    const login = async () => {
        setIsLoad(true);
        await authService.login(identifier, password)
        if (authService.getToken()) navigate('/')
        setIsLoad(false);
    }

    useEffect(() => {
        setIsDisabled(
            !syncValidation.isEmailValid(identifier) ||
            !syncValidation.isPasswordValid(password)
        )
    }, [identifier, password])





    return (
        <div className='main-container page'>
            <div className='login-page page'>
                <div className='flex column gap-1' >
                    <div className='flex column gap-1 container' >
                        <img src={logo} alt="sbs-logo" />
                        <input
                            className='sbs-input'
                            type="email"
                            value={identifier}
                            onChange={({ target }) => { setIdentifier(target.value) }}
                            placeholder="דואר אלקטרוני"
                        />
                        <input
                            className='sbs-input'
                            type="password"
                            value={password}
                            onChange={({ target }) => { setPassword(target.value) }}
                            placeholder="סיסמא"
                            onKeyDown={(ev) => { if (ev.code === 'Enter' && !isDisabled) login() }}
                        />
                        <button disabled={isDisabled || isLoad} onClick={() => { login() }} className='button-primary' >התחבר</button>
                        <button onClick={() => { navigate('/forgot-password') }} className='forgot-password' >שכחתי סיסמא</button>

                    </div>
                </div>
            </div>
        </div>
    )
}