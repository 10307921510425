import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const exerciseService = {
    get,
    remove,
    // getRandom,
    getEmpty,
    edit,
    add,
    getTypes,
    getTypesHebrew,
    getTypeHebrewMap,
    changeImage,
    getImage
}

let exercises = [
    {
        id: '1',
        name: 'sdad',
        description: 'asdasd',
        type: "Chest"
    }

]

function getEmpty() {
    return {
        id: "",
        type: getTypes()[0],
        title: "",
        description: "",
        youtubeUrl: "",
        image: null,
    };
}

const ENDPOINT = 'training/exercises'

async function get() {
    const res = await httpService.get(ENDPOINT);
    const exercises = res.data.map(exercise => {
        const { id, type, name, description, video, image } = exercise;
        return { id, type, title: name, description, youtubeUrl: video, image }
    });
    return exercises
}

async function remove(id) {
    try {
        const res = await httpService.delete(ENDPOINT + `/${id}`);
        errorService.updateError({ msg: 'תרגיל נמחק', type: 'warning', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(exercise) {
    try {
        const body = {
            id: exercise.id,
            name: exercise.title,
            video: exercise.youtubeUrl,
            description: exercise.description,
            type: exercise.type
        }
        const res = await httpService.put(ENDPOINT, body);
        errorService.updateError({ msg: 'תרגיל עודכן', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(exercise) {
    try {

        const body = {
            name: exercise.title,
            video: exercise.youtubeUrl,
            description: exercise.description,
            type: exercise.type
        }
        const res = await httpService.post(ENDPOINT, body);
        errorService.updateError({ msg: 'תרגיל נוסף', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function changeImage(id, file) {
    try {
        const body = new FormData();
        body.append('image', file);
        body.append('id', id);
        const res = await httpService.post(`${ENDPOINT}/image`, body);
        errorService.updateError({ msg: 'תמונה עודכנה', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getImage(name) {
    try {
        if (!name) return null;
        const res = await httpService.get(`general/getBase64/${name.replace('/api/general/get_media_file?filename=', '')}`);
        console.log(res);
        return res.data

    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

function getTypes() {
    return [
        "Chest",
        "Biceps",
        "Triceps",
        "Legs",
        "Back",
        "Abs",
        "Shoulders"
    ]
}

function getTypesHebrew() {
    return [
        "חזה",
        "יד קדמית",
        "יד אחורית",
        "רגליים",
        "גב",
        "בטן",
        "כתפיים"
    ]
}

function getTypeHebrewMap() {
    return {
        "Chest": 'חזה',
        "Biceps": 'יד קדמית',
        "Triceps": 'יד אחורית',
        "Legs": 'רגליים',
        "Back": 'גב',
        "Abs": 'בטן',
        "Shoulders": 'כתפיים'
    }
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
