import { useEffect, useState } from "react";
import { exerciseService } from "../../../services/exerciseService";
import { planService } from "../../../services/planService";
import AskAddExercisePopup from "./AskAddExercisePopup";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ExeciseTypeList from "./ExeciseTypeList";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import SuperSetModal from "./SuperSetModal";

export default function TrainList({
  plan,
  setPlan,
  nextStep,
  previousStep,
  disableNextStep,
  onChangesSave,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const onNewChangesSave = async () => {
    setIsLoading(true);
    await onChangesSave();
    setIsLoading(false);
    setIsSaved(true);
  };

  const [execises, setExecises] = useState([]);
  const [execisesMap, setExercisesMap] = useState({});
  const [askAddExerciseData, setAskAddExerciseData] = useState(null);
  const [currTrainIndex, setCurrTrainIndex] = useState(13);
  const [multiSetModal, setMultiSetModal] = useState(false);

  const isCanContinue = () => {
    const trains = plan.trains.map((train) => train.exerciseTypesMap);
    return trains.every((train) => {
      const excs = Object.entries(train).map(
        ([key, value]) => value.execiseIds
      );
      return !excs.some((ex) => ex?.length < 1);
    });
  };

  useEffect(() => {
    updateExecises();
  }, []);
  console.log("plan.trains", plan.trains);

  useEffect(() => {
    updateExecisesMap();
  }, [execises]);

  useEffect(() => {
    if (plan.trains.length - 1 < currTrainIndex)
      setCurrTrainIndex(plan.trains.length - 1);
  }, [plan]);

  const updateExecises = async () => {
    const cuurExecises = await exerciseService.get();
    setExecises(cuurExecises);
  };

  const updateExecisesMap = () => {
    const currExercisesMap = execises.reduce((acc, execise) => {
      if (acc[execise.type]) acc[execise.type].push(execise);
      else acc[execise.type] = [execise];
      return acc;
    }, {});
    setExercisesMap(currExercisesMap);
  };

  const updateTrainExeciseByKey = (key, trainId, trainExeciseId, value) => {
    console.log({
      key,
      trainId,
      trainExeciseId,
      value,
    });
    console.log("value", value, trainExeciseId, key);
    setPlan((plan) => {
      const updateTrain = plan.trains.find((train) => train.id === trainId);
      updateTrain.trainExercisesMap[trainExeciseId][key] = value;

      // check if he unlinked one of the super set options
      if (key === "type" && value !== "SuperSet") {
        // get the closest super sets
        // const firstType = updateTrain.exerciseTypesOrder[0];
        // const index = updateTrain.exerciseTypesMap[
        //   firstType
        // ].execiseIds.findIndex((id) => id === trainExeciseId);

        // const topIndex =
        //   updateTrain.exerciseTypesMap[firstType].execiseIds[index - 1];
        // const top = updateTrain.trainExercisesMap[topIndex];
        // const bottomIndex =
        //   updateTrain.exerciseTypesMap[firstType].execiseIds[index + 1];
        // const bottom = updateTrain.trainExercisesMap[bottomIndex];

        // if (top && top.superSet) {
        //   updateTrain.trainExercisesMap[topIndex].type = "Normal";
        //   updateTrain.trainExercisesMap[topIndex].superSet = 0;
        //   updateTrain.trainExercisesMap[topIndex].superSetChild = false;
        // }

        // if (bottom && bottom.superSet) {
        //   updateTrain.trainExercisesMap[bottomIndex].type = "Normal";
        //   updateTrain.trainExercisesMap[bottomIndex].superSet = 0;
        //   updateTrain.trainExercisesMap[bottomIndex].superSetChild = false;
        // }

        const pairedSuperSet =
          updateTrain.trainExercisesMap[trainExeciseId].superSet;

        if (pairedSuperSet) {
          updateTrain.trainExercisesMap[pairedSuperSet].type = "Normal";
          updateTrain.trainExercisesMap[pairedSuperSet].superSet = 0;
        //   updateTrain.trainExercisesMap[pairedSuperSet].superSetChild = false;
        }

        console.log({ pairedSuperSet });
      }

      if (key === "type") {
        switch (value) {
          default: {
            updateTrain.trainExercisesMap[trainExeciseId]["superSet"] = 0; // 0 = means false
            updateTrain.trainExercisesMap[trainExeciseId]["dropSetNumber"] = 0;
            break;
          }
          case "SuperSet": {
            updateTrain.trainExercisesMap[trainExeciseId]["superSet"] = 0; // the id of the second super set
            updateTrain.trainExercisesMap[trainExeciseId]["dropSetNumber"] = 0;
            setMultiSetModal({ trainExeciseId, trainId });
            break;
          }
          case "DropSet": {
            updateTrain.trainExercisesMap[trainExeciseId]["superSet"] = 0;
            updateTrain.trainExercisesMap[trainExeciseId]["dropSetNumber"] = 1;
            break;
          }
          case "DropSet_Triple": {
            updateTrain.trainExercisesMap[trainExeciseId]["superSet"] = 0;
            updateTrain.trainExercisesMap[trainExeciseId]["dropSetNumber"] = 2;
            break;
          }
        }
      }
      console.log("endTrain", updateTrain);
      return {
        ...plan,
        trains: plan.trains.map((train) =>
          train.id === trainId ? updateTrain : train
        ),
      };
    });
  };

  const deleteTrainExecise = (trainId, trainExeciseId, execiseType) => {
    setPlan((plan) => {
      const updateTrain = plan.trains.find((train) => train.id === trainId);
      const trainExercisesMap = { ...updateTrain.trainExercisesMap };

      if (trainExercisesMap[trainExeciseId].superSet) {
        trainExercisesMap[
          trainExercisesMap[trainExeciseId].superSet
        ].superSet = 0;
        trainExercisesMap[trainExercisesMap[trainExeciseId].superSet].type =
          "Normal";
      }

      delete trainExercisesMap[trainExeciseId];

      const exerciseTypesMap = { ...updateTrain.exerciseTypesMap };
      exerciseTypesMap[execiseType] = {
        ...exerciseTypesMap[execiseType],
        execiseIds: exerciseTypesMap[execiseType].execiseIds?.filter(
          (execiseId) => execiseId !== trainExeciseId
        ),
      };
      console.log("DEVVV", exerciseTypesMap[execiseType]);

      const updatedTrain = {
        ...updateTrain,
        trainExercisesMap,
        exerciseTypesMap,
      };

      return {
        ...plan,
        trains: plan.trains.map((train) =>
          train.id === trainId ? updatedTrain : train
        ),
      };
    });
  };

  const addExerciseToTrain = (execiseId, exerciseType, description) => {
    const trainExecise = {
      ...planService.getEmptyTrainExercise(),
      execiseId,
      description,
    };
    setPlan((plan) => {
      const editedTrain = plan.trains.find(
        (train) => train.id === askAddExerciseData.trainId
      );
      const trainExercisesMap = { ...editedTrain.trainExercisesMap };
      trainExercisesMap[trainExecise.id] = trainExecise;

      const exerciseTypesMap = { ...editedTrain.exerciseTypesMap };
      exerciseTypesMap[exerciseType] = {
        ...exerciseTypesMap[exerciseType],
        execiseIds: [
          ...exerciseTypesMap[exerciseType].execiseIds,
          trainExecise.id,
        ],
      };

      const train = {
        ...editedTrain,
        trainExercisesMap,
        exerciseTypesMap,
      };
      const trains = plan.trains.map((trainItem) =>
        trainItem.id === askAddExerciseData.trainId ? train : trainItem
      );
      return { ...plan, trains };
    });

    // setAskAddExerciseData(null);
  };

  const onDragEnd = (resault) => {
    if (!resault.destination) return;
    const toIndex = resault.destination.index;
    const fromIndex = resault.source.index;
    console.log(resault);
    if (resault.type === "execise") {
      const toExeciseType = resault.destination.droppableId;
      const fromExeciseType = resault.source.droppableId;
      const trainExeciseId = resault.draggableId;
      if (fromExeciseType === toExeciseType) {
        if (fromIndex === toIndex) return;
        const execiseType =
          plan.trains[currTrainIndex].exerciseTypesMap[fromExeciseType];
        const newExeciseIds = Array.from(execiseType.execiseIds);
        newExeciseIds.splice(fromIndex, 1);
        newExeciseIds.splice(toIndex, 0, trainExeciseId);

        setPlan((plan) => {
          let updatedTrain = { ...plan.trains[currTrainIndex] };
          updatedTrain.exerciseTypesMap[fromExeciseType] = {
            ...execiseType,
            execiseIds: [...newExeciseIds],
          };

          return {
            ...plan,
            trains: plan.trains.map((train, idx) =>
              idx === currTrainIndex ? updatedTrain : train
            ),
          };
        });
      } else {
        const fromExeciseTypeObject =
          plan.trains[currTrainIndex].exerciseTypesMap[fromExeciseType];
        const toExeciseTypeObject =
          plan.trains[currTrainIndex].exerciseTypesMap[toExeciseType];

        const fromExeciseIds = Array.from(fromExeciseTypeObject.execiseIds);
        fromExeciseIds.splice(fromIndex, 1);
        const newFromExeciseTypeObject = {
          ...fromExeciseTypeObject,
          execiseIds: fromExeciseIds,
        };

        const toExeciseIds = Array.from(toExeciseTypeObject.execiseIds);
        toExeciseIds.splice(toIndex, 0, trainExeciseId);
        const newToExeciseTypeObject = {
          ...toExeciseTypeObject,
          execiseIds: toExeciseIds,
        };

        setPlan((plan) => {
          let updatedTrain = { ...plan.trains[currTrainIndex] };
          updatedTrain.exerciseTypesMap[fromExeciseType] = {
            ...newFromExeciseTypeObject,
          };
          updatedTrain.exerciseTypesMap[toExeciseType] = {
            ...newToExeciseTypeObject,
          };

          return {
            ...plan,
            trains: plan.trains.map((train, idx) =>
              idx === currTrainIndex ? updatedTrain : train
            ),
          };
        });
      }
    } else {
      const newExerciseTypesOrder = Array.from(
        plan.trains[currTrainIndex].exerciseTypesOrder
      );
      newExerciseTypesOrder.splice(fromIndex, 1);
      newExerciseTypesOrder.splice(toIndex, 0, resault.draggableId);

      setPlan((plan) => {
        return {
          ...plan,
          trains: plan.trains.map((train, idx) =>
            idx === currTrainIndex
              ? { ...train, exerciseTypesOrder: newExerciseTypesOrder }
              : train
          ),
        };
      });
    }
  };

  const changeTrain = (isNext) => {
    if (isNext) {
      if (currTrainIndex >= plan.trains.length - 1) setCurrTrainIndex(0);
      else setCurrTrainIndex(currTrainIndex + 1);
    } else {
      if (currTrainIndex <= 0) setCurrTrainIndex(plan.trains.length - 1);
      else setCurrTrainIndex(currTrainIndex - 1);
    }
  };

  const firstType =
    (plan.trains[currTrainIndex] &&
      plan.trains[currTrainIndex]?.exerciseTypesMap &&
      Object.keys(plan.trains[currTrainIndex]?.exerciseTypesMap)?.[0]) ||
    "";

  const exerciseType =
    plan.trains[currTrainIndex]?.exerciseTypesMap?.[firstType]?.id;

  console.log("firstType", firstType, exerciseType);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="trains-execrise-view-plan">
        <div className="sbs-dots-container">
          {plan.trains.map((train, idx) => (
            <div
              key={idx}
              className={`dot ${currTrainIndex === idx ? "choosen" : ""}`}
              onClick={() => setCurrTrainIndex(idx)}
            ></div>
          ))}
        </div>
        <div className="flex align-center">
          <button
            onClick={() => changeTrain(false)}
            className="change-train-button back"
            disabled={currTrainIndex <= 0}
          >
            <IoIosArrowForward
              fill={currTrainIndex <= 0 ? "#ff7b3b" : "#ff5603"}
            />
          </button>
          <div className="grow-1">
            {plan.trains[currTrainIndex] && (
              <>
                <button
                  onClick={() =>
                    setAskAddExerciseData({
                      exerciseType,
                      trainId: plan.trains[currTrainIndex].id,
                    })
                  }
                  className="button-primary"
                  style={{ marginBottom: "10px" }}
                >
                  הוסף תרגיל
                </button>
                <Droppable droppableId="train" type="execise-type">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <div className="train-container">
                        <span className="sbs-title">
                          {plan.trains[currTrainIndex].title}
                        </span>
                        <ExeciseTypeList
                          deleteTrainExecise={deleteTrainExecise}
                          setAskAddExerciseData={setAskAddExerciseData}
                          train={plan.trains[currTrainIndex]}
                          updateTrainExeciseByKey={updateTrainExeciseByKey}
                          execises={execises}
                          execisesMap={execisesMap}
                          placeholder={provided.placeholder}
                        />
                      </div>
                    </div>
                  )}
                </Droppable>
              </>
            )}
          </div>

          <button
            onClick={() => changeTrain(true)}
            className="change-train-button next"
            disabled={currTrainIndex >= plan.trains.length - 1}
          >
            <IoIosArrowBack
              fill={
                currTrainIndex >= plan.trains.length - 1 ? "#ff7b3b" : "#ff5603"
              }
            />
          </button>
        </div>
        <div className="flex justify-space-between gap-1">
          <button onClick={() => previousStep()} className="button-secondary">
            חזור לעריכת הגדרות האימון
          </button>
          <button
            disabled={!isCanContinue() || disableNextStep}
            onClick={() => nextStep()}
            className="button-primary"
          >
            {disableNextStep
              ? disableNextStep
              : !isCanContinue()
              ? "יש להוסיף לפחות תרגיל אחד לכל אימון"
              : "עבור לסיכום"}
          </button>
        </div>
        <button
          disabled={disableNextStep || isLoading || !isCanContinue()}
          onClick={onNewChangesSave}
          className="button-primary mt-1"
        >
          שמור שינויים
        </button>

        {/* <div
          className={`data-situation ${isSaved ? "updated" : "not-updated"}`}
        >
          <SbsIcons icon={isLoading ? "update" : "check"} />
        </div> */}
        {!isCanContinue() || disableNextStep ? (
          <span style={{ color: "#ff0000" }}>
            {disableNextStep
              ? disableNextStep
              : !isCanContinue()
              ? "יש להוסיף לפחות תרגיל אחד לכל אימון"
              : "עבור לסיכום"}
          </span>
        ) : (
          <></>
        )}

        <AskAddExercisePopup
          plan={plan}
          askAddExerciseData={askAddExerciseData}
          setAskAddExerciseData={setAskAddExerciseData}
          // exercisesMap={execisesMap}
          addExerciseToTrain={addExerciseToTrain}
          execises={execises}
        />
        <SuperSetModal
          multiSetModal={multiSetModal}
          setMultiSetModal={setMultiSetModal}
          plan={plan}
          setPlan={setPlan}
          execises={execises}
        />
      </div>
    </DragDropContext>
  );
}

export const moveElement = (array, fromIndex, toIndex) =>
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
