import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { productService } from "../../services/productService";
import ProductsRemarksPopup from "./components/ProductsRemarksPopup";
import { MeasureTypes } from "./../../components/meal-creator/MealCreator";

export default function ViewProduct({}) {
  const [product, setProduct] = useState(productService.getEmpty());
  const [isProductRemarksOpen, setIsProductRemarksOpen] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { productId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Step By Step - Product";
  }, []);

  const remarksMap = {
    Vegan: "טבעוני",
    Vegetarian: "צמחוני",
    Pregnancy: "היריון",
    Lactose: "לקטוז",
    Peanuts: "בוטנים",
    Nuts: "אגוזים",
    Eggs: "ביצים",
    Fish: "דגים",
    Sesame: "סומסום",
    Soya: "סויה",
  };

  useEffect(() => {
    if (productId) updateProduct(productId);
  }, [productId]);

  const onSave = async () => {
    setIsLoad(true);
    if (product.id) await productService.edit(product);
    else await productService.add(product);
    setIsLoad(false);
    navigate("/manage-products");
  };

  const updateProduct = async (id) => {
    if (id) {
      const editProduct = await productService.getById(id);
      setProduct(editProduct);
    }
  };
  console.log("product", product);

  console.log('check', (!+product.numGrams && !product.customAmount), product.customAmount)

  return (
    <div className="main-container page">
      <div className="view-product-page flex column gap-1">
        <span className="sbs-title">תצוגת מוצר</span>

        <div className="first-data flex gap-0-5">
          <input
            className="sbs-input"
            type="text"
            value={product.title}
            onChange={({ target }) => {
              setProduct({ ...product, title: target.value });
            }}
            placeholder="שם המוצר"
          />
          <select
            className="sbs-select"
            value={product.type}
            onChange={({ target }) =>
              setProduct((product) => ({ ...product, type: target.value }))
            }
          >
            <option value="Carbohydrate">פחמימה</option>
            <option value="Protein">חלבון</option>
            <option value="Fat">שומן</option>
          </select>
        </div>

        <div className="inputs-container">
          <div className="flex column">
            <span>סוג כמות</span>
            <select
              className="sbs-select"
              value={product.measureType || MeasureTypes[0].value}
              onChange={({ target }) => {
                setProduct({ ...product, measureType: target.value });
              }}
            >
              {MeasureTypes.map((measureType) => {
                return (
                  <option key={measureType.value} value={measureType.value}>
                    {measureType.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex column">
            <span>כמות {product.customAmount}</span>
            <input
              className="sbs-input"
              type="number"
              value={product.amount}
              onChange={({ target }) => {
                const newValue = parseFloat(target.value); // Parse the input value as a float
                setProduct({ ...product, amount: newValue });
              }}
              placeholder="כמות"
            />
          </div>
        </div>

        <span>סימן תזונתי ל 100 גרם</span>

        <div className="inputs-container">
          <div className="flex column">
            <span>קלוריות</span>
            <input
              className="sbs-input"
              type="number"
              value={product.numCalorie}
              onChange={({ target }) => {
                setProduct({ ...product, numCalorie: target.value });
              }}
              placeholder="קלוריות"
            />
          </div>
          <div className="flex column">
            <span>סיבי תזונה</span>
            <input
              className="sbs-input"
              type="number"
              value={product.numFiber}
              onChange={({ target }) => {
                setProduct({ ...product, numFiber: target.value });
              }}
              placeholder="סיבי תזונה"
            />
          </div>
          <div className="flex column">
            <span>נתרן</span>
            <input
              className="sbs-input"
              type="number"
              value={product.numSodium}
              onChange={({ target }) => {
                setProduct({ ...product, numSodium: target.value });
              }}
              placeholder="נתרן"
            />
          </div>
          <div className="flex column">
            <span>שומן</span>
            <input
              className="sbs-input"
              type="number"
              value={product.numFat}
              onChange={({ target }) => {
                setProduct({ ...product, numFat: target.value });
              }}
              placeholder="שומן"
            />
          </div>
          <div className="flex column">
            <span>חלבונים</span>
            <input
              className="sbs-input"
              type="number"
              value={product.numProtein}
              onChange={({ target }) => {
                setProduct({ ...product, numProtein: target.value });
              }}
              placeholder="חלבונים"
            />
          </div>
          <div className="flex column">
            <span>פחמימות</span>
            <input
              className="sbs-input"
              type="number"
              value={product.numCarbohydrate}
              onChange={({ target }) => {
                setProduct({ ...product, numCarbohydrate: target.value });
              }}
              placeholder="פחמימות"
            />
          </div>
          <div className="flex column align-start">
            <span>הערות</span>
            <button
              onClick={() => setIsProductRemarksOpen(true)}
              className={
                product.remarks.length ? "button-secondary" : "button-primary"
              }
            >
              {product.remarks.length
                ? product.remarks.map((remark) => remarksMap[remark]).join(", ")
                : "הוסף הערות"}
            </button>
          </div>
        </div>

        <ProductsRemarksPopup
          isProductRemarksOpen={isProductRemarksOpen}
          product={product}
          setIsProductRemarksOpen={setIsProductRemarksOpen}
          setProduct={setProduct}
        />

        <div>
          <button
            disabled={isLoad || (!+product.numGrams && !product.amount)}
            onClick={() => onSave()}
            className="button-primary"
          >
            שמור מוצר
          </button>
        </div>
      </div>
    </div>
  );
}
