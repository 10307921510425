import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/imgs/logo.png'
import { authService } from '../../services/authService';
import { syncValidation } from '../../services/validationService';


export default function ForgotPassword(props) {

    const [identifier, setIdentifier] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Step By Step - Forgot Password";
    }, [])

    const sendEmail = async () => {
        setIsLoad(true);
        await authService.sendEmailRecoveryPassword(identifier)
        setIsLoad(false);
    }

    return (
        <div className='main-container page'>
            <div className='login-page page'>
                <div className='flex column gap-1' >
                    <div className='flex column gap-1 container' >
                        <img src={logo} alt="sbs-logo" />
                        <input
                            className='sbs-input'
                            type="email"
                            value={identifier}
                            onChange={({ target }) => { setIdentifier(target.value) }}
                            placeholder="דואר אלקטרוני"
                        />
                        <button disabled={!syncValidation.isEmailValid(identifier) || isLoad} onClick={() => { sendEmail() }} className='button-primary' >שלח איפוס סיסמא</button>
                    </div>
                </div>
            </div>
        </div>
    )
}