import { useEffect, useState } from 'react';
import SbsIcons from '../../components/SbsIcons';
import SbsTable from '../../components/SbsTable';
import { abdominalService } from '../../services/abdominalService';
import AskDeletePopup from './components/AskDeletePopup';
import AskEditPopup from './components/AskEditPopup';
import ImagePopup from './components/ImagePopup';
import YoutubePopup from './components/YoutubePopup';


var searchTimeOut = null;
export default function ManageAbdominal(props) {

    const [allAbdominal, setAllAbdominal] = useState([]);
    const [filterAbdominal, setFilterAbdominal] = useState([]);
    const [search, setSearch] = useState('');
    const [askDeleteAbdominal, setAskDeleteAbdominal] = useState(null);
    const [askEditAbdominal, setAskEditAbdominal] = useState(null);
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);
	const [askEditImage, setAskEditImage] = useState(null);
    const [askShowYoutube, setAskOpenYoutube] = useState(null);

    useEffect(() => {
        document.title = "Step By Step - Abdominals";
    }, [])

    useEffect(() => {
        updateAllAbdominal();
    }, [])

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterAbdominal();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allAbdominal])

    const updateAllAbdominal = async () => {
        setIsTableLoad(true);
        const abdominals = await abdominalService.get();
        setAllAbdominal(abdominals);
    }

    const updateFilterAbdominal = async () => {
        setIsTableLoad(false);
        const filterAbdominal = allAbdominal
            .filter(abdominal => abdominal.name.includes(search))
        setFilterAbdominal(filterAbdominal);
    }

    const onOptionEditAbdominal = (id) => {
        setAskEditAbdominal(allAbdominal.find(abdominal => abdominal.id === id));
    }

    const onOptionAskDeleteAbdominal = (id) => {
        setAskDeleteAbdominal(allAbdominal.find(abdominal => abdominal.id === id));
    }

    const editAskedEditAbdominal = async (name, description, video) => {
        if (!askEditAbdominal.id) addAskedEditAbdominal(name, description, video);
        else {
            const editedAskEditAbdominal = { ...askEditAbdominal, name, description, video }
            await abdominalService.edit(editedAskEditAbdominal);
            updateAllAbdominal()
            setAskEditAbdominal(null);
        }
    }

    const addAskedEditAbdominal = async (name, description, video) => {
        const newAbdominal = { name, description, video }
        await abdominalService.add(newAbdominal);
        updateAllAbdominal();
        setAskEditAbdominal(null);
    }

    const deleteAskedDeletedAbdominal = async () => {
        await abdominalService.remove(askDeleteAbdominal.id);
        updateAllAbdominal();
        setAskDeleteAbdominal(null);
    }

	const onOptionOpenYoutube = (id) => {
        setAskOpenYoutube(allAbdominal.find(abdominal => abdominal.id === id));
    }
    const onOptionOpenImage = (id) => {
        setAskEditImage(allAbdominal.find(abdominal => abdominal.id === id));
    }

    return (
        <div className='main-container page'>

            <div className='manage-abdominals-page'>
                <div className='header'>
                    <span className='sbs-title'>ניהול תרגילי בטן</span>
                    <span className='sbs-subtitle'>סה"כ {allAbdominal.length} תרגילי בטן</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש תרגילי בטן"
                />

                <SbsTable
                    columnMap={{ "name": "פעילות", "description": 'תיאור' }}
                    isHaveOptions={true}
                    isLoad={isTableLoad}
                    metaData={filterAbdominal}
                    options={[
                        { icon: 'delete', cb: onOptionAskDeleteAbdominal },
                        { icon: 'edit', cb: onOptionEditAbdominal },
						{ icon: 'youtube', cb: onOptionOpenYoutube },
                                        { icon: 'image', cb: onOptionOpenImage }
                    ]}
                />

                <AskDeletePopup
                    askDeleteAbdominal={askDeleteAbdominal}
                    deleteAskedDeletedAbdominal={deleteAskedDeletedAbdominal}
                    setAskDeleteAbdominal={setAskDeleteAbdominal}
                />

                <AskEditPopup
                    askEditAbdominal={askEditAbdominal}
                    editAskedEditAbdominal={editAskedEditAbdominal}
                    setAskEditAbdominal={setAskEditAbdominal}
                />

				<YoutubePopup
                    askShowYoutube={askShowYoutube}
                    setAskOpenYoutube={setAskOpenYoutube}
                />

                <ImagePopup
                    askEditImage={askEditImage}
                    setAskEditImage={setAskEditImage}
                    updateExercises={updateAllAbdominal}
                />

                <button onClick={() => { setAskEditAbdominal({ name: "", description: "" }) }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>

            </div>
        </div>
    )
}