import { useEffect, useState } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import MealCreator from "../../../components/meal-creator/MealCreator";
import { mealService } from "../../../services/mealService";

export default function MealList({
    menu,
    setMenu,
    nextStep,
    previousStep,
    isNextStepDisable
}) {

    // const [currMeal, setCurrMeal] = useState(mealService.getEmpty());
    const [currMealIdx, setCurrMealIdx] = useState(0);


    useEffect(() => {
        // setCurrMeal({ ...menu.meals[currMealIdx] })
        // return () => {
        //     updateMenuMeal(currMeal, currMealIdx);
        // }
    }, [currMealIdx])

    useEffect(() => {
        if (menu.meals.length - 1 < currMealIdx) setCurrMealIdx(menu.meals.length - 1)
    }, [menu])

    // const updateMenuMeal = (editedMeal, editedIdx) => {
    //     const meals = menu.meals.map((meal, idx) => idx === editedIdx ? { ...editedMeal } : meal)
    //     setMenu({
    //         ...menu,
    //         meals
    //     })
    // }

    const changeMeal = (isNext) => {
        // updateMenuMeal({ ...currMeal }, currMealIdx);
        if (isNext) {
            if (currMealIdx >= menu.meals.length - 1) setCurrMealIdx(0);
            else setCurrMealIdx(currMealIdx + 1);
        } else {
            if (currMealIdx <= 0) setCurrMealIdx(menu.meals.length - 1);
            else setCurrMealIdx(currMealIdx - 1);
        }
    }

    const setMeal = (getUpdateMeal) => {
        let updateMeal = mealService.getEmpty();
        if (typeof getUpdateMeal === 'function') {
            updateMeal = getUpdateMeal(menu.meals[currMealIdx]);
        } else {
            updateMeal = getUpdateMeal
        }
        const meals = menu.meals.map((mealItem, idx) => idx === currMealIdx ? updateMeal : mealItem)
        setMenu({ ...menu, meals })


    }

    const changeMealByIndex = (index) => {
        // updateMenuMeal();
        setCurrMealIdx(index)
    }


    return (
        <div className="flex column gap-1" >

            <div className='sbs-dots-container'>
                {
                    menu.meals.map((meal, idx) => (
                        <div key={idx} className={`dot ${currMealIdx === idx ? 'choosen' : ''}`} onClick={() => changeMealByIndex(idx)} >
                        </div>
                    ))
                }
            </div>
            {
                !!menu.meals.length &&
                <div className="flex align-center">
                    <button
                        onClick={() => changeMeal(false)}
                        className='change-meal-button back'
                        disabled={currMealIdx <= 0}
                    >
                        <IoIosArrowForward
                            fill={currMealIdx <= 0 ? '#ff7b3b' : '#ff5603'}
                        />

                    </button>
                    <div className="grow-1 flex column gap-1" >
                        <span className="sbs-title" >{menu.meals[currMealIdx].title}</span>
                        <span className="sbs-subtitle" >{menu.meals[currMealIdx].description}</span>
                        <MealCreator
                            meal={menu.meals[currMealIdx]}
                            setMeal={setMeal}
                            isEditable={true}
                        />
                    </div>
                    <button
                        onClick={() => changeMeal(true)}
                        className='change-meal-button next'
                        disabled={currMealIdx >= menu.meals.length - 1}
                    >
                        <IoIosArrowBack fill={currMealIdx >= menu.meals.length - 1 ? '#ff7b3b' : '#ff5603'} />
                    </button>

                </div>

            }
            <div className="flex justify-space-between gap-1" >
                <button onClick={() => previousStep()} className="button-secondary" >חזור ליצירת הארוחות</button>
                <button disabled={isNextStepDisable} onClick={() => nextStep()} className="button-primary">עבור לסיכום</button>

            </div>
        </div>
    )

}