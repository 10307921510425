import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { exerciseService } from "../../../services/exerciseService";
import { motivationalSentenceService } from "../../../services/motivationalSentenceService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditExercises, askEditExercises, setAskEditExercises }) {

    const [isOpen, setIsOpen] = useState(false);
    const [newExercises, setNewExercises] = useState(exerciseService.getEmpty());
    const types = exerciseService.getTypes()



    useEffect(() => {
        if (askEditExercises) {
            setIsOpen(true);
            setNewExercises({ ...askEditExercises })
        }
        else setIsOpen(false);
    }, [askEditExercises])



    return (
        <div onClick={() => setAskEditExercises(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    {
                        askEditExercises?.id ? (
                            <span>ערוך את {askEditExercises?.title}</span>
                        ) : (
                            <span>צור תרגיל</span>
                        )

                    }
                    <div className='close-popup' onClick={() => setAskEditExercises(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <div className="flex column gap-0-5">


                        <select
                            className="sbs-select"
                            value={newExercises.type}
                            onChange={({ target }) => setNewExercises(exercise => ({ ...exercise, type: target.value }))}
                        >
                            {
                                types.map(type => (
                                    <option key={type} value={type}> {exerciseService.getTypeHebrewMap()[type]}</option>
                                ))
                            }

                        </select>

                        <input
                            className='sbs-input'
                            type="text"
                            value={newExercises.title}
                            onChange={({ target }) => { setNewExercises(exercise => ({ ...exercise, title: target.value })) }}
                            placeholder="שם התרגיל"
                        />

                        <input
                            className='sbs-input'
                            type="text"
                            value={newExercises.youtubeUrl}
                            onChange={({ target }) => { setNewExercises(exercise => ({ ...exercise, youtubeUrl: target.value })) }}
                            placeholder="קישור לסרטון של התרגיל"
                        />

                        <textarea
                            className="sbs-input"
                            type="text"
                            value={newExercises.description}
                            onChange={({ target }) => { setNewExercises(exercise => ({ ...exercise, description: target.value })) }}
                            placeholder="תיאור"
                        />

                    </div>



                </div>
                <div className='popup-footer'>
                    <button className='button-primary' onClick={() => editAskedEditExercises(newExercises)} >שמירה</button>
                    {/* <button className='button-secondary' onClick={() => setAskEditExercises(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}