import { errorService } from "./errorService";
import httpService from "./httpService";

export const menuTemplateService = {
  getEmpty,
  add,
  remove,
  get,
  edit,
  getById,
  reOrder,
};

const ENDPOINT = "nutrition/menu_templates";

async function remove(id) {
  try {
    const res = await httpService.delete(ENDPOINT + `/${id}`);
    errorService.updateError({
      msg: "התבנית נמחקה",
      type: "warning",
      time: Date.now(),
    });
    return res;
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function get() {
  const res = await httpService.get(ENDPOINT);
  const templates = res.data;
  return templates;
}

async function edit(editedTemplate) {
  const body = {
    menuTemplateId: editedTemplate.id,
    ...editedTemplate,
    items: editedTemplate.mealIds,
  };
  try {
    const res = await httpService.put(ENDPOINT, body);
    errorService.updateError({
      msg: "התבנית עודכנה",
      type: "success",
      time: Date.now(),
    });
    return res.data;
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function add(newTemplate) {
  console.log(newTemplate);
  const body = {
    ...newTemplate,
  };
  try {
    const res = await httpService.post(ENDPOINT, body);
    return res.data;
  } catch {
    console.log("error", newTemplate);
    errorService.updateError({
      msg: "התבנית נוספה",
      type: "success",
      time: Date.now(),
    });
  }
}

async function getById(templateID) {
  const res = await httpService.get(ENDPOINT + `/${templateID}`);
  console.log(res.data);
  const template = {
    ...res.data,
    mealsId: res.data.mealTemplates.map((mt) => mt.id),
  };
  console.log(template);
  return template;
}

function getEmpty() {
  return {
    title: "",
    description: "",
    mealsId: [],
  };
}

async function reOrder(order) {
  const res = await httpService.put(ENDPOINT + `/reorder`, {
    MenuIDs: order,
  });

  return res.data;
}
