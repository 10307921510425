import { useEffect, useState } from "react";
import PriorityPicker from "../../../components/PriorityPicker";
import SbsIcons from "../../../components/SbsIcons";
import { taskService } from "../../../services/taskService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditdTask, askEditTask, setAskEditTask, taskOptionsMap, employees }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isSaveLoad, setIsSaveLoad] = useState(false);
    const [task, setTask] = useState(taskService.getEmpty());

    useEffect(() => {
        if (askEditTask) {
            setTask({ ...askEditTask })
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditTask])

    const onSave = () => {
        setIsSaveLoad(true);
        editAskedEditdTask(task);
        setAskEditTask(null);
        setIsSaveLoad(false);
    }

    return (
        <div onClick={() => setAskEditTask(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>ערוך משימה</span>
                    <div className='close-popup' onClick={() => setAskEditTask(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body flex column gap-1'>
                    <input
                        className='sbs-input'
                        type="text"
                        value={task.title}
                        onChange={({ target }) => { setTask({ ...task, title: target.value }) }}
                        placeholder="שם המשימה"
                    />
                    <select
                        className="sbs-select type"
                        value={task.owner}
                        onChange={({ target }) => setTask({ ...task, owner: target.value })}
                    >
                        <option value="">בחירת מבצע המשימה</option>
                        {
                            employees.map(employee => (
                                <option
                                    key={employee.id}
                                    value={employee.id}
                                >
                                    {(!employee.firstName && !employee.lastName) ? employee.email : employee.firstName + " " + employee.lastName}
                                </option>
                            ))
                        }
                    </select>

                    <input
                        className='sbs-input'
                        type="date"
                        onKeyDown={(ev) => ev.preventDefault()}
                        min={utilService.timestampToDatePickerTime(Date.now())}
                        value={utilService.timestampToDatePickerTime(task.deadline)}
                        onChange={({ target }) => { setTask({ ...task, deadline: utilService.datePickerTimeToTimestamp(target.value) }) }}
                        placeholder="מבצע המשימה"
                    />

                    <select
                        className="sbs-select type"
                        value={task.status}
                        onChange={({ target }) => setTask({ ...task, status: target.value })}
                    >
                        {
                            Object.keys(taskOptionsMap).map(key => (
                                <option key={key} value={key}>{taskOptionsMap[key]}</option>
                            ))
                        }
                    </select>


                    <PriorityPicker
                        priority={task.priority}
                        setPriority={(newPriority) => { setTask({ ...task, priority: newPriority }) }}
                    />




                </div>
                <div className='popup-footer'>
                    <button
                        disabled={!task.owner || !task.title || task.deadline < Date.now()}
                        className='button-primary'
                        onClick={() => onSave()}
                    >
                        שמירה
                    </button>
                    {/* <button className='button-secondary' onClick={() => setAskEditTask(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}