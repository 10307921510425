import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { globalVariable } from "../../../services/globalVariable";
import { motivationalSentenceService } from "../../../services/motivationalSentenceService";
import { salaryService } from "../../../services/salaryService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditSalary, askEditSalary, setAskEditSalary }) {

    const [isOpen, setIsOpen] = useState(false);
    const [newSalary, setNewSalary] = useState(salaryService.getEmpty());

    useEffect(() => {
        if (askEditSalary) {
            setNewSalary({ ...askEditSalary });
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditSalary])

    const paymentMathods = {
        Cash: 'מזומן',
        Check: "צ'ק",
        CreditCard: "כרטיס אשראי",
        BankTransfer: "העברה בנקאית",
        Bit: "ביט",
    }

    useEffect(() => {
        console.log(newSalary);
    }, [newSalary])







    return (
        <div onClick={() => setAskEditSalary(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    {
                        askEditSalary?.id ? (
                            <span>ערוך את התשלום</span>
                        ) : (
                            <span>צור תשלום חדש</span>
                        )

                    }
                    <div className='close-popup' onClick={() => setAskEditSalary(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <div className="salaries-inputs flex column gap-1" >
                        {/* <input
                            className="sbs-input"
                            type="date"
                            value={datePickerDate}
                            onChange={({ target }) => setDatePickerDate(target.value)}
                        /> */}

                        <select
                            className="sbs-select"
                            value={newSalary.paymantMethod}
                            onChange={({ target }) => setNewSalary(salary => ({ ...salary, paymantMethod: target.value }))}
                        >
                            {
                                Object.keys(paymentMathods).map(key => (
                                    <option key={key}
                                        value={key}
                                    >
                                        {paymentMathods[key]}
                                    </option>
                                ))
                            }
                        </select>

                        <input
                            className="sbs-input"
                            type="number"
                            value={newSalary.amount}
                            onChange={({ target }) => { setNewSalary(salary => ({ ...salary, amount: target.value })) }}
                            placeholder="סכום תשלום"
                        />

                        <input
                            className="sbs-input"
                            type="number"
                            value={newSalary.year}
                            onChange={({ target }) => { setNewSalary(salary => ({ ...salary, year: target.value })) }}
                            placeholder="שנה"
                        />

                        <select
                            className="sbs-select"
                            value={newSalary.month}
                            onChange={({ target }) => setNewSalary(salary => ({ ...salary, month: target.value }))}
                        >
                            {
                                Object.keys(globalVariable.monthNumberHebrewMap).map(key => (
                                    <option key={key} value={key}>{globalVariable.monthNumberHebrewMap[key]}</option>
                                ))
                            }
                        </select>


                    </div>

                </div>
                <div className='popup-footer'>
                    <button
                        className='button-primary'
                        disabled={+newSalary.year < 1900 || +newSalary.amount <= 0}
                        onClick={() => editAskedEditSalary(newSalary)}
                    >
                        שמירה
                    </button>
                    {/* <button className='button-secondary' onClick={() => setAskEditSalary(null)} >לא</button> */}
                </div>
            </div>
        </div >
    )
}