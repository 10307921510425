import { useEffect, useRef, useState } from "react";
import SbsIcons from "./SbsIcons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle, gridLength) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: gridLength * 2,
  margin: `0 0 ${gridLength}px 0`,

  display: "table",
  tableLayout: "fixed",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",
  width: "100% !important",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function SbsTable({
  metaData,
  columnMap,
  isHaveOptions,
  options,
  isHavePaginator,
  isLoad,
  isHaveReorder = false,
  reOrder = null,
}) {
  const PAGE_ITEMS = 25;
  const [pages, setPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(5);
  const [disabledButton, setDisabledButton] = useState(null);
  const timeoutRef = useRef();
  const [reorder, setReorder] = useState(null);

  useEffect(() => {
    setPages(Math.ceil(metaData.length / PAGE_ITEMS));
    setSelectedPage(0);
  }, [metaData]);

  // const onClickFirstInRow = (idx, id) => {
  //     if (idx === 0 && cb) cb(id)
  // }

  const reOrderFunc = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reOrderFunc(
      OrderedItems,
      result.source.index,
      result.destination.index
    );

    reOrder(items);
  };

  const OrderedItems = (
    isHavePaginator
      ? metaData.slice(
          selectedPage * PAGE_ITEMS,
          Math.min(metaData.length, selectedPage * PAGE_ITEMS + PAGE_ITEMS)
        )
      : metaData
  ).sort((a, b) => {
    if (!reorder) return 0;
    if (typeof a[reorder.key] !== typeof b[reorder.key]) return 0;
    if (typeof a[reorder.key] === "string") {
      if (reorder.direction === "up") {
        return a[reorder.key].localeCompare(b[reorder.key]);
      }
      return b[reorder.key].localeCompare(a[reorder.key]);
    } else if (typeof a[reorder.key] === "number") {
      if (reorder.direction === "up") {
        return a[reorder.key] - b[reorder.key];
      }
      return b[reorder.key] - a[reorder.key];
    }
    return 0;
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="sbs-table-container">
        <table className="sbs-table">
          <thead>
            <tr>
              {Object.keys(columnMap).map((key, idx) => (
                <th key={idx}>
                  {columnMap[key]}

                  {isHaveReorder && (
                    <div className="reorder-buttons">
                      <button
                        className="reorder-button"
                        onClick={() => {
                          setReorder({
                            key: key,
                            direction: "up",
                          });
                        }}
                      >
                        <SbsIcons icon="arrow-up" />
                      </button>
                      <button
                        className="reorder-button"
                        onClick={() => {
                          setReorder({
                            key: key,
                            direction: "down",
                          });
                        }}
                      >
                        <SbsIcons icon="arrow-down" />
                      </button>
                    </div>
                  )}
                </th>
              ))}
              {isHaveOptions && <th>אופציות</th>}
            </tr>
          </thead>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ position: "relative", width: "100%" }}
              >
                {OrderedItems.map((data, idx) =>
                  reOrder ? (
                    <Draggable
                      key={data.id}
                      draggableId={data.id.toString()}
                      index={idx}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided?.draggableProps?.style,
                        metaData.length
                      )}
                    >
                      {(provided, snapshot) => (
                        <tr
                          key={idx}
                          ref={provided.innerRef}
                          style={{
                            width: "100% !important",
                            display: "table",
                          }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {Object.keys(columnMap).map((key, idx) => (
                            <td key={key}>{data[key]}</td>
                          ))}
                          {isHaveOptions && (
                            <td>
                              <div className="options">
                                {options.map((option, idx) => (
                                  <button
                                    className="button-primary"
                                    disabled={
                                      disabledButton &&
                                      disabledButton.icon === option.icon &&
                                      disabledButton.id === data.id
                                    }
                                    key={idx}
                                    onClick={() => {
                                      clearTimeout(timeoutRef.current);
                                      setDisabledButton({
                                        icon: option.icon,
                                        id: data.id,
                                      });
                                      option.cb(data.id);
                                      timeoutRef.current = setTimeout(() => {
                                        setDisabledButton(null);
                                      }, 1000);
                                    }}
                                    title={option.icon}
                                  >
                                    <SbsIcons icon={option.icon} />
                                  </button>
                                ))}
                              </div>
                            </td>
                          )}
                        </tr>
                      )}
                    </Draggable>
                  ) : (
                    <tr key={idx} draggableId={data.id} index={idx}>
                      {Object.keys(columnMap).map((key, idx) => (
                        <td key={key}>{data[key]}</td>
                      ))}
                      {isHaveOptions && (
                        <td>
                          <div className="options">
                            {options.map((option, idx) => (
                              <button
                                className="button-primary"
                                disabled={
                                  disabledButton &&
                                  disabledButton.icon === option.icon &&
                                  disabledButton.id === data.id
                                }
                                key={idx}
                                onClick={() => {
                                  clearTimeout(timeoutRef.current);
                                  setDisabledButton({
                                    icon: option.icon,
                                    id: data.id,
                                  });
                                  option.cb(data.id);
                                  timeoutRef.current = setTimeout(() => {
                                    setDisabledButton(null);
                                  }, 1000);
                                }}
                                title={option.icon}
                              >
                                <SbsIcons icon={option.icon} />
                              </button>
                            ))}
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                )}
                {isLoad && (
                  <tr>
                    <td
                      colSpan={
                        Object.keys(columnMap).length + (isHaveOptions ? 1 : 0)
                      }
                    >
                      <div className="loader"></div>
                    </td>
                  </tr>
                )}
                {!metaData.length && !isLoad && (
                  <tr>
                    <td
                      colSpan={
                        Object.keys(columnMap).length + (isHaveOptions ? 1 : 0)
                      }
                    >
                      אין מידע להציג
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </Droppable>
        </table>
        {isHavePaginator && pages > 1 && (
          <div className="paginator">
            {pages <= 7 &&
              Array.from(Array(pages).keys()).map((page) => (
                <div
                  key={page}
                  className={`page ${page === selectedPage ? "curr" : ""}`}
                  onClick={() => setSelectedPage(page)}
                >
                  {page + 1}
                </div>
              ))}
            {pages > 7 &&
              ((selectedPage > 3 && selectedPage < pages - 4 && (
                <>
                  <div
                    onClick={() => setSelectedPage(0)}
                    className={`page ${0 === selectedPage ? "curr" : ""}`}
                  >
                    1
                  </div>
                  <span>...</span>
                  <div
                    onClick={() => setSelectedPage(selectedPage - 2)}
                    className={`page`}
                  >
                    {selectedPage - 1}
                  </div>
                  <div
                    onClick={() => setSelectedPage(selectedPage - 1)}
                    className={`page`}
                  >
                    {selectedPage}
                  </div>
                  <div
                    onClick={() => setSelectedPage(selectedPage)}
                    className={`page curr`}
                  >
                    {selectedPage + 1}
                  </div>
                  <div
                    onClick={() => setSelectedPage(selectedPage + 1)}
                    className={`page`}
                  >
                    {selectedPage + 2}
                  </div>
                  <div
                    onClick={() => setSelectedPage(selectedPage + 2)}
                    className={`page`}
                  >
                    {selectedPage + 3}
                  </div>
                  <span>...</span>
                  <div
                    onClick={() => setSelectedPage(pages - 1)}
                    className={`page ${0 === pages - 1 ? "curr" : ""}`}
                  >
                    {pages}
                  </div>
                </>
              )) ||
                (selectedPage <= 3 && (
                  <>
                    {Array.from(Array(selectedPage + 1).keys()).map((page) => (
                      <div
                        key={page}
                        className={`page ${
                          page === selectedPage ? "curr" : ""
                        }`}
                        onClick={() => setSelectedPage(page)}
                      >
                        {page + 1}
                      </div>
                    ))}
                    <div
                      onClick={() => setSelectedPage(selectedPage + 1)}
                      className={`page`}
                    >
                      {selectedPage + 2}
                    </div>
                    <div
                      onClick={() => setSelectedPage(selectedPage + 2)}
                      className={`page`}
                    >
                      {selectedPage + 3}
                    </div>
                    <span>...</span>
                    <div
                      onClick={() => setSelectedPage(pages - 1)}
                      className={`page ${0 === pages - 1 ? "curr" : ""}`}
                    >
                      {pages}
                    </div>
                  </>
                )) ||
                (selectedPage >= pages - 4 && (
                  <>
                    <div
                      onClick={() => setSelectedPage(0)}
                      className={`page ${0 === selectedPage ? "curr" : ""}`}
                    >
                      1
                    </div>
                    <span>...</span>
                    <div
                      onClick={() => setSelectedPage(selectedPage - 2)}
                      className={`page`}
                    >
                      {selectedPage - 1}
                    </div>
                    <div
                      onClick={() => setSelectedPage(selectedPage - 1)}
                      className={`page`}
                    >
                      {selectedPage}
                    </div>
                    <div
                      onClick={() => setSelectedPage(selectedPage)}
                      className={`page curr`}
                    >
                      {selectedPage + 1}
                    </div>
                    {Array.from(Array(pages - selectedPage - 1).keys())
                      .reverse()
                      .map((pageSub) => (
                        <div
                          key={pageSub}
                          className={`page`}
                          onClick={() => setSelectedPage(pages - pageSub - 1)}
                        >
                          {pages - pageSub}
                        </div>
                      ))}
                  </>
                )))}
          </div>
        )}
        <div></div>
      </div>
    </DragDropContext>
  );
}
