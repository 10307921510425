import { utilService } from "../../services/utilService"
import SbsIcons from "../SbsIcons"

export default function FilterByRemarksPopup({ selectedRemarks, setSelectedRemarks, isOpen, setIsOpen }) {

    const remarksMap = {
        Vegan: 'טבעוני',
        Vegetarian: 'צמחוני',
        Pregnancy: 'היריון',
        Lactose: 'לקטוז',
        Peanuts: 'בוטנים',
        Nuts: 'אגוזים',
        Eggs: 'ביצים',
        Fish: 'דגים',
        Sesame: 'סומסום',
        Soya: 'סויה',
    }

    const toggleRemark = (remark) => {
        setSelectedRemarks(prev => {
            if (prev.includes(remark)) return prev.filter(r => r !== remark)
            return [...prev, remark]
        })
    }

    return (
        <div onClick={() => setIsOpen(false)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>ערוך היערות</span>
                    <div className='close-popup' onClick={() => setIsOpen(false)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <form className="flex column gap-0-5">
                        {
                            isOpen && Object.keys(remarksMap).map((remarkKey) => (
                                <div key={remarkKey} className="group sbs-checkbox-container">
                                    <input
                                        id={remarkKey}
                                        name={remarkKey}
                                        type="checkbox"
                                        checked={selectedRemarks.includes(remarkKey)}
                                        onChange={({ target }) => toggleRemark(remarkKey)}
                                    />
                                    <label htmlFor={remarkKey}>{remarksMap[remarkKey]}</label>
                                </div>
                            ))
                        }
                    </form>
                </div>
                <div className='popup-footer'>
                    <button className='button-secondary' onClick={() => setIsOpen(false)} >סגור</button>
                </div>
            </div>
        </div>
    )
}