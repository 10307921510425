import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const groupService = {
    get,
    remove,
    getEmpty,
    edit,
    add
}

const ENDPOINT = 'general/groups'

let groups = [
    {
        "id": "g102",
        "title": "נוער",
        customers: ['c101', 'c102']
    },
    {
        "id": "g103",
        "title": "בנים",
        customers: ['c101']
    },
    {
        "id": "g104",
        "title": "בנות",
        customers: ['c102']
    },
]

function getEmpty() {
    return {
        "id": "",
        "title": "",
        "customers": [],
    };
}

async function get() {
    const res = await httpService.get(ENDPOINT)
    var groups = [];
    res.data.forEach((group) => {
        const { id, name, customers } = group;
        groups.push({ id, title: name, customers })
    });
    return groups;
}

async function remove(id) {
    try {

        const res = await httpService.delete(ENDPOINT + `/${id}`);
        errorService.updateError({ msg: 'קבוצה נמחקה', type: 'warning', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(group) {
    try {
        const { id, title } = group;
        const res = await httpService.put(ENDPOINT, { id, newName: title });
        errorService.updateError({ msg: 'הקבוצה עודכנה', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(group) {
    try {
        const res = await httpService.post(ENDPOINT, { name: group.title });
        errorService.updateError({ msg: 'קבוצה נוספה', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}


