import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SbsIcons from "../../../components/SbsIcons";
import { utilService } from "../../../services/utilService";
import Select from "react-select";
import { exerciseService } from "../../../services/exerciseService";
import SbsTable from "../../../components/SbsTable";
import { errorService } from "../../../services/errorService";

export default function AskAddExercisePopup({
  askAddExerciseData,
  setAskAddExerciseData,
  execises,
  addExerciseToTrain,
  plan,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [filterExecises, setFilterExecises] = useState([]);
  const [search, setSearch] = useState("");
  const [execiseTypeFilter, setExeciseTypeFilter] = useState([]);

  const toggleTypeFilter = (type) => {
    if (execiseTypeFilter.some((filterType) => type === filterType))
      setExeciseTypeFilter(
        execiseTypeFilter.filter((filterType) => type !== filterType)
      );
    else setExeciseTypeFilter([...execiseTypeFilter, type]);
  };

  useEffect(() => {
    if (askAddExerciseData != null) {
      setIsOpen(true);
      setSearch("");
    } else setIsOpen(false);
  }, [askAddExerciseData]);

  // useEffect(() => {
  //     console.log(
  //         plan.trains
  //             .find(train => train.id === askAddExerciseData?.trainId)?.trainExercisesMap
  //     );
  // }, [execises, askAddExerciseData, search, plan, execiseTypeFilter])

  useEffect(() => {
    setFilterExecises(
      execises
        .filter(
          (exercise) =>
            exercise.title.includes(search) &&
            (!execiseTypeFilter.length ||
              execiseTypeFilter.some(
                (execiseType) => execiseType === exercise.type
              ))
        )
        .map((exercise) => {
          const isOnPlan = Object.values(
            plan.trains.find(
              (train) => train.id === askAddExerciseData?.trainId
            )?.trainExercisesMap ?? {}
          ).some((trainExercise) => trainExercise.execiseId == exercise.id);
          return {
            ...exercise,
            isOnPlan: isOnPlan ? (
              <span style={{ color: "#4BB543" }}>✓</span>
            ) : (
              <span style={{ color: "#8B0000" }}>✕</span>
            ),
            isOnPlanBool: isOnPlan,
          };
        })
    );
  }, [execises, askAddExerciseData, search, plan, execiseTypeFilter]);

  const onAddExecise = (id) => {
    const currExecise = execises.find((execise) => execise.id === id);
    console.log(currExecise);
    addExerciseToTrain(
      id,
      askAddExerciseData.exerciseType,
      currExecise.description
    );
    errorService.updateError({
      msg: "תרגיל נוסף לתוכנית אימונים",
      type: "success",
      time: Date.now(),
    });
  };

  return (
    <div
      onClick={() => setAskAddExerciseData(null)}
      className={
        isOpen ? "sbs-popup-container open" : "sbs-popup-container close"
      }
    >
      <div
        onClick={(ev) => utilService.stopPropagation(ev)}
        className="sbs-popup"
      >
        <div className="popup-header">
          <span>
            הוסף תרגיל{" "}
            {
              exerciseService.getTypeHebrewMap()[
                askAddExerciseData?.exerciseType
              ]
            }
          </span>
          <div
            className="close-popup"
            onClick={() => setAskAddExerciseData(null)}
          >
            <SbsIcons icon="close"></SbsIcons>
          </div>
        </div>
        <div className="popup-body">
          {askAddExerciseData && (
            <div>
              {execises.length ? (
                // <Select
                //     placeholder="בחר תרגיל"
                //     className="oversight-input-select"
                //     // options format: {value:any,label:string}
                //     options={execises.map(exercise => ({ value: exercise.id, label: exercise.title }))}
                //     onChange={(newVal) => { setChoosenExerciseId(newVal.value) }}
                //     isMulti={false}
                //     classNamePrefix="oversight-select"
                // />
                <div className="flex column gap-1">
                  <div className="flex gap-1">
                    <input
                      className={`sbs-input search`}
                      type="text"
                      value={search}
                      onChange={({ target }) => {
                        setSearch(target.value);
                      }}
                      placeholder="חפש תרגיל"
                    />

                    {Object.keys(exerciseService.getTypeHebrewMap()).map(
                      (key) => (
                        <button
                          className={`button-${
                            execiseTypeFilter.some((type) => type === key)
                              ? "primary"
                              : "secondary"
                          }`}
                          key={key}
                          onClick={() => toggleTypeFilter(key)}
                        >
                          {exerciseService.getTypeHebrewMap()[key]}
                        </button>
                      )
                    )}
                  </div>
                  <SbsTable
                    columnMap={{ isOnPlan: "קיים באימון", title: "שם התרגיל" }}
                    isHaveOptions={true}
                    metaData={filterExecises.filter((f) => !f.isOnPlanBool)}
                    options={[{ icon: "add", cb: onAddExecise }]}
                  />
                </div>
              ) : (
                <span>
                  אין תרגילים{" "}
                  <Link to="/manage-exercises">עבור לניהול תרגילים</Link>
                </span>
              )}
            </div>
          )}
        </div>
        <div className="popup-footer">
          {/* <button disabled={!choosenExerciseId} className='button-primary' onClick={() => addExerciseToTrain(choosenExerciseId, askAddExerciseData.exerciseType)} >הוסף</button> */}
          <button
            className="button-secondary"
            onClick={() => setAskAddExerciseData(null)}
          >
            סגור
          </button>
        </div>
      </div>
    </div>
  );
}
