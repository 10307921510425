const SbsStepper = ({ stepsLengh, currStep, setCurrStep, disableSteps }) => {
  const isPrevStepDisabed = typeof disableSteps[currStep - 1] === "undefined" || disableSteps[currStep - 1];
  const isNextStepDisabed = typeof disableSteps[currStep + 1] === "undefined" || disableSteps[currStep + 1];

  console.log("steps", disableSteps[currStep + 1], typeof disableSteps[currStep + 1])

  return (
    <div className="sbs-stepper" style={{ position: "relative" }}>
      {Array.from({ length: stepsLengh }, (k, v) => "").map((_, idx) => (
        <button
          disabled={disableSteps && disableSteps[idx]}
          onClick={() => setCurrStep(idx)}
          key={idx}
          className={`${currStep === idx ? "curr" : ""}`}
        >
          <span>{idx + 1}</span>
        </button>
      ))}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          marginTop: "10px",
          position: "absolute",
          right: "25%",
          top: 20,
        }}
      >
        <button
          disabled={disableSteps && isPrevStepDisabed}
          onClick={() =>
            isPrevStepDisabed ? undefined : setCurrStep(currStep - 1)
          }
          className="button-primary step-button"
          style={buttonStyles}
        >
          לשלב הקודם
        </button>
        <button
          disabled={disableSteps && isNextStepDisabed}
          onClick={() =>
            isNextStepDisabed ? undefined : setCurrStep(currStep + 1)
          }
          className="button-primary"
          style={buttonStyles}
        >
          לשלב הבא
        </button>
      </div>
    </div>
  );
};

const buttonStyles = {
  borderRadius: "var(--sbs-border-radius)",
  width: "fit-content",
  fontSize: "15px",
  padding: "5px",
  border: "1px solid var(--sbs-primary)",
};

export default SbsStepper;
