import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const q_aService = {
    get,
    remove,
    edit,
    add
    // getRandom,
    // getEmpty,
}

const ENDPOINT = "general/faqs";

let q_a = [
    {
        "id": "qa101",
        "question": "אור דמרי המלך",
        "answer": "ברור זאת שאלה בכלל",
    },

]

async function get() {
    const response = await httpService.get(ENDPOINT);
    return response.data;
}

async function remove(id) {
    try {
        const response = await httpService.delete(ENDPOINT + "/" + id);
        errorService.updateError({ msg: 'השאלה נמחקה', type: 'warning', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(editedQ_A) {
    try {
        const response = await httpService.put(ENDPOINT, editedQ_A);
        errorService.updateError({ msg: 'השאלה עודכנה', type: 'success', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(newQ_A) {
    try {
        const response = await httpService.post(ENDPOINT, newQ_A);
        errorService.updateError({ msg: 'השאלה נוספה', type: 'success', time: Date.now() })
        return response;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
