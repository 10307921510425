import { useEffect, useState } from 'react';
import SbsTable from '../../components/SbsTable';
import { motivationalSentenceService } from '../../services/motivationalSentenceService';
import { useNavigate } from 'react-router-dom';
import SbsIcons from '../../components/SbsIcons';
import AskDeletePopup from './components/AskDeletePopup';
import AskEditPopup from './components/AskEditPopup';
import { groupService } from '../../services/groupService';

var searchTimeOut = null;
export default function ManageMotivationalSentences(props) {
    const [filterMotivationalSentences, setFilterMotivationalSentences] = useState([]);
    const [allMotivationalSentences, setAllMotivationalSentences] = useState([]);
    const [groups, setGroups] = useState([]);
    const [search, setSearch] = useState('');
    const [askDeleteSentence, setAskDeleteSentence] = useState(null);
    const [askEditSentence, setAskEditSentence] = useState(null);
    const navigate = useNavigate();
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);

    useEffect(() => {
        document.title = "Step By Step - Motivational Sentences";
    }, [])

    useEffect(() => {
        updateGroups()
    }, [])

    useEffect(() => {
        updateAllMotivationalSentences();
    }, [groups])



    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterMotivationalSentences();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allMotivationalSentences])

    const updateFilterMotivationalSentences = async () => {
        setIsTableLoad(false);
        const filterMotivationalSentences = allMotivationalSentences
            .filter(motivationalSentence => motivationalSentence.sentence.includes(search))
            .map(motivationalSentence => {
                console.log(groups);
                console.log(motivationalSentence.groups);
                const newGroups = groups.length && motivationalSentence.groups.map(groupId => groups.find(group => group.id === groupId)?.title);
                return { ...motivationalSentence, groups: newGroups ? newGroups.join(', ') : '' }
            });
        setFilterMotivationalSentences(filterMotivationalSentences);
    }

    const updateAllMotivationalSentences = async () => {
        setIsTableLoad(true);
        const motivationalSentences = await motivationalSentenceService.get();
        setAllMotivationalSentences(motivationalSentences);
    }

    const updateGroups = async () => {
        const groups = await groupService.get();
        setGroups(groups);
    }

    const onOptionEditMotivationalSentence = (id) => {
        setAskEditSentence(allMotivationalSentences.find(sentence => sentence.id === id));
    }

    const onOptionAskDeleteSentence = (id) => {
        setAskDeleteSentence(allMotivationalSentences.find(sentence => sentence.id === id));
    }

    const editAskedEditSentence = async (sentence, groups) => {
        if (!askEditSentence.id) addAskedEditSentence(sentence, groups);
        else {
            const editedAskEditSentence = { ...askEditSentence, sentence, groups }
            await motivationalSentenceService.edit(editedAskEditSentence);
            updateAllMotivationalSentences();
            setAskEditSentence(null);
        }
    }

    const addAskedEditSentence = async (sentence, groups) => {
        const newSentence = { sentence, groups }
        await motivationalSentenceService.add(newSentence);
        updateAllMotivationalSentences();
        setAskEditSentence(null);
    }

    const deleteAskedDeletedSentence = async () => {
        await motivationalSentenceService.remove(askDeleteSentence.id);
        updateAllMotivationalSentences();
        setAskDeleteSentence(null);
    }

    return (
        <div className='main-container page'>
            <div className='manage-motivational-sentences-page'>

                <div className='header'>
                    <span className='sbs-title'>ניהול משפטי מוטיבציה</span>
                    <span className='sbs-subtitle'>סה"כ {allMotivationalSentences.length} משפטי מוטיבציה</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש משפט"
                />

                <SbsTable
                    columnMap={{ "sentence": "משפט מוטיבציה", "groups": "קבוצות" }}
                    isHaveOptions={true}
                    metaData={filterMotivationalSentences}
                    isLoad={isTableLoad}
                    options={[
                        { icon: 'delete', cb: onOptionAskDeleteSentence },
                        { icon: 'edit', cb: onOptionEditMotivationalSentence }
                    ]}
                ></SbsTable>

                <AskDeletePopup
                    askDeleteSentence={askDeleteSentence}
                    deleteAskedDeletedSentence={deleteAskedDeletedSentence}
                    setAskDeleteSentence={setAskDeleteSentence}
                />

                <AskEditPopup
                    askEditSentence={askEditSentence}
                    editAskedEditSentence={editAskedEditSentence}
                    setAskEditSentence={setAskEditSentence}
                    groups={groups}
                />

                <button onClick={() => { setAskEditSentence({ sentence: "", groups: [] }) }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>
            </div>
        </div>
    )
}