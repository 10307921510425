import { useEffect, useState } from "react";
import { mealService } from "../../services/mealService";
import { productService } from "../../services/productService";
import { utilService } from "../../services/utilService";
import SbsIcons from "../SbsIcons";
import SbsTable from "../SbsTable";

var searchTimeOut = null;
export default function LoadMealPopup({
    isLoadMealOpen,
    setIsLoadMealOpen,
    loadMeal
}) {

    const [allMeals, setAllMeals] = useState([]);
    const [filterMeals, setFilterMeals] = useState([]);
    const [search, setSearch] = useState('');
    const [mealChoosenTypes, setMealChoosenTypes] = useState([]);
    const mealTypes = {
        Breakfast: 'ארוחת בוקר',
        Lunch: 'ארוחת צהריים',
        Dinner: 'ארוחת ערב',
        Snack: "חטיף",
    }


    useEffect(() => {
        updateAllMeals()
    }, [])

    useEffect(() => {
        if (searchTimeOut) clearTimeout(searchTimeOut);
        searchTimeOut = setTimeout(() => {
            updateFilterMeals();
        }, 500)
    }, [search, allMeals, mealChoosenTypes])



    const updateAllMeals = async () => {
        const meals = await mealService.get();
        setAllMeals(meals);
    }

    const updateFilterMeals = async () => {
        let filteredMeals = allMeals
            .filter(meal => meal.title.includes(search))
            .map(meal => ({
                ...meal,
                numProtein: meal.products.reduce((acc, product) => acc += product.numProtein, 0),
                numCarbohydrate: meal.products.reduce((acc, product) => acc += product.numCarbohydrate, 0),
                numFat: meal.products.reduce((acc, product) => acc += product.numFat, 0)
            }))
        if (mealChoosenTypes.length) {
            filteredMeals = filteredMeals.filter(meal => (
                mealChoosenTypes.some(type => type === meal.type)
            ))
        }

        setFilterMeals(filteredMeals);
    }


    const onLoadMeal = (id) => {
        const loaddedMeal = allMeals.find(meal => meal.id == id);
        if (loaddedMeal) loadMeal({ ...loaddedMeal })
        setIsLoadMealOpen(false);
    }

    return (
        <div onClick={() => setIsLoadMealOpen(false)} className={isLoadMealOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>טען ארוחה</span>
                    <div className='close-popup' onClick={() => setIsLoadMealOpen(false)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body flex column gap-1'>
                    <div className="flex gap-1">
                        <input
                            className='sbs-input'
                            type="text"
                            value={search}
                            onChange={({ target }) => { setSearch(target.value) }}
                            placeholder="חפש ארוחה"
                        />

                        {
                            Object.keys(mealTypes).map(type => (

                                <button
                                    key={type}
                                    className={mealChoosenTypes.some(choosenType => choosenType === type) ? 'button-primary' : 'button-secondary'}
                                    onClick={() => { setMealChoosenTypes(mealChoosenTypes.some(choosenType => choosenType === type) ? mealChoosenTypes.filter(choosenType => choosenType !== type) : [...mealChoosenTypes, type]) }}
                                >
                                    {mealTypes[type]}
                                </button>
                            ))
                        }
                    </div>
                    <SbsTable
                        columnMap={{ "id": "מזהה", "title": "שם המוצר", numProtein: "חלבונים", numCarbohydrate: 'פחמימות', numFat: 'שומנים' }}
                        isHaveOptions={true}
                        metaData={filterMeals}
                        options={[
                            { icon: 'load', cb: onLoadMeal },
                        ]}
                    />
                </div>
                {/* <div className='popup-footer'>
                    <button className='button-secondary' onClick={() => setIsLoadMealOpen(false)} >לא</button>
                </div> */}
            </div>
        </div>
    )
}