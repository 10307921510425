import { repeat } from "rxjs";
import { errorService } from "./errorService";
import httpService from "./httpService";
import { utilService } from "./utilService";

export const templateService = {
  get,
  remove,
  edit,
  add,
  getById,
  reOrder,
  getOrDamriData,
};

const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
});

function getOrDamriData(nivTemplate) {
  const {
    id,
    title,
    trains,
    creatorName,
    weeklyWorkouts,
    weeklyAerobicInMinutes,
  } = nivTemplate;
  const template = {
    id: id + "",
    weeklyWorkouts,
    weeklyAerobicInMinutes,
    title,
    createBy: creatorName,
    trains: trains.map((train) => {
      const { id, title, trainCategories } = train;
      return {
        id: id + "",
        title,
        exerciseTypesMap: trainCategories
          .map((trainCategory) => {
            const { type, trainCategoryExercises } = trainCategory;
            return {
              id: type,
              title: type,
              execiseIds: trainCategoryExercises
                .sort(
                  (trainCategoryExercises, trainCategoryExercises2) =>
                    trainCategoryExercises.index - trainCategoryExercises2.index
                )
                .map(
                  (trainCategoryExercises) => "" + trainCategoryExercises.id
                ),
            };
          })
          .reduce((acc, trainCategory) => {
            acc[trainCategory.id] = trainCategory;
            return acc;
          }, {}),
        trainExercisesMap: trainCategories.reduce((acc, trainCategory) => {
          trainCategory.trainCategoryExercises.forEach(
            (trainCategoryExercise) => {
              const { restInSeconds } = trainCategoryExercise;
              acc[trainCategoryExercise.id] = {
                ...trainCategoryExercise,
                id: "" + trainCategoryExercise.id,
                exerciseID: "" + trainCategoryExercise.exerciseID,
                exerciseId: "" + trainCategoryExercise.exerciseID,
                execiseId: "" + trainCategoryExercise.exerciseID,
                rest: `${leadingZeroFormatter.format(
                  Math.floor(restInSeconds / 60)
                )}:${leadingZeroFormatter.format(restInSeconds % 60)}`,
              };
            }
          );
          return acc;
        }, {}),
        exerciseTypesOrder: trainCategories
          .sort(
            (trainCategory, trainCategory2) =>
              trainCategory.index - trainCategory2.index
          )
          .map((trainCategory) => trainCategory.type),
      };
    }),
  };
  console.log(template, "template");
  return template;
}

const ENDPOINT = "training/plan_templates";

async function get() {
  const res = await httpService.get(ENDPOINT);
  const templates = res.data.map((template) => getOrDamriData(template));
  return templates;
}

async function remove(id) {
  try {
    const res = await httpService.delete(ENDPOINT + `/${id}`);
    errorService.updateError({
      msg: "התבנית נמחקה",
      type: "warning",
      time: Date.now(),
    });
    return res;
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function edit(editedTemplate) {
  console.log(editedTemplate);
  try {
    const body = {
      ...editedTemplate,
      id: +editedTemplate.id,
      trains: editedTemplate.trains.map((train) => ({
        title: train.title,
        trainCategories: train.exerciseTypesOrder.map((type) => {
          return {
            type: train.exerciseTypesMap[type].title,
            TrainCategoryExercises: train.exerciseTypesMap[type].execiseIds.map(
              (execiseId) => {
                const rest = train.trainExercisesMap[execiseId].rest;
                const [minutes, seconds] = rest.split(":");
                const restTime = +minutes * 60 + +seconds;
                return {
                  exerciseId: +train.trainExercisesMap[execiseId].execiseId,
                  numRepeatMin:
                    train.trainExercisesMap[execiseId].numRepeatMin + "",
                  numRepeatMax:
                    train.trainExercisesMap[execiseId].numRepeatMax || 0 + "",
                  numSet: +train.trainExercisesMap[execiseId].numSet,
                  type: train.trainExercisesMap[execiseId].type,
                  description: train.trainExercisesMap[execiseId].description,
                  comment: "-",
                  restInSeconds: restTime,
                };
              }
            ),
          };
        }),
      })),
    };
    console.log(body);
    const res = await httpService.put(ENDPOINT, body);
    errorService.updateError({
      msg: "התבנית עודכנה",
      type: "success",
      time: Date.now(),
    });
    return res.data;
  } catch (err) {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
    throw err;
  }
}

async function add(newTemplate) {
  try {
    const body = {
      ...newTemplate,
      trains: newTemplate.trains.map((train) => ({
        title: train.title,
        trainCategories: train.exerciseTypesOrder.map((type) => {
          return {
            type: train.exerciseTypesMap[type].title,
            TrainCategoryExercises: train.exerciseTypesMap[type].execiseIds.map(
              (execiseId) => {
                const rest = train.trainExercisesMap[execiseId].rest;
                const [minutes, seconds] = rest.split(":");
                const restTime = +minutes * 60 + +seconds;

                return {
                  exerciseId: train.trainExercisesMap[execiseId].execiseId
                    ? +train.trainExercisesMap[execiseId].execiseId
                    : +(+execiseId),
                  numRepeatMin:
                    train.trainExercisesMap[execiseId].numRepeatMin + "",
                  numRepeatMax:
                    train.trainExercisesMap[execiseId].numRepeatMax || 0 + "",
                  numSet: +train.trainExercisesMap[execiseId].numSet,
                  type: train.trainExercisesMap[execiseId].type,
                  description: train.trainExercisesMap[execiseId].description,
                  comment: "-",
                  restInSeconds: restTime,
                };
              }
            ),
          };
        }),
      })),
    };
    const res = await httpService.post(ENDPOINT, body);
    return res.data;
  } catch (err) {
    errorService.updateError({
      msg: "התבנית נוספה",
      type: "success",
      time: Date.now(),
    });
    throw err;
  }
}

async function getById(templateID) {
  const res = await httpService.get(ENDPOINT + `/${templateID}`);
  const template = getOrDamriData(res.data);
  return template;
}

async function reOrder(order) {
  const res = await httpService.put(ENDPOINT + `/reorder`, {
    PlanIDs: order,
  });

  return res.data;
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
