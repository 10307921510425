import { useEffect, useState } from 'react';
import SbsIcons from '../../components/SbsIcons';
import SbsTable from '../../components/SbsTable';
import { exerciseService } from '../../services/exerciseService';
import AskDeletePopup from './components/AskDeletePopup';
import AskEditPopup from './components/AskEditPopup';
import ImagePopup from './components/ImagePopup';
import YoutubePopup from './components/YoutubePopup';

var searchTimeOut = null;
export default function ManageExercises(props) {

    const [allExercises, setAllExercises] = useState([]);
    const [filterExercisesdMap, setFilterdExercisesMap] = useState([]);
    const [askDeleteExercises, setAskDeleteExercises] = useState(null);
    const [askEditExercises, setAskEditExercises] = useState(null);
    const [askEditImage, setAskEditImage] = useState(null);
    const [askShowYoutube, setAskOpenYoutube] = useState(null);
    const [search, setSearch] = useState("");
    const [isSearchLoad, setIsSearchLoad] = useState(false);

    useEffect(() => {
        document.title = "Step By Step - Exercises";
    }, [])

    useEffect(() => {
        updateExercises()
    }, [])



    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterExercisesMap();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allExercises])

    const updateExercises = async () => {
        const exercises = await exerciseService.get();
        setAllExercises(exercises);
    }

    const updateFilterExercisesMap = () => {
        const exercisesMap = allExercises.filter(exercise => exercise.title.includes(search))
            .reduce((acc, exercise) => {
                if (acc[exercise.type]) acc[exercise.type].push(exercise);
                else acc[exercise.type] = [exercise];
                return acc
            }, {})
        setFilterdExercisesMap(exercisesMap)
    }

    const onOptionAskDeleteExercises = (id) => {
        setAskDeleteExercises(allExercises.find(exercise => exercise.id === id));
    }
    const onOptionAskEditExercises = (id) => {
        setAskEditExercises(allExercises.find(exercise => exercise.id === id));
    }

    const onOptionOpenYoutube = (id) => {
        setAskOpenYoutube(allExercises.find(exercise => exercise.id === id));
    }
    const onOptionOpenImage = (id) => {
        setAskEditImage(allExercises.find(exercise => exercise.id === id));
    }

    const deleteAskedDeletedExercises = async () => {
        await exerciseService.remove(askDeleteExercises.id);
        updateExercises();
        setAskDeleteExercises(null)
    }

    const editAskedEditExercises = async (editedExercises) => {
        if (!askEditExercises.id) addAskedEditExercises(editedExercises)
        else {
            await exerciseService.edit(editedExercises);
            updateExercises();
            setAskEditExercises(null)
        }
    }

    const addAskedEditExercises = async (addExercises) => {
        await exerciseService.add(addExercises);
        updateExercises();
        setAskEditExercises(null)
    }

    return (
        <div className='main-container page'>
            <div className='manage-exercises-page'>
                <div className='header'>
                    <span className='sbs-title'>ניהול תרגילים</span>
                    <span className='sbs-subtitle'>סה"כ {allExercises.length} תרגילים</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש תרגיל"
                />

                <div className='exercises-container flex column gap-1' >
                    {
                        Object.keys(filterExercisesdMap).map(key => (
                            <div key={key}>
                                <span className='sbs-title'> {exerciseService.getTypeHebrewMap()[key]}</span>
                                <SbsTable
                                    columnMap={{ "title": "שם התרגיל", "description": "תיאור" }}
                                    isHaveOptions={true}
                                    metaData={filterExercisesdMap[key]}
                                    options={[
                                        { icon: 'delete', cb: onOptionAskDeleteExercises },
                                        { icon: 'edit', cb: onOptionAskEditExercises },
                                        { icon: 'youtube', cb: onOptionOpenYoutube },
                                        { icon: 'image', cb: onOptionOpenImage }
                                    ]}
                                />
                            </div>
                        ))
                    }
                </div>

                <AskDeletePopup
                    askDeleteExercises={askDeleteExercises}
                    setAskDeleteExercises={setAskDeleteExercises}
                    deleteAskedDeletedExercises={deleteAskedDeletedExercises}
                />

                <AskEditPopup
                    askEditExercises={askEditExercises}
                    editAskedEditExercises={editAskedEditExercises}
                    setAskEditExercises={setAskEditExercises}
                />

                <YoutubePopup
                    askShowYoutube={askShowYoutube}
                    setAskOpenYoutube={setAskOpenYoutube}
                />

                <ImagePopup
                    askEditImage={askEditImage}
                    setAskEditImage={setAskEditImage}
                    updateExercises={updateExercises}
                />

                <button onClick={() => { setAskEditExercises(exerciseService.getEmpty()) }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>

            </div>
        </div>
    )
}