import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";

export default function PermissionCustomerGuard(props) {

    const navigate = useNavigate();
    const [isCustomerPermission, setIsCustomerPermissions] = useState(authService.getPermissions().some(prem=>prem==='ManageCustomers'));

    useEffect(() => {
        if(!isCustomerPermission){
            navigate('/')
        } 
    }, [isCustomerPermission])
    




    return (
        isCustomerPermission &&
        <>
            {props.children}
        </>
    )
}