import { useRef } from "react";

export function TimePicker({
    time,
    onChange,
}) {
    const hoursRef = useRef();
    const minutesRef = useRef();
    const [hours, minutes] = time.split(':');
    return (
        <div dir="ltr" className="time-picker flex">
            <input
                value={hours}
                type="number"
                onChange={({ target }) => {
                    const newHours = target.value;
                    if (!newHours) onChange('00:' + minutes)
                    else if (newHours.length === 1) onChange('0' + newHours + ':' + minutes)
                    else {
                        onChange(newHours.slice(-2) + ':' + minutes)
                        minutesRef.current.focus();
                        minutesRef.current.select();
                    }
                }}
                ref={hoursRef}
            />:
            <input
                value={minutes}
                type="number"
                onChange={({ target }) => {
                    const newMinutes = target.value;
                    if (!newMinutes) onChange('00:00')
                    else if (newMinutes.length === 1) onChange(hours + ':0' + newMinutes)
                    else {
                        onChange(hours + ':' + (+newMinutes.slice(-2) % 60))
                    }
                }}
                ref={minutesRef}
            />
        </div>
    )
}