import React, { useEffect, useState } from "react";
import { exerciseService } from "../../../services/exerciseService";
import { utilService } from "../../../services/utilService";

export default function PlanSummary({
  onSave,
  previousStep,
  plan,
  customer,
  isCanContinue,
}) {
  const [execiseMap, setExeciseMap] = useState(null);
  const [isSaveLoad, setIsSaveLoad] = useState(false);
  const columnMap = {
    execiseId: "שם התרגיל",
    type: "סוג",
    numSet: "סטים",
    numRepeatMin: "חזרות",
    description: "תיאור",
    comment: "הערה",
  };

  useEffect(() => {
    updateExecisesMap();
  }, []);

  const updateExecisesMap = async () => {
    const execises = await exerciseService.get();
    const map = execises.reduce((acc, execise) => {
      acc[execise.id] = execise;
      return acc;
    }, {});
    setExeciseMap(map);
  };

  const handleSave = async () => {
    setIsSaveLoad(true);
    await onSave();
    setIsSaveLoad(false);
  };

  return (
    <div>
      <span className="sbs-title summary">סיכום</span>
      {customer.id && (
        <div className="flex column gap-0-5">
          <span>
            תוכנית אימון חדש ללקוח {customer.firstName} {customer.lastName}
          </span>
          <span>
            תאריך תחילת אימון {utilService.timestampToDDMMYYYY(plan.startDate)}
          </span>
          <span>
            תאריך סיום אימון {utilService.timestampToDDMMYYYY(plan.endDate)}
          </span>
          <span>זמן אירובי שבועי {plan.weeklyAerobicInMinutes} דקות</span>
          <span>{plan.weeklyAerobicNum} אימוני אירובי בשבוע</span>
        </div>
      )}
      {!customer.id && (
        <div className="flex column gap-0-5">
          <span>תבנית חדשה {plan.title}</span>
          {plan.createBy && <span>נוצר על ידי {plan.createBy}</span>}
        </div>
      )}

      <div className="divider"></div>

      <div className="flex column gap-1">
        {plan.trains.map((train, idx) => (
          <div className="flex column gap-1" key={idx}>
            <span className="sbs-title">אימון {train.title}</span>
            <table className="sbs-table">
              <tbody>
                <tr>
                  {Object.values(columnMap).map((columnName, idx) => (
                    <th key={idx}>{columnName}</th>
                  ))}
                </tr>
                {train.exerciseTypesOrder.map((exerciseTypeId) => {
                  const exerciseType = train.exerciseTypesMap[exerciseTypeId];
                  return (
                    <React.Fragment key={exerciseTypeId}>
                      <tr>
                        <td align="start" className="table-colspan" colSpan="6">
                          {
                            exerciseService.getTypeHebrewMap()[
                              exerciseType.title
                            ]
                          }
                        </td>
                      </tr>
                      {exerciseType.execiseIds.map((execiseId) => {
                        const trainExercise =
                          train.trainExercisesMap[execiseId];
                        return (
                          <tr key={execiseId}>
                            {!!execiseMap && (
                              <td>
                                {
                                  execiseMap[
                                    trainExercise.execiseId ??
                                      trainExercise.exerciseId
                                  ]?.title
                                }
                              </td>
                            )}
                            <td>{trainExercise.type}</td>
                            <td>{trainExercise.numSet} סטים</td>
                            <td
                              style={{
                                width: "100px",
                              }}
                            >
                              {trainExercise.isMax
                                ? "מקסימום"
                                : trainExercise.numRepeatMax &&
                                  trainExercise.numRepeatMax !== "0"
                                ? `${trainExercise.numRepeatMin} - ${trainExercise.numRepeatMax}`
                                : trainExercise.numRepeatMin}{" "}
                              חזרות
                            </td>
                            <td>{trainExercise.description}</td>
                            <td>{trainExercise.comment || ""}</td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <div className="flex column summary-btns">
        <button
          disabled={isSaveLoad || !isCanContinue}
          onClick={handleSave}
          className="button-primary mt-1"
        >
          שמור
        </button>
      </div>

      <div className="flex gap-0-5 summary-btns">
        <button
          onClick={() => {
            previousStep();
          }}
          className="button-secondary"
        >
          חזור לעריכת האימונים
        </button>
      </div>
    </div>
  );
}
