import { useEffect, useState } from "react"
import SbsIcons from "../../../components/SbsIcons"
import { utilService } from "../../../services/utilService"
import { workClockService } from "../../../services/workClockService";

// var workClockInterval = null;
var workClockTimeOut = null;
export default function WorkClock({
    monthWork,
    dateShared,
    sumMonth,
    updateWorkClock,
    showButton
}) {

    const [currWorkFrom, setCurrWorkFrom] = useState(-1);
    const [addedTime, setAddedTime] = useState(0);
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        updateCurrWorkFrom()
    }, [])

    useEffect(() => {
        if (currWorkFrom > -1) {
            workClockTimeOut = setTimeout(() => {
                setAddedTime(Date.now() - currWorkFrom)
            }, 200);
        } else {
            setAddedTime(0)
        }
    }, [addedTime])





    useEffect(() => {
        if (currWorkFrom > -1) {
            setAddedTime(Date.now() - currWorkFrom);
        } else {
        }
    }, [currWorkFrom, sumMonth])

    const updateCurrWorkFrom = async () => {
        const curr = await workClockService.getOpenEmployeeClock();
        console.log("🚀 ~ file: WorkClock.js:47 ~ updateCurrWorkFrom ~ curr", curr)
        setCurrWorkFrom(curr)
    }

    const toggleCurrWorkClock = async () => {
        setIsLoad(true)
        await workClockService.addWorkClock(1);
        await updateCurrWorkFrom()
        await updateWorkClock()
        setIsLoad(false)
    }

    const timeToShow = () => {
		console.log('addedTime', addedTime)
        return utilService.msToHMMSS(sumMonth + addedTime)
    }


    if (monthWork.month === dateShared.month) return (
        <div>
            <div className="flex gap-1 align-center">
                {
                    showButton &&
                    <button onClick={() => toggleCurrWorkClock()} className={`button-primary ${isLoad ? 'load' : ""}`} >
                        <SbsIcons icon={currWorkFrom > -1 ? 'pause' : 'play'} />
                    </button>
                }
                <div>
                    {
                        timeToShow()
                    }
                </div>
            </div>
        </div>
    )
    else return (
        <div>סהכ {Math.floor(sumMonth / 1000 / 60 / 60)} שעות</div>
    )
}