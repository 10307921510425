import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { groupService } from "../../services/groupService";
import AskDeletePopup from "./components/AskDeletePopup";
import AskEditCustomers from "./components/AskEditCustomers";
import AskEditPopup from "./components/AskEditPopup";

var searchTimeOut = null;
export default function ManageGroups({ }) {
    const [allGroups, setAllGroups] = useState([]);
    const [filterGroups, setFilterGroups] = useState([]);
    const [search, setSearch] = useState('');
    const [askDeleteGroup, setAskDeleteGroup] = useState(null);
    const [askEditGroup, setAskEditGroup] = useState(null);
    const [askEditGroupCustomers, setAskEditGroupCustomers] = useState(null);
    let navigate = useNavigate();
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);

    useEffect(() => {
        document.title = "Step By Step - Groups";
    }, [])

    useEffect(() => {
        updateAllGroups();
    }, [])

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterGroups();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allGroups])

    const updateAllGroups = async () => {
        setIsTableLoad(true);
        const group = await groupService.get();
        console.log(group);
        setAllGroups(group);
    }

    const updateFilterGroups = async () => {
        setIsTableLoad(false);
        const filteredGroup = allGroups
            .filter(group => group.title.includes(search))
            .map(group => ({
                ...group,
                customers: group.customers.length
            }))
        setFilterGroups(filteredGroup);
    }

    const onOptionAskDeleteGroup = (id) => {
        setAskDeleteGroup(allGroups.find(group => group.id === id));
    }

    const onOptionAskEditGroup = (id) => {
        setAskEditGroup(allGroups.find(group => group.id === id));
    }

    const onOptionAskEditGroupCustomers = (id) => {
        setAskEditGroupCustomers(allGroups.find(group => group.id === id));
    }

    const deleteAskedDeletedGroup = async () => {
        await groupService.remove(askDeleteGroup.id);
        updateAllGroups();
        setAskDeleteGroup(null);
    }

    const editAskedEditdGroup = async (group) => {
        if (group.id) await groupService.edit(group);
        else await groupService.add(group)
        updateAllGroups();
        setAskEditGroup(null);
    }


    return (
        <div className="main-container page">
            <div className="manage-groups-page">
                <div className='header'>
                    <span className='sbs-title'>ניהול קבוצות</span>
                    <span className='sbs-subtitle'>סה"כ {allGroups.length} קבוצות</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש מוצר"
                />

                <SbsTable
                    columnMap={{ "title": "שם הקבוצה", customers: 'כמות לקוחות' }}
                    isHaveOptions={true}
                    isLoad={isTableLoad}
                    metaData={filterGroups}
                    options={[
                        { icon: 'delete', cb: onOptionAskDeleteGroup },
                        { icon: 'edit', cb: onOptionAskEditGroup },
                        { icon: 'users', cb: onOptionAskEditGroupCustomers },
                    ]}
                ></SbsTable>


                <AskDeletePopup
                    askDeleteGroup={askDeleteGroup}
                    deleteAskedDeletedGroup={deleteAskedDeletedGroup}
                    setAskDeleteGroup={setAskDeleteGroup}
                />

                <AskEditPopup
                    askEditGroup={askEditGroup}
                    editAskedEditdGroup={editAskedEditdGroup}
                    setAskEditGroup={setAskEditGroup}

                />

                <AskEditCustomers
                    askEditGroupCustomers={askEditGroupCustomers}
                    editAskedEditdGroup={editAskedEditdGroup}
                    setAskEditGroupCustomers={setAskEditGroupCustomers}
                />

                <button onClick={() => { setAskEditGroup(groupService.getEmpty()) }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>

            </div>
        </div >
    )
}