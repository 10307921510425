import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { motivationalSentenceService } from "../../../services/motivationalSentenceService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditQ_A, askEditQ_A, setAskEditQ_A }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isSaveLoad, setIsSaveLoad] = useState(false);
    const [newQuestion, setNewQuestion] = useState("");
    const [newAnswer, setNewAnswer] = useState("");

    const onSave = () => {
        setIsSaveLoad(true)
        editAskedEditQ_A(newQuestion, newAnswer)
        setAskEditQ_A(null)
        setIsSaveLoad(false)
    }


    useEffect(() => {
        if (askEditQ_A) {
            setNewQuestion(askEditQ_A.question);
            setNewAnswer(askEditQ_A.answer);
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditQ_A])

    return (
        <div onClick={() => setAskEditQ_A(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    {
                        askEditQ_A?.id ? (
                            <span>ערוך את {askEditQ_A?.question}</span>
                        ) : (
                            <span>צור שאלה ותשובה</span>
                        )

                    }
                    <div className='close-popup' onClick={() => setAskEditQ_A(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <div className="q_a-inputs" >
                        <input
                            className='sbs-input'
                            type="text"
                            value={newQuestion}
                            onChange={({ target }) => { setNewQuestion(target.value) }}
                            placeholder="שאלה"
                        />
                        <textarea
                            className='sbs-input'
                            type="text"
                            value={newAnswer}
                            onChange={({ target }) => { setNewAnswer(target.value) }}
                            placeholder="תשובה"
                        />
                    </div>
                </div>
                <div className='popup-footer'>
                    <button disabled={!newQuestion || !newAnswer || isSaveLoad} className='button-primary' onClick={() => onSave()} >שמירה</button>
                    {/* <button className='button-secondary' onClick={() => setAskEditQ_A(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}