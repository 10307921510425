import { errorService } from "./errorService";
import httpService from "./httpService";
import { utilService } from "./utilService";

export const customerService = {
  get,
  getById,
  getEmpty,
  removePayment,
  // addPayment,
  remove,
  // getRandom,
  edit,
  add,
  getCount,
  addFiles,
  deleteFile,
  changePlansEndDate,
};

const ENDPOINT = "customers";

let customers = [
  {
    profileImage: null,
    id: "1",
    firstName: "אור",
    lastName: "דמרי",
    email: "ordamari971@gmail.com",
    phone: "0503990939",
    isActive: true,
    isPremium: false,
    idNumber: "",
    adrress: "",
    joinAt: Date.now(),
    groups: [],
    files: [],
    payments: ["p101", "p102"],
  },
  {
    profileImage: null,
    id: "c102",
    firstName: "ניב",
    lastName: "ניבאי",
    email: "nivNivai@gmail.com",
    phone: "0503990202",
    isActive: true,
    isPremium: false,
    idNumber: "",
    adrress: "",
    joinAt: Date.now(),
    groups: [],
    files: [],
    payments: ["p101", "p102"],
  },
];

function getEmpty() {
  return {
    profileImage: null,
    id: "",
    firstName: "",
    lastName: "",
    comments: "",
    email: "",
    phone: "",
    isActive: true,
    isPremium: false,
    idNumber: "",
    address: "",
    joinAt: Date.now(),
    groups: [],
    files: [],
    payments: [],
    lastSeen: null,
    isSuspended: false,
    gender: "male",
    notes: "",
  };
}

async function get() {
  const res = await httpService.get(ENDPOINT);
  const customers = res.data.map((customer) => {
    return {
      profileImage: null,
      weight: 0,
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phone,
      isActive: true,
      isPremium: customer.type,
      idNumber: customer.id,
      addrress: customer.address,
      joinAt: customer.created,
      groups: customer.groups,
      files: customer.files,
      payments: customer.payments,
      lastDateForPlans: customer.lastDateForPlans,
      lastSeen: null,
      isSuspended: false,
      comments: customer.comments,
    };
  });
  return customers;
}

async function removePayment(customerId, paymentId) {
  customers = customers.map((customer) =>
    customer.id === customerId
      ? {
          ...customer,
          payments: customer.payments.filter(
            (paymantItemId) => paymantItemId != paymentId
          ),
        }
      : customer
  );
}

// async function addPayment(customerId, paymentId) {
//     let editedCostumer = customers.find(customer => customer.id === customerId);
//     editedCostumer = { ...editedCostumer, payments: [...editedCostumer.payments, paymentId] }
//     edit(editedCostumer);

// }

async function remove(id) {
  const res = await httpService.delete(ENDPOINT + `/${id}`);
  return res;
}

async function edit(customer) {
  try {
    const res = await httpService.put(ENDPOINT, customer);
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function add(customer) {
  try {
    const res = await httpService.post(ENDPOINT, customer);
    console.log(res);
    errorService.updateError({
      msg: "לקוח נוסף",
      type: "success",
      time: Date.now(),
    });
    return res.data;
  } catch (err) {
    if (err.request.status === 409) {
      errorService.updateError({
        msg: "קיים משתמש עם המספר הזה",
        type: "danger",
        time: Date.now(),
      });
    } else {
      errorService.updateError({
        msg: "משהו קרה",
        type: "danger",
        time: Date.now(),
      });
    }
  }

  // const newCustomer = { ...customer, id: utilService.genId() };
  // customers = [...customers, newCustomer]
  // return newCustomer
}

async function getById(id) {
  const res = await httpService.get(ENDPOINT + `/${id}`);
  console.log(res);
  const {
    firstName,
    lastDateForPlans,
    lastName,
    comments,
    weight,
    email,
    phone,
    isActive,
    type,
    idNumber,
    address,
    created,
    groups,
    files,
    payments,
    lastSeen,
    isSuspended,
  } = res.data;
  const customer = {
    profileImage: null,
    id: res.data.id,
    lastDateForPlans: lastDateForPlans,
    firstName,
    comments,
    lastName,
    email,
    phone,
    isActive,
    weight,
    isPremium: type === 1,
    idNumber,
    address,
    joinAt: created,
    groups,
    files,
    payments,
    lastSeen,
    isSuspended,
  };
  return customer;
}

async function getCount() {
  const res = await httpService.get(ENDPOINT + `/count`);
  const { count, countThisWeek } = res.data;
  return { all: count, lastWeek: countThisWeek };
}

async function addFiles(files, userId) {
  console.log(userId);
  var bodyFormData = new FormData();
  const filesArr = Array.from(files);
  bodyFormData.append("customerID", userId);
  filesArr.forEach((file) => {
    bodyFormData.append(file.name, file, file.name);
  });
  const res = await httpService.post(ENDPOINT + "/files", bodyFormData);
}

async function deleteFile(userId, fileName) {
  const res = await httpService.delete(
    ENDPOINT + "/files/" + userId + "/" + fileName
  );
}

async function changePlansEndDate(userId, timestamp) {
  await httpService.put(ENDPOINT + "/changeEndingPlan", {
    CustomerId: userId,
    NewDate: timestamp,
  });
}
