import { useEffect, useState } from "react";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { templateService } from "../../services/templateService";
import AskDeletePopup from "./components/AskDeletePopup";
import { useNavigate } from "react-router-dom";

var searchTimeOut = null;
export default function ManageTemplate({}) {
  const [allTemplates, setAllTemplates] = useState([]);
  const [filterTemplates, setFilterTemplates] = useState([]);
  const [search, setSearch] = useState("");
  const [askDeleteTemplate, setAskDeleteTemplate] = useState(null);
  let navigate = useNavigate();
  const [isTableLoad, setIsTableLoad] = useState(false);
  const [isSearchLoad, setIsSearchLoad] = useState(false);

  useEffect(() => {
    document.title = "Step By Step - Templates";
  }, []);

  useEffect(() => {
    updateAllTemplates();
  }, []);

  useEffect(() => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
      setIsSearchLoad(true);
    }
    searchTimeOut = setTimeout(() => {
      updateFilterTemplates();
      setIsSearchLoad(false);
    }, 500);
  }, [search, allTemplates]);

  const updateAllTemplates = async () => {
    setIsTableLoad(true);
    const template = await templateService.get();
    setAllTemplates(template);
  };

  const updateFilterTemplates = async () => {
    setIsTableLoad(false);
    const filterMotivationalSentences = allTemplates.filter((template) =>
      template.title.includes(search)
    );
    setFilterTemplates(filterMotivationalSentences);
  };

  const onOptionAskDeleteTemplate = (id) => {
    setAskDeleteTemplate(allTemplates.find((template) => template.id === id));
  };

  const onOptionAskEditTemplate = (id) => {
    navigate(`/view-template/${id}`);
  };

  const deleteAskedDeletedTemplate = async () => {
    await templateService.remove(askDeleteTemplate.id);
    updateAllTemplates();
    setAskDeleteTemplate(null);
  };

  const handleReOrder = async (newReOrder) => {
    setAllTemplates(newReOrder);
    setFilterTemplates(newReOrder);

    const newOrder = newReOrder.map((template, index) => {
      return parseInt(template.id);
    });

    await templateService.reOrder(newOrder);
  };

  return (
    <div className="main-container page">
      <div className="manage-templates-page">
        <div className="header">
          <span className="sbs-title">ניהול תבניות</span>
          <span className="sbs-subtitle">
            סה"כ {allTemplates.length} תבניות
          </span>
        </div>

        <input
          className={`sbs-input search ${isSearchLoad ? "load" : "no-load"}`}
          type="text"
          value={search}
          onChange={({ target }) => {
            setSearch(target.value);
          }}
          placeholder="חפש תבנית"
        />

        <SbsTable
          columnMap={{ title: "שם התבנית", createBy: "יוצר התבנית" }}
          isHaveOptions={true}
          isLoad={isTableLoad}
          metaData={filterTemplates}
          options={[
            { icon: "delete", cb: onOptionAskDeleteTemplate },
            { icon: "edit", cb: onOptionAskEditTemplate },
          ]}
          reOrder={handleReOrder}
        ></SbsTable>

        <AskDeletePopup
          askDeleteTemplate={askDeleteTemplate}
          deleteAskedDeletedTemplate={deleteAskedDeletedTemplate}
          setAskDeleteTemplate={setAskDeleteTemplate}
        />

        <button
          onClick={() => {
            navigate("/view-template");
          }}
          className="sbs-flow-btn"
        >
          <SbsIcons icon="add" />
        </button>
      </div>
    </div>
  );
}
