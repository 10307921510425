import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { groupService } from "../../../services/groupService";
import { customerService } from "../../../services/customerService";
import { utilService } from "../../../services/utilService";

export default function AskEditCustomers({ editAskedEditdGroup, askEditGroupCustomers, setAskEditGroupCustomers }) {

    const [isOpen, setIsOpen] = useState(false);
    const [group, setGroup] = useState(groupService.getEmpty());


    useEffect(() => {
        if (askEditGroupCustomers) {
            console.log(askEditGroupCustomers);
            setGroup({ ...askEditGroupCustomers })
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditGroupCustomers])



    return (
        <div onClick={() => setAskEditGroupCustomers(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>לקוחות בקבוצה</span>
                    <div className='close-popup' onClick={() => setAskEditGroupCustomers(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body flex column'>
                    {
                    group.customers.length ?
                        group.customers.map(customer => (
                            <div key={customer.id} >
                                {customer?.firstName} {customer?.lastName}
                            </div>
                        )):(
                            <span>אין לקוחות בקבוצה</span>
                        )
                    }
                </div>
                <div className='popup-footer'>
                    {/* <button className='button-primary' onClick={() => editAskedEditdGroup(group)} >כן</button>
                    <button className='button-secondary' onClick={() => setAskEditGroupCustomers(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}