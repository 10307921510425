import { useState } from 'react';
import { Link } from 'react-router-dom';


export default function HomeDataContainer({title,num,numLastWeek,routeTo}){
    return (
        <div className="home-data-container">
            <h3>{title}</h3>
            <span>{num}</span> 
            <span>{numLastWeek} נוספו בשבוע האחרון</span>
            <Link className="button-primary" to={routeTo}>ניהול {title}</Link>
        </div>
    )
}