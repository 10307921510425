import { utilService } from "./utilService";
import httpService from './httpService'

export const workClockService = {
    // getEmpty,
    getByEmployeeId,
    getOpenEmployeeClock,
    addWorkClock,
    updateWork,
    get,
    deleteWorkClock,
    addWork
}

let workClocks = [
    {
        id: 'w101',
        employeeId: 'e101',
        from: Date.now() - 1000 * 60 * 60 * 3,
        to: Date.now()
    },
    {
        employeeId: 'e101',
        id: 'w102',
        from: 1651784400000,
        to: 1651894400000
    }
]

const ENDPOINT = "users/clocks";

async function getByEmployeeId(employeeId) {

    const res = await httpService.get(ENDPOINT + `/${employeeId}`);
    const workclocks = res.data.filter(clock => clock.end).map(clock => {

        return {
            id: clock.id,
            employeeId: clock.userID,
            from: clock.start,
            to: clock.end
        }
    })
    return workclocks
}

async function addWorkClock(employeeId) {
    const res = await httpService.post(ENDPOINT);
    return res;
}

async function getOpenEmployeeClock() {
    const res = await httpService.get(`${ENDPOINT}/myself`);
    const openWorkClock = res.data.find(clock => !clock.end)
    console.log(openWorkClock);
    return openWorkClock ? openWorkClock.start : -1
}

async function updateWork(updatedWork) {
    const { from, to, id, employeeId } = updatedWork
    // const updatedWorkObj = { from, to, id: 1213213, employeeId }
    console.log({ id, start: from, end: to });
    const res = await httpService.put(ENDPOINT, { id, start: from, end: to });
}

async function addWork(addedWork, employeeId) {
    const { from, to } = addedWork
    console.log(employeeId);
    // const updatedWorkObj = { from, to, id: 1213213, employeeId }
    const res = await httpService.post(ENDPOINT + '/create', { start: from, end: to, userId: employeeId });

}

async function get() {
    const res = await httpService.get(ENDPOINT);
    console.log(res.data)
    return res.data.map(clock => (
        {
            id: clock.id,
            employeeId: clock.userID,
            from: clock.start,
            to: clock.end
        }
    ))
}

async function deleteWorkClock(id) {
    const res = await httpService.delete(ENDPOINT + '/' + id);
}