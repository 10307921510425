import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const employeeService = {
    get,
    getById,
    getEmpty,
    removePayment,
    addPayment,
    remove,
    edit,
    add,
    addFilesToEmployee,
    deleteFile,
    changePassword
}

let employees = [
    {
        profileImage: null,
        id: 'e101',
        firstName: 'אור',
        lastName: 'דמרי',
        role: 'employee',
        isActive: true,
        phone: '0503990939',
        email: 'ordamari971@gmail.com',
        permission: '',
        files: [],

    },
    {
        profileImage: null,
        id: 'e102',
        firstName: 'ניב',
        lastName: 'ניבאי',
        role: 'employee',
        isActive: true,
        phone: '0503990938',
        email: 'nivNivai@gmail.com',
        permission: ''
    }
]

function getEmpty() {
    return {
        profileImage: null,
        id: '',
        firstName: '',
        lastName: '',
        status: 'Active',
        phone: '',
        email: '',
        permission: '',
        created: Date.now(),
        files: [],
        isAdmin: false,
        role: 'employee',
        lastLogIn: Date.now(),
        hourSalary: 0,
        monthSalary: 0,
        premiumSalary: 0,
        manageWorkClocksPremission: false,
        manageEmployeesPremission: false,
        manageCustomersPremission: false,
        nourishmentPremission: false,
        trainsPremission: false,
        motivisionSentenncePremission: false,
        groupsPremission: false,
        tasksPremission: false,
    };
}

const ENDPOINT = 'users'

async function get() {
    const res = await httpService.get(ENDPOINT);
    const employees = res.data.map(employee => {
        const { id, firstName, lastName, phone, email, role, created } = employee;
        return { id, firstName, lastName, phone, email, role, permission: '', isActive: true, profileImage: null, created }
    });
    return employees;
}

async function removePayment(employeeId, paymentId) {
    employees = employees.map(employee => employee.id === employeeId ? {
        ...employee,
        payments: employee.payments.filter(paymantItemId => paymantItemId != paymentId)
    }
        :
        employee
    )
}

async function addPayment(employeeId, paymentId) {
    let editedCostumer = employees.find(employee => employee.id === employeeId);
    editedCostumer = { ...editedCostumer, payments: [...editedCostumer.payments, paymentId] }
    edit(editedCostumer);

}

const permissionsOrToNivData = {
    //"OrData":"NivData"
    "manageEmployeesPremission": "ManageUsers",
    "manageWorkClocksPremission": "ManageWorkClocks",
    "manageCustomersPremission": "ManageCustomers",
    "motivisionSentenncePremission": "ManageQuotes",
    "nourishmentPremission": "ManageNutrition",
    "tasksPremission": "ManageTrains",
    "groupsPremission": "ManageGroups",
    "trainsPremission": "ManageTasks",

}

async function remove(id) {
    try {
        const res = await httpService.delete(ENDPOINT + `/${id}`);
        errorService.updateError({ msg: 'העובד נמחק', type: 'warning', time: Date.now() })
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(employee) {
    const permissions = Object.entries(employee).map(pair => {
        if (pair[0] in permissionsOrToNivData) {
            return permissionsOrToNivData[pair[0]];
        }
        return null;
    }).filter(e => e !== null);
    const body = {
        id: employee.id,
        status: employee.status,
        idNumber: employee.idNumber ? employee.idNumber : "",
        phone: employee.phone,
        address: employee.address,
        type: employee.isAdmin ? "Admin" : "User",
        email: employee.email,
        firstName: employee.firstName,
        lastName: employee.lastName,
        permissions,
        role: employee.role,
        hourSalary: +employee.hourSalary,
        monthSalary: +employee.monthSalary,
        premiumSalary: +employee.premiumSalary
    };

    try {
        const res = await httpService.put(ENDPOINT, body);
        // errorService.updateError({ msg: 'משהו קרה', type: 'success', time: Date.now() })
        return res.data;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }


}

async function add(employee, tempPassword) {
    try {

        const body = { ...employee, password: tempPassword }
        const res = await httpService.post(ENDPOINT, body);
        errorService.updateError({ msg: 'העובד נוסף', type: 'success', time: Date.now() })
        return res.data.info
    } catch (err) {
        if (err.request.status === 409) errorService.updateError({ msg: 'קיים עובד עם מייל זה', type: 'danger', time: Date.now() })
        else errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getById(employeeID) {
    const res = await httpService.get(ENDPOINT + `/${employeeID}`);
    const { id, firstName, lastName, phone, email, role, files, created, idNumber, address, lastLogin, type, hourSalary, monthSalary, premiumSalary, status } = res.data;
    const permissionSerializer = Object.fromEntries(
        Object.entries(permissionsOrToNivData).map(_ => [_[1], _[0]])
    );
    const returnData = { id, status, firstName, lastName, phone, email, role, permission: '', isActive: true, files, profileImage: null, created, idNumber, address, lastLogin, isAdmin: type, hourSalary, monthSalary, premiumSalary };
    res.data.permissions.forEach(permission => {
        returnData[permissionSerializer[permission]] = true;
    })
    return returnData;
}

async function addFilesToEmployee(files, userId) {
    var bodyFormData = new FormData();
    const filesArr = Array.from(files)
    bodyFormData.append('userID', userId)
    filesArr.forEach(file => {
        bodyFormData.append(file.name, file, file.name)
    })
    const res = await httpService.post(ENDPOINT + '/files', bodyFormData);
}

async function deleteFile(userId, fileName) {
    const res = await httpService.delete(ENDPOINT + '/files/' + userId + '/' + fileName)
}

async function changePassword(userId, newPassword) {
    try {

        const res = await httpService.post(ENDPOINT + '/change_password', { userId, newPassword })
        errorService.updateError({ msg: 'סיסמת העובד שונתה', type: 'success', time: Date.now() })
        return res.data.info
    } catch (err) {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}
