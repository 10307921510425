import httpService from './httpService'
import { utilService } from './utilService';
import { mealService } from './mealService';
import { errorService } from './errorService';

export const menuService = {
    remove,
    edit,
    add,
    getById,
    getByCustomerId,
    getEmpty,
    getCount
}

const ENDPOINT = 'nutrition/menus'

let menus = [
]

function getEmpty() {
    return {
        id: "",
        customerId: '',
        meals: [
            mealService.getEmpty()
        ],
        startDate: Date.now(),
        endDate: Date.now() + 1000 * 60 * 60 * 24 * 30,
        createAt: Date.now(),
        dailyWaterInLiter: 0,
        title: '',
        description: '',
        createBy: 'בר פחימה'
    }
}

async function remove(id) {
    try {
        const res = await httpService.delete(ENDPOINT + `/${id}`);
        errorService.updateError({ msg: 'התפריט נמחק', type: 'warning', time: Date.now() })
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(editedMenu) {
    try {
        const { id, meals, startDate, endDate, dailyWaterInLiter, title, description } = editedMenu;
		
		const body = {
            id,
            title,
            description,
            expirationDate: endDate,
            startDate,
            dailyWaterInLiter: +dailyWaterInLiter,
            description,
            meals: meals.map(meal => mealService.toNivData(meal))
        }
		console.log("body", body)

        const res = await httpService.put(ENDPOINT, body);
		console.log(res)
        errorService.updateError({ msg: 'התפריט עודכן', type: 'success', time: Date.now() })
        return res.data;
    } catch (err) {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
        throw err;
    }
}

async function add(newMenu) {
    try {

        console.log(newMenu)
        const { customerId, meals, startDate, endDate, dailyWaterInLiter, title, description } = newMenu;

		const body = {
            customerId: +customerId,
            title,
            description,
            expirationDate: endDate,
            startDate,
            dailyWaterInLiter: +dailyWaterInLiter,
            description,
            meals: meals.map(meal => mealService.toNivData(meal))
        }

		console.log(body)
        const res = await httpService.post(ENDPOINT, body);
        errorService.updateError({ msg: 'התפריט נוסף', type: 'success', time: Date.now() })
        return res.data;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getById(menuId) {
    const res = await httpService.get(ENDPOINT + `/${menuId}`);
    const { id, customerID, startDate, endDate, created, title, description, creatorName, dailyWaterInLiter, meals } = res.data
    console.log(JSON.stringify(res.data));
    return {
        id,
        customerId: customerID,
        meals: meals.map(meal => mealService.toOrData(meal)),
        startDate,
        endDate,
        createAt: created,
        dailyWaterInLiter,
        title,
        description,
        createBy: creatorName
    }
}

async function getByCustomerId(customerId) {
	console.log("customerId", customerId)
	if(!customerId) return []

    const res = await httpService.get(ENDPOINT + `/customer/${customerId}`);
    const menus = res.data.map(menu => {
        const { id, customerId, startDate, endDate, created, title, description, creatorName, dailyWaterInLiter, meals } = menu
        return {
            id,
            customerId,
            meals: meals.map(meal => mealService.toOrData(meal)),
            startDate,
            endDate,
            createAt: created,
            dailyWaterInLiter,
            title,
            description,
            createBy: creatorName
        }
    });
    return menus
}

async function getCount() {
    const res = await httpService.get(ENDPOINT + `/count`)
    const { count, countThisWeek } = res.data;
    return { all: count, lastWeek: countThisWeek };
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
