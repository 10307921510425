import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import ManageMotivationalSentences from './pages/manage-motivational-sentences/ManageMotivationalSentences';
import ManageQ_A from './pages/manage-q_a/ManageQ_A';
import ManageCustomers from './pages/manage-customers/ManageCustomers';
import ViewCustomer from './pages/view-customer/ViewCustomer';
import ManageExercises from './pages/manage-exercises/ManageExercises';
import ManageAerobics from './pages/manage-aerobics/ManageAerobics.js';
import ViewPlan from './pages/view-plan/ViewPlan';
import ManageTemplate from './pages/manage-templates/ManageTemplate';
import ManageProducts from './pages/manage-products/ManageProducts';
import ViewProduct from './pages/view-product/ViewProduct';
import ManageMeals from './pages/manage-meals/ManageMeals';
import ViewMeal from './pages/view-meal/ViewMeal';
import ViewMenu from './pages/view-menu/ViewMenu';
import ManageGroups from './pages/manage-groups/ManageGroups';
import ManageTasks from './pages/manage-tasks/ManageTasks';
import ManageEmployees from './pages/manage-employees/ManageEmployees';
import ViewTimeClock from './pages/view-time-clock/ViewTimeClock';
import ManageWorkClocks from './pages/manage-work-clocks/ManageWorkClocks';
import ViewEmployee from './pages/view-employee/ViewEmployee';
import { authService } from './services/authService';
import TokenGuard from './guard/TokenGuard';
import PermissionWorkClockGuard from './guard/permissions/PermissionWorkClockGuard';
import PermissionUserGuard from './guard/permissions/PermissionUserGuard';
import PermissionCustomerGuard from './guard/permissions/PermissionCustomerGuard copy';
import PermissionNutritionGuard from './guard/permissions/PermissionNutritionGuard';
import PermissionTrainGuard from './guard/permissions/PermissionTrainsGuard';
import { ErrorHandler } from './components/ErrorHandler';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import RestorePassword from './pages/restore-password/RestorePassword';
import { ManageMenuTemlates } from './pages/manage-menu-templates/ManageMenuTemlates';
import { ViewMenuTemplate } from './pages/view-menu-template/ViewMenuTemplate';
import ManageAbdominal from './pages/manage-abdominal/ManageAbdominal';


function App(props) {


  const [isMenuOpen, setIsMenuOpen] = useState(false);




  return (
    <div onClick={() => setIsMenuOpen(false)} dir='rtl' className="App">
      <ErrorHandler />

      <Router>
        < Navbar
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
        <Routes>
          <Route exact path="/" element={<TokenGuard> <Home /> </TokenGuard>} />
          <Route exact path="/manage-motivational-sentences" element={<TokenGuard> <ManageMotivationalSentences /> </TokenGuard>} />
          <Route exact path="/manage-questions-and-answers" element={<TokenGuard> <ManageQ_A /> </TokenGuard>} />
          <Route exact path="/manage-customers" element={<TokenGuard> <PermissionCustomerGuard><ManageCustomers /></PermissionCustomerGuard>  </TokenGuard>} />
          <Route exact path="/manage-employees" element={<TokenGuard><PermissionUserGuard><ManageEmployees /></PermissionUserGuard></TokenGuard>} />
          <Route exact path="/manage-exercises" element={<TokenGuard> <PermissionTrainGuard><ManageExercises /></PermissionTrainGuard>  </TokenGuard>} />
          <Route exact path="/manage-aerobics" element={<TokenGuard> <PermissionTrainGuard><ManageAerobics /></PermissionTrainGuard>  </TokenGuard>} />
          <Route exact path="/manage-abdominal" element={<TokenGuard> <PermissionTrainGuard><ManageAbdominal /></PermissionTrainGuard>  </TokenGuard>} />
          <Route exact path="/manage-templates" element={<TokenGuard> <PermissionTrainGuard><ManageTemplate /></PermissionTrainGuard>  </TokenGuard>} />
          <Route exact path="/manage-products" element={<TokenGuard><PermissionNutritionGuard><ManageProducts /></PermissionNutritionGuard>   </TokenGuard>} />
          <Route exact path="/manage-meals" element={<TokenGuard><PermissionNutritionGuard><ManageMeals /></PermissionNutritionGuard>  </TokenGuard>} />
          <Route exact path="/manage-menu-templates" element={<TokenGuard><PermissionNutritionGuard><ManageMenuTemlates /></PermissionNutritionGuard>  </TokenGuard>} />
          <Route exact path="/manage-groups" element={<TokenGuard> <ManageGroups /> </TokenGuard>} />
          <Route exact path="/manage-tasks" element={<TokenGuard> <ManageTasks /> </TokenGuard>} />
          <Route exact path="/manage-work-clocks" element={<TokenGuard> <PermissionWorkClockGuard><ManageWorkClocks /></PermissionWorkClockGuard>  </TokenGuard>} />
          <Route exact path="/view-customer/:id" element={<TokenGuard> <ViewCustomer /> </TokenGuard>} />
          <Route exact path="/view-customer" element={<TokenGuard> <ViewCustomer /> </TokenGuard>} />
          <Route exact path="/view-employee/:id" element={<TokenGuard> <ViewEmployee /> </TokenGuard>} />
          <Route exact path="/view-employee" element={<TokenGuard> <ViewEmployee /> </TokenGuard>} />
          <Route exact path="/view-template" element={<TokenGuard> <ViewPlan /> </TokenGuard>} />
          <Route exact path="/view-template/:templateId" element={<TokenGuard> <ViewPlan /> </TokenGuard>} />
          <Route exact path="/view-plan/plan/:planId" element={<TokenGuard> <ViewPlan /> </TokenGuard>} />
          <Route exact path="/view-plan/customer/:customerId" element={<TokenGuard> <ViewPlan /> </TokenGuard>} />
          <Route exact path="/view-product" element={<TokenGuard> <ViewProduct /> </TokenGuard>} />
          <Route exact path="/view-product/:productId" element={<TokenGuard> <ViewProduct /> </TokenGuard>} />
          <Route exact path="/view-meal" element={<TokenGuard> <ViewMeal /> </TokenGuard>} />
          <Route exact path="/view-time-clock" element={<TokenGuard> <ViewTimeClock /> </TokenGuard>} />
          <Route exact path="/view-time-clock/:id" element={<TokenGuard> <ViewTimeClock /> </TokenGuard>} />
          <Route exact path="/view-meal/:mealId" element={<TokenGuard> <ViewMeal /> </TokenGuard>} />
          <Route exact path="/view-menu/menu/:menuId" element={<TokenGuard> <ViewMenu /> </TokenGuard>} />
          <Route exact path="/view-menu-template/:templateId" element={<TokenGuard> <ViewMenuTemplate /> </TokenGuard>} />
          <Route exact path="/view-menu-template" element={<TokenGuard> <ViewMenuTemplate /> </TokenGuard>} />
          <Route exact path="/view-menu/customer/:customerId" element={<TokenGuard> <ViewMenu /> </TokenGuard>} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/restore-password" element={<RestorePassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
