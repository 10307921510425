import { useEffect, useState } from 'react';
import SbsTable from '../../components/SbsTable';
import { motivationalSentenceService } from '../../services/motivationalSentenceService';
import { taskService } from '../../services/taskService';
import { utilService } from '../../services/utilService';
import { planService } from '../../services/planService';
import HomeDataContainer from './components/HomeDataContainer';
import { menuService } from '../../services/menuService';
import { customerService } from "../../services/customerService"
import { employeeService } from '../../services/employeeService';
import { taskOptionsMap } from '../manage-tasks/ManageTasks';

export default function Home(props) {

    const [customer, setCustomer] = useState({ all: 0, lastWeek: 0 })
    const [plan, setPlan] = useState({ all: 0, lastWeek: 0 })
    const [menu, setMenu] = useState({ all: 0, lastWeek: 0 })
    const [employees, setEmployees] = useState([]);
    const [currMotivationSentense, setCurrMotivationSentense] = useState("");
    const [tasks, setTasks] = useState([]);
    const [isTableLoad, setIsTableLoad] = useState(false);


    useEffect(() => {
        document.title = "Step By Step - Home";
        updateEmployees();
        updateCurrMotivationSentense();
        updateCount();

        return () => {
        }
    }, [])

    useEffect(() => {
        updateTasks();
    }, [employees])

    const updateCount = async () => {
        const planCount = await planService.getCount();
        setPlan(planCount);
        const menuCount = await menuService.getCount();
        setMenu(menuCount);
        const customerCount = await customerService.getCount();
        setCustomer(customerCount);
    }

    const updateEmployees = async () => {
        const employees = await employeeService.get();
        setEmployees(employees);
    }

    const updateTasks = async () => {
        setIsTableLoad(true);
        const tasks = await (await taskService.get()).map(task => {
            const employee = employees.find(emoloyee => emoloyee.id === task.owner)
            return {
                ...task,
                deadline: utilService.timestampToDDMMYYYY(task.deadline),
                owner: employee ? employee.firstName + ' ' + employee.lastName : '',
                priority: <span className="primary-color" >{utilService.priorityNumberToStars(task.priority)}</span>,
                status: taskOptionsMap[task.status]
            }
        })
        setTasks(tasks)
        setIsTableLoad(false);
    }

    const updateCurrMotivationSentense = async () => {
        const motivationSentense = await motivationalSentenceService.getRandom()
        setCurrMotivationSentense(motivationSentense.sentence);
    }





    return (
        <div className='main-container page'>

            <div className='home'>
                <div className="data-container">
                    <HomeDataContainer title={"לקוחות"} num={customer.all} numLastWeek={customer.lastWeek} routeTo="/manage-customers" ></HomeDataContainer>
                    <HomeDataContainer title={"ארוחות"} num={menu.all} numLastWeek={menu.lastWeek} routeTo="/manage-meals" ></HomeDataContainer>
                    <HomeDataContainer title={"תבניות"} num={plan.all} numLastWeek={plan.lastWeek} routeTo="/manage-templates" ></HomeDataContainer>
                </div>

                <div className='motivation-sentense'>
                    {currMotivationSentense}
                </div>

                <div className='task-table'>
                    <SbsTable
                        columnMap={{ owner: "מבצע המשימה", status: "סטטוס", priority: "עדיפות", deadline: "תאריך סיום" }}
                        isHaveOptions={false}
                        isLoad={isTableLoad}
                        metaData={tasks}
                        options={null}
                    ></SbsTable>
                </div>
            </div>
        </div>
    )
}