import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { exerciseService } from "../../../services/exerciseService";
import { utilService } from "../../../services/utilService";

export default function ImagePopup({ askEditImage, setAskEditImage, updateExercises }) {

    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState(null)
    const [image, setImage] = useState(null);
    const [isLoad, setIsLoad] = useState(false);

    const onImageLoad = (ev) => {
        console.log(ev);
        if (!ev.target.files[0]) setFile(null);
        else {
            setFile(ev.target.files[0]);
            var fr = new FileReader();
            fr.onload = function (e) {
                setImage(fr.result);
            }
            fr.readAsDataURL(ev.target.files[0]);
        }
    }

    const saveImage = async () => {
        setIsLoad(true);
        await exerciseService.changeImage(askEditImage.id, file);
        await updateExercises();
        setIsLoad(false);
        setAskEditImage(null);
    }

    const getImageUrl = async () => {
        const imageUrl = await exerciseService.getImage(askEditImage.image);
        setImage(imageUrl);
    }


    useEffect(() => {
        if (askEditImage) {
            setIsOpen(true);
            getImageUrl();
        }
        else {
            setIsOpen(false);
            setImage(null);
            setFile(null);
        }
    }, [askEditImage])

    return (
        <div onClick={() => setAskEditImage(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>{askEditImage?.title}</span>
                    <div className='close-popup' onClick={() => setAskEditImage(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <div className="flex column align-center justify-center">

                        {
                            image ?
                                <div className="image">
                                    <img src={image} alt={askEditImage?.title} />
                                </div> : <div>אין תמונה</div>
                        }
                        <input
                            type='file'
                            accept="image/png, image/gif, image/jpeg"
                            onChange={onImageLoad}
                        />
                    </div>

                </div>
                <div className='popup-footer'>
                    <button className='button-primary' disabled={!file || isLoad} onClick={saveImage} >שמור</button>
                    <button className='button-secondary' onClick={() => setAskEditImage(null)} >סגור</button>
                </div>
            </div>
        </div >
    )
}