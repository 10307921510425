import { Draggable } from "react-beautiful-dnd";
import ExeciseTypePreview from "./ExeciseTypePreview";

export default function ExeciseTypeList({
    deleteTrainExecise,
    train,
    setAskAddExerciseData,
    updateTrainExeciseByKey,
    execises,
    placeholder,
    execisesMap

}) {
    return (
        // <div className='train-exerises flex column gap-1'>
        <div className='train-exerises flex column'>
            {
                train.exerciseTypesOrder.map((exerciseType, execiseIndex) => (
                    <div key={exerciseType}>
                        <Draggable
                            draggableId={exerciseType}
                            index={execiseIndex}
                        // key={exerciseType}
                        >
                            {
                                (provided) => (

                                    <ExeciseTypePreview
                                        deleteTrainExecise={deleteTrainExecise}
                                        exerciseTypeObject={train.exerciseTypesMap[exerciseType]}
                                        setAskAddExerciseData={setAskAddExerciseData}
                                        train={train}
                                        trainExercisesMap={train.trainExercisesMap}
                                        updateTrainExeciseByKey={updateTrainExeciseByKey}
                                        execises={execises}
                                        execiseIndex={execiseIndex}
                                        draggableProps={provided.draggableProps}
                                        dragHandleProps={provided.dragHandleProps}
                                        innerRef={provided.innerRef}
                                        execisesMap={execisesMap}
                                    />
                                )
                            }

                        </Draggable>
                    </div>
                ))
            }
            {placeholder}
        </div>
    )
}