import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { mealService } from "../../services/mealService";
import AskDeletePopup from "./components/AskDeletePopup";

var searchTimeOut = null;
export default function ManageMeals({ }) {
    const [allMeals, setAllMeals] = useState([]);
    const [filterMeals, setFilterMeals] = useState([]);
    const [search, setSearch] = useState('');
    const [askDeleteMeal, setAskDeleteMeal] = useState(null);
    let navigate = useNavigate();
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);

    useEffect(() => {
        document.title = "Step By Step - Meals";
    }, [])

    useEffect(() => {
        updateAllMeals();
    }, [])

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterMeals();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allMeals])

    const updateAllMeals = async () => {
        setIsTableLoad(true);
        const meal = await mealService.get();
        setAllMeals(meal);
    }

    const updateFilterMeals = async () => {
        setIsTableLoad(false);
        console.log(allMeals);
        const filteredMeals = allMeals
            .filter(meal => meal.title.includes(search))
            .map(meal => ({
                ...meal,
                numProtein: meal.products.filter((product) => product.type === 'Protein').length,
                numCarbohydrate: meal.products.filter((product) => product.type === 'Carbohydrate').length,
                numFat: meal.products.filter((product) => product.type === 'Fat').length,
            }))
        // .map(meal => ({
        //     ...meal,
        //     numProtein: meal.products.reduce((acc, product) => acc += product.numProtein, 0),
        //     numCarbohydrate: meal.products.reduce((acc, product) => acc += product.numCarbohydrate, 0),
        //     numFat: meal.products.reduce((acc, product) => acc += product.numFat, 0)
        // }))
        setFilterMeals(filteredMeals);
    }

    const onOptionAskDeleteMeal = (id) => {
        setAskDeleteMeal(allMeals.find(meal => meal.id === id));
    }

    const onOptionAskEditMeal = (id) => {
        navigate(`/view-meal/${id}`)

    }

    const deleteAskedDeletedMeal = async () => {
        await mealService.remove(askDeleteMeal.id);
        updateAllMeals();
        setAskDeleteMeal(null);
    }

	const handleReOrder = async (newReOrder) => {
		setAllMeals(newReOrder);
		setFilterMeals(newReOrder);
	
		const newOrder = newReOrder.map((template, index) => {
		  return parseInt(template.id);
		});
	
		await mealService.reOrder(newOrder);
	  };

    return (
        <div className="main-container page">
            <div className="manage-meals-page">
                <div className='header'>
                    <span className='sbs-title'>ניהול ארוחות</span>
                    <span className='sbs-subtitle'>סה"כ {allMeals.length} ארוחות</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש מוצר"
                />

                <SbsTable
                    columnMap={{ "title": "שם הארוחה", numProtein: "חלבונים", numCarbohydrate: 'פחמימות', numFat: 'שומנים' }}
                    isHaveOptions={true}
                    isLoad={isTableLoad}
                    metaData={filterMeals}
                    options={[
                        { icon: 'delete', cb: onOptionAskDeleteMeal },
                        { icon: 'edit', cb: onOptionAskEditMeal }
                    ]}
					reOrder={handleReOrder}
                />


                <AskDeletePopup
                    askDeleteMeal={askDeleteMeal}
                    deleteAskedDeletedMeal={deleteAskedDeletedMeal}
                    setAskDeleteMeal={setAskDeleteMeal}
                />

                <button onClick={() => { navigate('/view-meal') }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>

            </div>
        </div>
    )
}