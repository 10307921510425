import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/imgs/logo.png'
import { authService } from '../../services/authService';
import { syncValidation } from '../../services/validationService';


export default function RestorePassword(props) {

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const navigate = useNavigate()
    const [isValid, setIsValid] = useState(false);
    const { search } = useLocation();
    const [token, setToken] = useState('');

    useEffect(() => {
        setToken(search.split('=')[1])
    }, [search])

    useEffect(() => {
        document.title = "Step By Step - Restore password";
    }, [])

    useEffect(() => {
        setIsValid(
            syncValidation.isPasswordValid(password) &&
            password === repeatPassword
        )
    }, [password, repeatPassword])


    const restorePassword = async () => {
        if (!isValid) return;
        setIsLoad(true);
        await authService.recoveryPassword(token, repeatPassword)
        navigate('/login')
        setIsLoad(false);
    }

    return (
        <div className='main-container page'>
            <div className='login-page page'>
                <div className='flex column gap-1' >
                    <div className='flex column gap-1 container' >
                        <img src={logo} alt="sbs-logo" />
                        <input
                            className='sbs-input'
                            type="password"
                            value={password}
                            onChange={({ target }) => { setPassword(target.value) }}
                            placeholder="סיסמא"
                            onKeyDown={(ev) => { if (ev.code === 'Enter') restorePassword() }}
                        />
                        <input
                            className='sbs-input'
                            type="password"
                            value={repeatPassword}
                            onChange={({ target }) => { setRepeatPassword(target.value) }}
                            placeholder="סיסמא שוב"
                            onKeyDown={(ev) => { if (ev.code === 'Enter' && isValid) restorePassword() }}
                        />
                        <button disabled={!isValid || isLoad} onClick={() => { restorePassword() }} className='button-primary' >אפס סיסמא</button>

                    </div>
                </div>
            </div>
        </div>
    )
}