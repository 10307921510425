import { useEffect, useState } from 'react';
import { employeeService } from '../../services/employeeService';
import SbsIcons from '../../components/SbsIcons';
// import EmployeeDataByLabels from './components/EmployeeDataByLabels';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeDataByLabels from './components/EmployeeDataByLabels';
import { utilService } from '../../services/utilService';
import { syncValidation } from '../../services/validationService';


var updateCostumerTimeOut = null;
export default function ViewEmployee(props) {

    const [employee, setEmployee] = useState(employeeService.getEmpty());
    const [currLabel, setCurrLabel] = useState('personalInformation');
    const [isNew, setIsNew] = useState(false);
    const [isDataUpdated, setIsDataUpdated] = useState(true);
    const [tempPassword, setTempPassword] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        document.title = "Step By Step - Employee";
    }, [])

    const employeeStatus = {
        Active: 'פעיל',
        Inactive: 'לא פעיל',
        Suspended: 'מושהה'
    }

    const labelsMap = {
        personalInformation: "פרטים אישיים",
        files: "מסמכים",
        employeeManagment: "ניהול עובד",
        paymentManagment: "ניהול משכורות",
        permissionsManagment: "ניהול הרשאות",
    }

    useEffect(() => {
        if (id) getEmployee();
    }, [])




    useEffect(() => {
        if (employee.id) setIsNew(false)
        else setIsNew(true);
    }, [employee])

    useEffect(() => {
        if (!isNew) {
            setIsDataUpdated(false);
            if (updateCostumerTimeOut) clearTimeout(updateCostumerTimeOut);
            updateCostumerTimeOut = setTimeout(async () => {
                if (employee.email) await employeeService.edit(employee);
                setIsDataUpdated(true);
            }, 2000);
        }

        return () => {
            clearTimeout(updateCostumerTimeOut);
        }
    }, [employee])

    const getEmployee = async () => {
        const currEmployee = await employeeService.getById(id);
        setEmployee(currEmployee);
    }

    const updateTempPassword = () => {
        const password = Math.random().toString(16).slice(2);
        setTempPassword(password)
    }

    const addEmployee = async () => {
        try {
            const newEmployee = await employeeService.add(employee, tempPassword);
            if (newEmployee) {
                navigate("" + newEmployee.id)
                setEmployee(newEmployee);
                setIsDataUpdated(true);
            }
        } catch {

        }
    }

    const deleteEmployee = async () => {
        await employeeService.remove(employee.id);
        navigate('/manage-employees')
    }

    return (
        <div className='main-container page'>
            <div className='view-employee-page'>
                <span className='sbs-title'>{isNew ? 'עובד חדש' : 'כרטיס עובד'}</span>

                <div className='image-and-basic'>
                    <div className='profile-image'>
                        <img src={employee.profileImage ? employee.profileImage : 'https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif'} />
                    </div>
                    <div className='basic-data'>

                        <div className='flex gap-1 align-start'>
                            <input
                                type="text"
                                className='sbs-input'
                                value={employee.firstName}
                                onChange={({ target }) => { setEmployee(employee => ({ ...employee, firstName: target.value })) }}
                                placeholder="שם פרטי"
                            />
                            <input
                                type="text"
                                className='sbs-input'
                                value={employee.lastName}
                                onChange={({ target }) => { setEmployee(employee => ({ ...employee, lastName: target.value })) }}
                                placeholder="שם משפחה"
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                className='sbs-input'
                                value={employee.email}
                                onChange={({ target }) => { setEmployee(employee => ({ ...employee, email: target.value })) }}
                                placeholder="דואר אלקטרוני"
                            />
                        </div>

                        <div >
                            {
                                isNew ? (
                                    <div>
                                        <div className='flex gap-1 align-start'>

                                            <input
                                                className='sbs-input'
                                                type="text"
                                                value={tempPassword}
                                                onChange={({ target }) => { setTempPassword(target.value) }}
                                                placeholder="סיסמא זמנית"
                                            />
                                            <button
                                                className='button-primary'
                                                onClick={() => { updateTempPassword() }}
                                            >
                                                צור סיסמא
                                            </button>
                                            <div class="tooltip">
                                                ?
                                                <div class="info">
                                                    <h3>דרישות סיסמא</h3>
                                                    <p>מינימום תווים 8</p>
                                                    <p>מקסימום תווים 32</p>
                                                    <p>חייב להכיל אותיות באנגלית ומספרים</p>
                                                    <div class="arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <button onClick={() => deleteEmployee()} className='button-secondary'>
                                            מחק עובד
                                        </button>
                                    </div>
                                )


                            }

                        </div>


                        <div className='flex align-center gap-1' >
                            <select
                                className="sbs-select"
                                value={employee.status}
                                onChange={({ target }) => setEmployee(employee => ({ ...employee, status: target.value }))}
                            >
                                {
                                    Object.keys(employeeStatus).map(key => (
                                        <option key={key} value={key}>{employeeStatus[key]}</option>
                                    ))
                                }

                            </select>
                        </div>

                        {
                            !isNew &&
                            <div className='flex align-center' >
                                <span className=''>מספר עובד: <span>{employee.id}</span></span>
                            </div>
                        }
                        {
                            isNew &&
                            <div className='flex align-center'>
                                <button
                                    onClick={() => addEmployee()}
                                    className='button-primary'
                                    disabled={
                                        !syncValidation.isEmailValid(employee.email) ||
                                        !syncValidation.isPasswordValid(tempPassword)
                                    }
                                >
                                    צור עובד
                                </button>
                            </div>
                        }



                    </div>
                </div>
                {
                    !isNew &&
                    <div className='data-container'>
                        <div className='labels'>
                            {
                                Object.keys(labelsMap).map(labelKey => (
                                    <div onClick={() => { setCurrLabel(labelKey) }} key={labelKey} className={`label ${labelKey === currLabel ? 'curr' : ''}`}>
                                        <SbsIcons icon={labelKey} />
                                        <span>{labelsMap[labelKey]}</span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='data'>
                            <EmployeeDataByLabels
                                employee={employee}
                                setEmployee={setEmployee}
                                label={currLabel}
                                isNew={isNew}
                                getEmployee={getEmployee}
                            />
                        </div>
                    </div>
                }
                {
                    !isNew &&
                    <div className={`data-situation ${isDataUpdated ? 'updated' : 'not-updated'}`}>
                        <SbsIcons icon={isDataUpdated ? 'check' : 'update'} />
                    </div>
                }
            </div >
        </div >
    )
}