import { useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { mealService } from "../../../services/mealService";
import { utilService } from "../../../services/utilService"
import LoadMenuTemplatePopup from "./LoadMenuTemplatePopup";

export default function MealsCreation({
    menu,
    setMenu,
    nextStep,
    customer,
    isNextStepDisable
}) {

    const [isLoadMenuTemplateOpen, setIsLoadMenuTemplateOpen] = useState(false);

    const updateMealData = (mealIdx, dataType, value) => {
        const editedMeal = { ...menu.meals[mealIdx] }
        editedMeal[dataType] = value;
        setMenu({ ...menu, meals: menu.meals.map((meal, idx) => idx === mealIdx ? editedMeal : meal) })
    }

    const deleteMeal = (mealIdx) => {
        setMenu({ ...menu, meals: menu.meals.filter((_, idx) => idx !== mealIdx) })
    }


    const loadTemplate = (menuTemplate) => {
        console.log('menu', menu);
        console.log('menuTemplate', menuTemplate);
        setMenu({
            ...menu,
            title: menuTemplate.title,
            description: menuTemplate.description,
            meals: menuTemplate.mealTemplates.map(mealFromTemplate => {
                return {
                    ...mealFromTemplate,
                    products: mealFromTemplate.items.map(item => {
                        return {
                            id: item.id,
                            title: item.name,
                            type: item.type,
                            numCalorie: item.calories,
                            numCarbohydrate: item.carbs,
                            numFat: item.fats,
                            numProtein: item.proteins,
                            numFiber: item.fiber,
                            remarks: item.remarks,
                            numSodium: item.sodiums,
                            numGrams: item.numGrams,
                            customAmount: item.customAmount,
                        }
                    })
                }
            })
        })
    }

    return (
        <div className="flex column gap-1 align-start">
            <span className="sbs-title">תפריט עבור {customer.firstName} {customer.lastName}</span>

            <div className="flex column">
                <span>שם התפריט</span>
                <input
                    className='sbs-input'
                    type="text"
                    value={menu.title}
                    onChange={({ target }) => { setMenu({ ...menu, title: target.value }) }}
                    placeholder="שם התפריט"
                />
            </div>

            <div className="flex column">
                <span>תיאור התפריט</span>
                <input
                    className='sbs-input'
                    type="text"
                    value={menu.description}
                    onChange={({ target }) => { setMenu({ ...menu, description: target.value }) }}
                    placeholder="תיאור התפריט"
                />
            </div>

            {/* <div className="flex column">
                <span>כמות מים יומית בליטרים</span>
                <input
                    className='sbs-input'
                    type="number"
                    value={menu.dailyWaterInLiter}
                    onChange={({ target }) => { setMenu({ ...menu, dailyWaterInLiter: target.value }) }}
                    placeholder="כמות מים יומית בליטרים"
                />
            </div> */}

            <div className="flex column">
                <span>תאריך התחלה</span>
                <input
                    className='sbs-input'
                    type="date"
                    value={utilService.timestampToDatePickerTime(menu.startDate)}
                    onChange={({ target }) => { setMenu({ ...menu, startDate: utilService.datePickerTimeToTimestamp(target.value) }) }}
                    placeholder="תאריך התחלה"
                />
            </div>

            <div className="flex column">
                <span>תאריך סיום</span>
                <input
                    className='sbs-input'
                    type="date"
                    value={utilService.timestampToDatePickerTime(menu.endDate)}
                    onChange={({ target }) => { setMenu({ ...menu, endDate: utilService.datePickerTimeToTimestamp(target.value) }) }}
                    placeholder="תאריך סיום"
                />
            </div>

            <div className="flex column gap-1">
                <span>הוסף ארוחות</span>
                {
                    menu.meals.map((meal, idx) => (
                        <div className="flex gap-1 align-center" key={idx} >
                            <div className="flex column ">
                                {/* <span>שם התפריט</span> */}
                                <input
                                    className='sbs-input'
                                    type="text"
                                    value={meal.title}
                                    onChange={({ target }) => { updateMealData(idx, 'title', target.value) }}
                                    placeholder="שם הארוחה"
                                />
                            </div>
                            <div className="flex column">
                                {/* <span>תיאור התפריט</span> */}
                                <input
                                    className='sbs-input'
                                    type="text"
                                    value={meal.description}
                                    onChange={({ target }) => { updateMealData(idx, 'description', target.value) }}
                                    placeholder="תיאור הארוחה"
                                />
                            </div>

                            <div onClick={() => { deleteMeal(idx) }} className='flex-center pointer white-svg'>
                                <SbsIcons icon={'close'} />
                            </div>
                        </div>
                    ))
                }

                <div className="flex gap-1" >
                    <button onClick={() => setMenu({ ...menu, meals: [...menu.meals, mealService.getEmpty()] })} className="button-primary" >הוסף ארוחה</button>

                    <button onClick={() => { setIsLoadMenuTemplateOpen(true) }} className="button-secondary flex gap-1 align-center" >
                        טען תבנית
                        <SbsIcons icon="load" />
                    </button>
                </div>


                <LoadMenuTemplatePopup
                    isLoadMenuTemplateOpen={isLoadMenuTemplateOpen}
                    setIsLoadMenuTemplateOpen={setIsLoadMenuTemplateOpen}
                    loadMenuTemplate={loadTemplate}
                />

            </div>
            <div className="flex w100 justify-space-between" >
                <div />
                <button disabled={isNextStepDisable} onClick={() => nextStep()} className="button-primary" >עבור לעריכת הארוחות</button>
            </div>
        </div>
    )
}