import { useState } from "react"

export default function PriorityPicker({
    priority,
    setPriority,
    limit
}) {

    const [hoveredStarIdx, setHoveredStarIdx] = useState(-1);

    return (
        <div className="priority-picker" >
            {
                Array.from(Array(limit ?? 5).keys()).map((_, idx) => (
                    <span
                        key={idx}
                        onClick={() => { setPriority(idx + 1) }}
                        onMouseEnter={() => setHoveredStarIdx(idx)}
                        onMouseLeave={() => { setHoveredStarIdx(-1) }}
                        className="pointer star"
                    >
                        {(priority >= idx + 1 || hoveredStarIdx>=idx ) ? '★' : '☆'}
                    </span>
                ))
            }
        </div>
    )
}