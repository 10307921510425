import { useEffect, useRef, useState } from "react"
import { errorService } from "../services/errorService";
import { DangerSymbol } from "./DangerSymbol";
import { SuccessSymbol } from "./SuccessSymbol";
import { WarningSymbol } from "./WarningSymbol";

export function ErrorHandler() {

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        errorService.errors$.subscribe(errors => {
            setErrors(errors)
        })
    }, [])

    const GetSymbol = ({ type }) => {
        switch (type) {
            case 'danger':
                return <DangerSymbol />
            case 'warning':
                return <WarningSymbol />
            case 'success':
                return <SuccessSymbol />
            default:
                <div />
        }
    }





    return (
        <div className="error-container">
            {
                errors.map((error, idx) => (
                    <div className={`error ${error.type}`} key={idx}>
                        <GetSymbol type={error.type} />
                        <span className="msg" >
                            {error.msg}
                        </span>
                    </div>
                ))
            }
        </div>
    )
}