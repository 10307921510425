import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const paymentService = {
    get,
    remove,
    // getRandom,
    getEmpty,
    edit,
    add,
    getByIds
}

let payments = [
    {
        "id": "p101",
        "date": Date.now(),
        "type": "Cash",
        "amount": 250,
        "description": "עבור משתמש פרמיום"
    },
    {
        "id": "p102",
        "date": Date.now(),
        "type": "Cash",
        "amount": 350,
        "description": "עבור תוכנית אימונים"
    },
]

function getEmpty() {
    return {
        "id": "",
        "date": Date.now(),
        "type": "Cash",
        "amount": 0,
        "description": ""
    };
}

const ENDPOINT = 'customers/payments'

async function get(customer_id) {
	if(!customer_id) return []
	
    const res = await httpService.get(ENDPOINT + `/${customer_id}`);
    const payments = res.data.map(payment => {
        const { id, date, type, amount, description } = payment
        return {
            id,
            date,
            type,
            amount,
            description
        }
    })
    return payments
}

async function remove(id) {
    try {
        const res = await httpService.delete(ENDPOINT + '/' + id);
        errorService.updateError({ msg: 'התשלום נמחק', type: 'warning', time: Date.now() })
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(payment, customerId) {
    try {
        const { id, date, type, amount, description } = payment
        const body = {
            id,
            customerId,
            date,
            type,
            amount: +amount,
            description
        }
        const res = await httpService.put(ENDPOINT, body);
        errorService.updateError({ msg: 'התשלום עודכן', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(payment, customerId) {
    try {
        const { date, type, amount, description } = payment
        const body = {
            customerId,
            date,
            type,
            amount: +amount,
            description
        }
        const res = await httpService.post(ENDPOINT, body);
        errorService.updateError({ msg: 'התשלום נוסף', type: 'success', time:Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }

async function getByIds(ids) {
    const paymentsFromIds = ids.map(id => payments.find(payment => payment.id === id));
    return paymentsFromIds
}

