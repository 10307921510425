import { utilService } from "../../../services/utilService"
import SbsIcons from "../../../components/SbsIcons"

export default function ProductsRemarksPopup({ isProductRemarksOpen, setIsProductRemarksOpen, product, setProduct }) {

    const remarksMap = {
        Vegan: 'טבעוני',
        Vegetarian: 'צמחוני',
        Pregnancy: 'היריון',
        Lactose: 'לקטוז',
        Peanuts: 'בוטנים',
        Nuts: 'אגוזים',
        Eggs: 'ביצים',
        Fish: 'דגים',
        Sesame: 'סומסום',
        Soya: 'סויה',
    }

    const toggleRemark = (remark) => {
        const remarks = product.remarks.some(remarkItem => remarkItem === remark) ? product.remarks.filter(remarkItem => remarkItem !== remark) : [...product.remarks, remark]
        setProduct({ ...product, remarks })
    }

    return (
        <div onClick={() => setIsProductRemarksOpen(false)} className={isProductRemarksOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>ערוך היערות</span>
                    <div className='close-popup' onClick={() => setIsProductRemarksOpen(false)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <form className="flex column gap-0-5">
                        {
                            isProductRemarksOpen && Object.keys(remarksMap).map((remarkKey) => (
                                <div key={remarkKey} className="group sbs-checkbox-container">
                                    <input
                                        id={remarkKey}
                                        name={remarkKey}
                                        type="checkbox"
                                        checked={product.remarks.some(remark => remark === remarkKey)}
                                        onChange={({ target }) => toggleRemark(remarkKey)}
                                    />
                                    <label htmlFor={remarkKey}>{remarksMap[remarkKey]}</label>
                                </div>
                            ))
                        }
                    </form>
                </div>
                <div className='popup-footer'>
                    <button className='button-secondary' onClick={() => setIsProductRemarksOpen(false)} >סגור</button>
                </div>
            </div>
        </div>
    )
}