import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { groupService } from "../../../services/groupService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditdGroup, askEditGroup, setAskEditGroup }) {

    const [isOpen, setIsOpen] = useState(false);
    const [group, setGroup] = useState(groupService.getEmpty());


    useEffect(() => {
        if (askEditGroup) {
            setGroup({ ...askEditGroup })
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditGroup])

    return (
        <div onClick={() => setAskEditGroup(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    {!askEditGroup?.id ? <span>יצירת קבוצה</span> : <span>ערוך קבוצה</span>}
                    <div className='close-popup' onClick={() => setAskEditGroup(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body flex column'>
                    <input
                        className='sbs-input'
                        type="text"
                        value={group.title}
                        onChange={({ target }) => { setGroup({ ...group, title: target.value }) }}
                        placeholder="שם הקבוצה"
                    />
                </div>
                <div className='popup-footer'>
                    <button className='button-primary' onClick={() => editAskedEditdGroup(group)} >שמירה</button>
                    {/* <button className='button-secondary' onClick={() => setAskEditGroup(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}