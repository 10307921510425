import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const salaryService = {
    // get,
    getByEmployeeId,
    getEmpty,
    // removePayment,
    // addPayment,
    remove,
    edit,
    add,
    addPaycheck,
    removePaycheck
}

let salaries = [
]

function getEmpty() {
    return {
        "id": '',
        "year": new Date().getFullYear(),
        "month": new Date().getMonth() + 1,
        "amount": 0,
        "paymantMethod": "Cash",
        "employeeId": ''
    };
}

const ENDPOINT = "users/user_salaries";

async function remove(id) {
    try {
        const res = await httpService.delete(ENDPOINT + `/${id}`);
        errorService.updateError({ msg: 'משכורת נמחקה', type: 'warning', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(salary) {
    try {
        const { id, year, month, amount, paymantMethod } = salary;
        const body = {
            id,
            year: +year,
            month: +month,
            amount: +amount,
            type: paymantMethod,
        }
        const res = await httpService.put(ENDPOINT, body);
        errorService.updateError({ msg: 'המשכורת עודכנה', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(salary, employeeId) {
    try {
        const { year, month, amount, paymantMethod } = salary;
        const body = {
            userID: employeeId,
            year: +year,
            month: +month,
            amount: +amount,
            type: paymantMethod,
        }
        const res = await httpService.post(ENDPOINT, body);
        errorService.updateError({ msg: 'משכורת נוספה', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getByEmployeeId(id) {
    const res = await httpService.get(ENDPOINT + `/${id}`);
    const salaries = res.data.map(salary => {
        const { id, year, month, amount, type, userID, file } = salary;
        return {
            id,
            year,
            month,
            amount,
            file,
            paymantMethod: type,
            employeeId: userID
        }
    });
    return salaries;
}

async function addPaycheck(salaryId, file) {
    var bodyFormData = new FormData();
    bodyFormData.append('salaryId', salaryId);
    bodyFormData.append(file.name, file, file.name)
    const res = await httpService.post(ENDPOINT + '/file', bodyFormData);
    return res;
}

async function removePaycheck(salaryId) {
    const res = await httpService.delete(`${ENDPOINT}/file/${salaryId}`);
    return res;
}
