import SbsIcons from "../../../components/SbsIcons"
import SbsTable from "../../../components/SbsTable"
import uploadImg from '../../../assets/imgs/upload-img.png'
import { utilService } from "../../../services/utilService"
import { useEffect, useState } from "react"
import { salaryService } from "../../../services/salaryService"
import AskEditPopup from "./AskEditPopup"
import AskDeletePopup from "./AskDeletePopup"
import { employeeService } from "../../../services/employeeService"
import { syncValidation } from "../../../services/validationService"
import { globalVariable } from "../../../services/globalVariable"
import AskShowPaycheckPopup from "./AskShowPaycheckPopup"

export default function EmployeeDataByLabels({ employee, setEmployee, label, isNew, getEmployee }) {

    const [employeeSalaries, setEmployeeSalaries] = useState([]);
    const [askEditSalary, setAskEditSalary] = useState(null);
    const [askDeleteSalary, setAskDeleteSalary] = useState(null);
    const [askShowPaycheck, setAskShowPaycheck] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [isChangePasswordLoad, setIsChangePasswordLoad] = useState(false);

    useEffect(() => {
        setNewPassword([])
    }, [])

    const paymentMathods = {
        Cash: 'מזומן',
        Check: "צ'ק",
        CreditCard: "כרטיס אשראי",
        BankTransfer: "העברה בנקאית",
        Bit: "ביט",
    }


    // const [groups, setGroups] = useState([]);
    // const [customerPayments, setCustomerPayments] = useState([]);
    // const [customerPlans, setCustomerPlans] = useState([]);
    // const [customerMenus, setCustomerMenus] = useState([]);
    // const [askDeletePlan, setAskDeletePlan] = useState(null);
    // const [askDeleteMenu, setAskDeleteMenu] = useState(null);
    // let navigate = useNavigate();

    useEffect(() => {
        if (employee.id) {
            updateSalaries();
        }
    }, [employee, askShowPaycheck])

    const updateSalaries = async () => {
        const salaries = await salaryService.getByEmployeeId(employee.id);
        setEmployeeSalaries(salaries)
    }

    // useEffect(() => {
    //     updateGroups();
    // }, [])

    // useEffect(() => {
    //     updatePayments();
    //     updatePlans();
    //     updateMenus();
    // }, [customer])



    const deleteAskedDeletedSalary = async () => {
        await salaryService.remove(askDeleteSalary.id);
        updateSalaries();
        setAskDeleteSalary(null)
    }

    const editAskedEditSalary = async (salary) => {
        console.log(salary);
        if (!askEditSalary.id) addAskedEditSalary(salary);
        else {
            await salaryService.edit(salary);
            updateSalaries();
            setAskEditSalary(null);
        }
    }

    const addAskedEditSalary = async (salary) => {
        await salaryService.add(salary, employee.id);
        updateSalaries();
        setAskEditSalary(null);
    }

    const onOptionAskDeleteSalary = (id) => {
        const salary = employeeSalaries.find(salary => salary.id === id);
        setAskDeleteSalary(salary);
    }
    const onOptionShowPaycheck = (id) => {
        const salary = employeeSalaries.find(salary => salary.id === id);
        setAskShowPaycheck(salary);
    }

    const onOptionAskEditSalary = (id) => {
        const salary = employeeSalaries.find(salary => salary.id === id);
        setAskEditSalary(salary);
    }

    const changePassword = async () => {
        setIsChangePasswordLoad(true)
        const password = newPassword
        setNewPassword('')
        await employeeService.changePassword(employee.id, newPassword);
        setIsChangePasswordLoad(false);

    }


    // const deleteAskedDeletedPlan = async () => {
    //     const newPlans = customerPlans.filter(plan => plan.id !== askDeletePlan.id);
    //     setCustomerPlans(newPlans);
    //     await planService.remove(askDeletePlan.id);
    //     setAskDeletePlan(null)
    // }

    // const deleteAskedDeletedMenu = async () => {
    //     const newMenus = customerMenus.filter(menu => menu.id !== askDeleteMenu.id);
    //     setCustomerMenus(newMenus);
    //     await menuService.remove(askDeleteMenu.id);
    //     setAskDeleteMenu(null)
    // }

    // const onOptionAskDeletePlan = (id) => {
    //     const plan = customerPlans.find(customerPlan => customerPlan.id === id);
    //     setAskDeletePlan(plan);
    // }

    // const onOptionAskEditPlan = (id) => {
    //     navigate(`/view-plan/plan/${id}`)
    // }

    // const updateGroups = async () => {
    //     const groups = await groupService.get();
    //     setGroups(groups);
    // }

    // const updatePayments = async () => {
    //     const payments = await paymentService.getByIds(customer.payments);
    //     setCustomerPayments(payments);
    // }

    // const updatePlans = async () => {

    //     const plans = customer.id ? await planService.getByCustomerId(customer.id) : [];

    //     setCustomerPlans(plans.map(plan => ({
    //         ...plan,
    //         createAt: utilService.timestampToDDMMYYYY(plan.created),
    //         progress: <DatesProgressBar startDate={plan.startDate} endDate={plan.endDate} />
    //     })));
    // }

    // const updateMenus = async () => {
    //     const menus = await menuService.getByCustomerId(customer.id);
    //     setCustomerMenus(menus.map(menu => ({
    //         ...menu,
    //         createAt: utilService.timestampToDDMMYYYY(menu.createAt),
    //         progress: <DatesProgressBar startDate={menu.startDate} endDate={menu.endDate} />
    //     })))
    // }

    // const toggleGroup = (groupId) => {
    //     let updateGroups = null;
    //     if (customer.groups.some(groupIdItem => groupIdItem === groupId)) {
    //         updateGroups = customer.groups.filter(groupIdItem => groupIdItem !== groupId);
    //     } else updateGroups = [...customer.groups, groupId];
    //     setCustomer({ ...customer, groups: updateGroups });
    // }

    const removeFile = async (fileName) => {
        // const files = employee.files.filter((file, fileIdx) => fileIdx !== idx);
        // setEmployee(employee => ({ ...employee, files }))
        await employeeService.deleteFile(employee.id, fileName);
        await getEmployee()
    }

    const onUploadFiles = async (event) => {
        await employeeService.addFilesToEmployee(event.target.files, employee.id)
        await getEmployee()
        // const newFiles = Object.keys(event.target.files).map(key => event.target.files[key]);

        // setEmployee({ ...employee, files: [...employee.files, ...newFiles] })
    }

    const dowloandFile = (fileName) => {
        window.open(
            `https:${globalVariable.BASE_URL}users/files/${employee.id}/${fileName}`,
            // `https://2243-80-178-138-34.ngrok.io/api/users/files/${employee.id}/${fileName}`,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    // const onOptionAskEditMenu = (id) => {
    //     navigate(`/view-menu/menu/${id}`)
    // }

    // const onOptionAskDeleteMenu = (id) => {
    //     const currMenu = customerMenus.find(menu => menu.id === id);
    //     if (currMenu) (
    //         setAskDeleteMenu(currMenu)
    //     )
    // }

    switch (label) {
        case 'personalInformation':
            return (
                <>
                    {
                        !isNew &&
                        <span className=''>תאריך הצטרפות: <span>{utilService.timestampToDDMMYYYY(employee.created)}</span></span>
                    }
                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={employee.idNumber}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, idNumber: target.value })) }}
                            placeholder="תעודת זהות"
                            name="idNumber"
                        />
                        <div className="placeholder">תעודת זהות</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={employee.firstName}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, firstName: target.value })) }}
                            placeholder='שם פרטי'
                            name="firstName"
                        />
                        <div className="placeholder">שם פרטי</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={employee.lastName}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, lastName: target.value })) }}
                            placeholder='שם משפחה'
                            name="lastName"
                        />
                        <div className="placeholder">שם משפחה</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={employee.phone}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, phone: target.value })) }}
                            placeholder="טלפון"
                            name="phone"
                        />
                        <div className="placeholder">טלפון</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={employee.address}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, address: target.value })) }}
                            placeholder='כתובת מגורים'
                            name="address"
                        />
                        <div className="placeholder">כתובת מגורים</div>
                    </div>

                    <div className="flex gap-1 align-end" >
                        <div className="sbs-input-placeholder">
                            <input
                                type="password"
                                value={newPassword}
                                onChange={({ target }) => { setNewPassword(target.value) }}
                                placeholder='סיסמא חדשה'
                                name="newPassword"
                            />
                            <div className="placeholder">סיסמא חדשה</div>
                        </div>

                        <button
                            className="button-primary"
                            onClick={() => changePassword()}
                            disabled={isChangePasswordLoad || !syncValidation.isPasswordValid(newPassword)}
                        >
                            שנה סיסמא
                        </button>
                    </div>

                    {
                        newPassword && !syncValidation.isPasswordValid(newPassword) &&
                        <small>הסיסמא חייבת לכלול אות קטנה אות גדולה מספרים ולהיות גדולה מ 8 תווים</small>
                    }
                </>
            )
        case 'employeeManagment':
            return (
                <>
                    {
                        !isNew &&
                        <span className=''>התחבר לאחרונה: <span>{utilService.timestampToDDMMYYYY(employee.lastLogin)}</span></span>
                    }
                    <div className="sbs-input-placeholder">
                        <input
                            type="text"
                            value={employee.role}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, role: target.value })) }}
                            placeholder="תפקיד"
                        />
                        <div className="placeholder">תפקיד</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="number"
                            value={employee.monthSalary}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, monthSalary: target.value })) }}
                            placeholder="שכר גלובלי"
                        />
                        <div className="placeholder">שכר גלובלי</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="number"
                            value={employee.hourSalary}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, hourSalary: target.value })) }}
                            placeholder="שכר שעתי"
                        />
                        <div className="placeholder">שכר שעתי</div>
                    </div>

                    <div className="sbs-input-placeholder">
                        <input
                            type="number"
                            value={employee.premiumSalary}
                            onChange={({ target }) => { setEmployee(employee => ({ ...employee, premiumSalary: target.value })) }}
                            placeholder="שכר פרמייה"
                        />
                        <div className="placeholder">שכר פרמייה</div>
                    </div>

                    <div className='flex align-center gap-1' >
                        <span>הרשאות מנהל</span>
                        <label className="sbs-switch">
                            <input
                                type="checkbox"
                                checked={employee.isAdmin}
                                onChange={() => { setEmployee(employee => ({ ...employee, isAdmin: !employee.isAdmin })) }}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>


                </>
            )
        case 'permissionsManagment':
            return (
                // groupsPremission:false,
                // tasksPremission:false,
                <div className="premissions-container" >
                    <span>הרשאות שעון נוכחות</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.manageWorkClocksPremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, manageWorkClocksPremission: !employee.manageWorkClocksPremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                    <span>הרשאות ניהול עובדים</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.manageEmployeesPremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, manageEmployeesPremission: !employee.manageEmployeesPremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                    <span>הרשאות ניהול לקוחות</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.manageCustomersPremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, manageCustomersPremission: !employee.manageCustomersPremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                    <span>הרשאות ניהול תזונה</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.nourishmentPremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, nourishmentPremission: !employee.nourishmentPremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                    <span>הרשאות ניהול אימונים</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.trainsPremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, trainsPremission: !employee.trainsPremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                    <span>הרשאות ניהול משפטי מוטיבציה</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.motivisionSentenncePremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, motivisionSentenncePremission: !employee.motivisionSentenncePremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                    <span>הרשאות קבוצות</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.groupsPremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, groupsPremission: !employee.groupsPremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                    <span>הרשאות משימות</span>
                    <label className={`sbs-switch ${employee.isAdmin ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={employee.tasksPremission || employee.isAdmin}
                            onChange={() => { setEmployee(employee => ({ ...employee, tasksPremission: !employee.tasksPremission })) }}
                        />
                        <span className="slider"></span>
                    </label>
                </div>
            )
        //     case 'training':
        //         return (
        //             <div className='flex column gap-1'>

        //                 <SbsTable
        //                     columnMap={{ "id": "מספר תוכנית", "createAt": "תאריך יצירה", "progress": "התקדמות" }}
        //                     isHaveOptions={true}
        //                     metaData={customerPlans}
        //                     options={[
        //                         { icon: 'delete', cb: onOptionAskDeletePlan },
        //                         { icon: 'edit', cb: onOptionAskEditPlan }
        //                     ]}
        //                 ></SbsTable>

        //                 <Link className="add-to-table" to={`/view-plan/customer/${customer.id}`} >
        //                     <SbsIcons icon="add" />
        //                 </Link>


        //                 <AskDeletePlan
        //                     askDeletePlan={askDeletePlan}
        //                     setAskDeletePlan={setAskDeletePlan}
        //                     deleteAskedDeletedPlan={deleteAskedDeletedPlan}
        //                 />


        //             </div>
        //         )
        //     case 'nutrition':
        //         return (
        //             <div>
        //                 <div className='flex column gap-1'>

        //                     <SbsTable
        //                         columnMap={{ "id": "מספר תפריט", "description": "תיאור", "createAt": "תאריך יצירה", "progress": "התקדמות" }}
        //                         isHaveOptions={true}
        //                         metaData={customerMenus}
        //                         options={[
        //                             { icon: 'delete', cb: onOptionAskDeleteMenu },
        //                             { icon: 'edit', cb: onOptionAskEditMenu }
        //                         ]}
        //                     ></SbsTable>

        //                     <Link className="add-to-table" to={`/view-menu/customer/${customer.id}`} >
        //                         <SbsIcons icon="add" />
        //                     </Link>

        //                     <AskDeleteMenu
        //                         askDeleteMenu={askDeleteMenu}
        //                         setAskDeleteMenu={setAskDeleteMenu}
        //                         deleteAskedDeletedMenu={deleteAskedDeletedMenu}
        //                     />


        //                 </div>
        //             </div>
        //         )
        case 'files':
            return (
                <div className='files-data' >
                    {
                        !!employee.files.length && (
                            <div className='files-list'>
                                {
                                    employee.files.map((file, idx) => (
                                        <div key={idx} className='file-item'>
                                            <span onClick={() => { dowloandFile(file) }} >
                                                {file}
                                            </span>
                                            <div onClick={() => removeFile(file)} >
                                                <SbsIcons icon={'close'} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }

                    <div className="files-upload-container">
                        <div className="not-file-upload">
                            <img className="empty-image" src={uploadImg} alt="upload image" />
                            <span>בחר קבצים <span>או גרור אותם לכאן</span></span>
                        </div>
                        <input type="file" multiple={true} className="files-upload" onChange={onUploadFiles} />
                    </div>

                </div>
            )
        //     // case 'userManagment':
        //     //     return <MdIcons.MdOutlineManageAccounts />
        //     case 'userManagment':
        //         return (
        //             <>
        //                 {
        //                     customer.lastSeen &&
        //                     <div className='flex align-center' >
        //                         <span className=''>נראה לאחרונה: <span>{customer.lastSeen}</span></span>
        //                     </div>
        //                 }
        //                 <div className='flex align-center gap-1' >
        //                     <span>לקוח פרימיום</span>
        //                     <label className="sbs-switch">
        //                         <input
        //                             type="checkbox"
        //                             checked={customer.isPremium}
        //                             onChange={() => { setCustomer(customer => ({ ...customer, isPremium: !customer.isPremium })) }}
        //                         />
        //                         <span className="slider"></span>
        //                     </label>
        //                 </div>
        //             </>
        //         )
        case 'paymentManagment':
            return (
                <>
                    <SbsTable
                        columnMap={{
                            "id": "מספר משכורת",
                            "year": "שנה",
                            "month": "חודש",
                            "amount": "סכום תשלום",
                            "paymantMethod": "אמצעי תשלום"
                        }}
                        isHaveOptions={true}
                        metaData={employeeSalaries.map(salary => ({ ...salary, paymantMethod: paymentMathods[salary.paymantMethod] }))}
                        options={[
                            { icon: 'delete', cb: onOptionAskDeleteSalary },
                            { icon: 'edit', cb: onOptionAskEditSalary },
                            { icon: 'paycheck', cb: onOptionShowPaycheck },
                        ]}
                    ></SbsTable>
                    <button className="add-to-table" onClick={() => setAskEditSalary(salaryService.getEmpty())} >
                        <SbsIcons icon="add" />
                    </button>

                    <AskDeletePopup
                        askDeleteSalary={askDeleteSalary}
                        deleteAskedDeletedSalary={deleteAskedDeletedSalary}
                        setAskDeleteSalary={setAskDeleteSalary}
                    />

                    <AskEditPopup
                        askEditSalary={askEditSalary}
                        setAskEditSalary={setAskEditSalary}
                        editAskedEditSalary={editAskedEditSalary}
                    />

                    <AskShowPaycheckPopup
                        askShowPaycheck={askShowPaycheck}
                        setAskShowPaycheck={setAskShowPaycheck}
                    />


                </>
            )
    }


}