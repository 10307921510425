import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { motivationalSentenceService } from "../../../services/motivationalSentenceService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditAerobic, askEditAerobic, setAskEditAerobic }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isSaveLoad, setIsSaveLoad] = useState(false);
    const [newName, setNewName] = useState("");
	const [youtubeUrl, setYoutubeUrl] = useState("");
    const [newDescription, setNewDescription] = useState("");


    useEffect(() => {
        if (askEditAerobic) {
            setIsLoad(false)
            setNewName(askEditAerobic.name);
			setYoutubeUrl(askEditAerobic.video);
            setNewDescription(askEditAerobic.description);
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditAerobic])

    return (
        <div onClick={() => setAskEditAerobic(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    {
                        askEditAerobic?.id ? (
                            <span>ערוך את {askEditAerobic?.name}</span>
                        ) : (
                            <span>צור פעולת אירובי</span>
                        )

                    }
                    <div className='close-popup' onClick={() => setAskEditAerobic(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <div className="flex column gap-0-5" >
                        <input
                            className='sbs-input'
                            type="text"
                            value={newName}
                            onChange={({ target }) => { setNewName(target.value) }}
                            placeholder="שם הפעילות"
                        />
						<input
                            className='sbs-input'
                            type="text"
                            value={youtubeUrl}
                            onChange={({ target }) => { setYoutubeUrl(target.value) }}
                            placeholder="קישור לסרטון של התרגיל"
                        />
                        <textarea
                            className='sbs-input'
                            type="text"
                            value={newDescription}
                            onChange={({ target }) => { setNewDescription(target.value) }}
                            placeholder="תיאור"
                        />
                    </div>
                </div>
                <div className='popup-footer'>
                    <button
                        disabled={isSaveLoad || !newName || !newDescription}
                        className='button-primary'
                        onClick={() => {
                            setIsSaveLoad(true)
                            editAskedEditAerobic(newName, newDescription, youtubeUrl)
                            setAskEditAerobic(null)
                            setIsSaveLoad(false)
                        }}
                    >
                        שמירה
                    </button>
                    {/* <button className='button-secondary' onClick={() => setAskEditAerobic(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}