import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { utilService } from "../../../services/utilService";

export default function AskDeleteMenu({ deleteAskedDeletedMenu, askDeleteMenu, setAskDeleteMenu }) {

    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        if (askDeleteMenu) setIsOpen(true);
        else setIsOpen(false);
    }, [askDeleteMenu])

    return (
        <div onClick={() => setAskDeleteMenu(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>אתה בטוח שאתה רוצה למחוק תפריט זה</span>
                    <div className='close-popup' onClick={() => setAskDeleteMenu(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    {/* <span> אתה בטוח שאתה רוצה למחוק את המשפט {askDeleteSentence?.sentence} ששייך לקבוצות {askDeleteSentence?.groups}</span> */}
                </div>
                <div className='popup-footer'>
                    <button className='button-primary' onClick={() => deleteAskedDeletedMenu()} >שמירה</button>
                    {/* <button className='button-secondary' onClick={() => setAskDeleteMenu(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}