import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";

export default function PermissionNutritionGuard(props) {

    const navigate = useNavigate();
    const [isNutritionPermission, setIsNutritionPermissions] = useState(authService.getPermissions().some(prem=>prem==='ManageNutrition'));

    useEffect(() => {
        if(!isNutritionPermission){
            navigate('/')
        } 
    }, [isNutritionPermission])
    




    return (
        isNutritionPermission &&
        <>
            {props.children}
        </>
    )
}