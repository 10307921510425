import { MeasureTypes } from '../components/meal-creator/MealCreator';
import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const productService = {
    get,
    remove,
    edit,
    add,
    getById,
    getEmpty
}

let products = [
    {
        id: 'p101',
        title: 'אוכל',
        type: 'general',
        numProtein: 2,
        numCarbohydrate: 10,
        numFat: 3,
        numCalorie: 150,
        numSodium: 0,
        numFiber: 0,
        isFavorite: false,
        remarks: [],
    },
    {
        id: 'p102',
        title: 'חזה עוף',
        type: 'protein',
        numProtein: 25,
        numCarbohydrate: 0,
        numFat: 0,
        numCalorie: 150,
        numSodium: 0,
        numFiber: 0,
        isFavorite: false,
        remarks: [],
    },
    {
        id: 'p103',
        title: '2 אוכל',
        type: 'general',
        numProtein: 0,
        numCarbohydrate: 0,
        numFat: 0,
        numCalorie: 0,
        numSodium: 0,
        numFiber: 0,
        isFavorite: false,
        remarks: [],
    },
]

function getEmpty() {
    return {
        id: '',
        title: '',
        type: 'Protein',
        numProtein: 0,
        numCarbohydrate: 0,
        numFat: 0,
        numCalorie: 0,
        numSodium: 0,
        numFiber: 0,
        amount: 0,
        customAmount: '',
        isFavorite: false,
        remarks: [],
		measureType: MeasureTypes[0].value,
    }
}

const ENDPOINT = "nutrition/items";

async function get() {
    const res = await httpService.get(ENDPOINT);
    console.log('res', res);
    const products = res.data.map(product => {
        return {
            id: product.id,
            title: product.name,
            type: product.type,
            numCalorie: product.calories,
            numCarbohydrate: product.carbs,
            numFat: product.fats,
            numProtein: product.proteins,
            numFiber: product.fiber,
            remarks: product.remarks,
            numSodium: product.sodiums,

            amount: product.amount,
            customAmount: product.customAmount,

			measureType: product.measureType,
        }
    });
    return products;
}

async function remove(id) {
    try {
        const res = await httpService.delete(ENDPOINT + `/${id}`);
        errorService.updateError({ msg: 'המוצר נמחק', type: 'warning', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(editedProduct) {
    console.log(editedProduct);
    try {
        const body = {
            id: editedProduct.id,
            name: editedProduct.title,
            type: editedProduct.type,
            proteins: +editedProduct.numProtein,
            carbs: +editedProduct.numCarbohydrate,
            fats: +editedProduct.numFat,
            calories: +editedProduct.numCalorie,
            sodiums: +editedProduct.numSodium,
            fibers: +editedProduct.numFiber,
            customAmount: editedProduct.customAmount,
            isFavorite: editedProduct.isFavorite,
            remarks: editedProduct.remarks,
            amount: editedProduct.amount,
			measureType: editedProduct.measureType,
        }
        const res = await httpService.put(ENDPOINT, body);
        errorService.updateError({ msg: 'המוצר עודכן', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(newProduct) {
    try {

        const body = {
            name: newProduct.title,
            type: newProduct.type,
            proteins: +newProduct.numProtein,
            carbs: +newProduct.numCarbohydrate,
            fats: +newProduct.numFat,
            calories: +newProduct.numCalorie,
            sodiums: +newProduct.numSodium,
            fibers: +newProduct.numFiber,
            isFavorite: newProduct.isFavorite,
            remarks: newProduct.remarks,
            customAmount: newProduct.customAmount,
            amount: newProduct.amount,
			measureType: newProduct.measureType,
        }
        const res = await httpService.post(ENDPOINT, body);
        errorService.updateError({ msg: 'מוצר נוסף', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getById(product_id) {
    const res = await httpService.get(ENDPOINT + `/${product_id}`);
    console.log(res);
    const { id, name, type, calories, carbs, fats, proteins, fibers, remarks, sodiums, customAmount, amount, measureType } = res.data;
    const product = {
        id,
        title: name,
        type,
        numCalorie: calories,
        numCarbohydrate: carbs,
        numFat: fats,
        numProtein: proteins,
        numFiber: fibers,
        remarks,
        numSodium: sodiums,
        customAmount: customAmount,
		amount: amount,
		measureType: measureType

    }
    return product;
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
