import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";

export default function PermissionUserGuard(props) {

    const navigate = useNavigate();
    const [isUserPermission, setIsUserPermissions] = useState(authService.getPermissions().some(prem=>prem==='ManageUsers'));

    useEffect(() => {
        if(!isUserPermission){
            navigate('/')
        } 
    }, [isUserPermission])
    




    return (
        isUserPermission &&
        <>
            {props.children}
        </>
    )
}