import { useEffect, useState } from "react";
import { productService } from "../../services/productService";
import { utilService } from "../../services/utilService";
import SbsIcons from "../SbsIcons";
import SbsTable from "../SbsTable";
import FilterByRemarksPopup from "./FilterByRemarksPopup";

const remarksMap = {
  Vegan: "טבעוני",
  Vegetarian: "צמחוני",
  Pregnancy: "היריון",
  Lactose: "לקטוז",
  Peanuts: "בוטנים",
  Nuts: "אגוזים",
  Eggs: "ביצים",
  Fish: "דגים",
  Sesame: "סומסום",
  Soya: "סויה",
};

export default function AddProductPopup({
  onAddProduct,
  isAddProductOpen,
  setIsAddProductOpen,
  columnMap,
  productTypesHebrew,
  mealProducts,
}) {
  const [allProducts, setAllProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  console.log("filterProducts", filterProducts);
  const [filterRemarks, setFilterRemarks] = useState([]);
  const [search, setSearch] = useState("");
  const [productChoosenTypes, setProductChoosenTypes] = useState([]);
  const [isFilterByRemarksOpen, setIsFilterByRemarksOpen] = useState(false);
  var searchTimeOut = null;

  useEffect(() => {
    updateAllProducts();
  }, []);

  useEffect(() => {
    if (searchTimeOut) clearTimeout(searchTimeOut);
    searchTimeOut = setTimeout(() => {
      updateFilterProducts();
    }, 500);
  }, [search, allProducts, productChoosenTypes, mealProducts, filterRemarks]);

  const updateAllProducts = async () => {
    const products = await productService.get();
    setAllProducts(products);
  };

  const updateFilterProducts = async () => {
    console.log(mealProducts);
    let filteredProducts = allProducts
      .filter((product) => product.title.includes(search))
      .filter((product) => {
        if (filterRemarks.length) {
          return filterRemarks.every((remark) =>
            product.remarks.includes(remark)
          );
        }
        return true;
      })
      .map((product) => {
        return {
          ...product,
          protein: "asd",
          isInMeal: mealProducts.some(
            (mealProduct) => mealProduct.id === product.id
          ) ? (
            <span style={{ color: "#4BB543" }}>✓</span>
          ) : (
            <span style={{ color: "#8B0000" }}>✕</span>
          ),
          isIncludeInMeal: mealProducts.some(
            (mealProduct) => mealProduct.id === product.id
          ),
        };
      })
      .sort((product1, product2) => product1.type - product2.type);
    if (productChoosenTypes.length) {
      filteredProducts = filteredProducts.filter((product) =>
        productChoosenTypes.some((type) => type === product.type)
      );
    }
    setFilterProducts(filteredProducts);
  };

  const onOptionAddProduct = (id) => {
    const addedProduct = allProducts.find((product) => product.id == id);
    if (addedProduct) onAddProduct({ ...addedProduct });
    // setIsAddProductOpen(false);
  };

  const onChangeInput = (ev, product) => {};

  console.log("filterProducts", filterProducts);
  return (
    <div
      onClick={() => setIsAddProductOpen(false)}
      className={
        isAddProductOpen
          ? "sbs-popup-container open"
          : "sbs-popup-container close"
      }
    >
      <div
        onClick={(ev) => utilService.stopPropagation(ev)}
        className="sbs-popup"
      >
        <div className="popup-header">
          <span>הוסף מוצר</span>
          <div
            className="close-popup"
            onClick={() => setIsAddProductOpen(false)}
          >
            <SbsIcons icon="close"></SbsIcons>
          </div>
        </div>
        <div className="popup-body flex column gap-1">
          <div className="flex gap-1">
            <input
              className="sbs-input"
              type="text"
              value={search}
              onChange={({ target }) => {
                setSearch(target.value);
              }}
              placeholder="חפש מוצר"
            />

            {Object.keys(productTypesHebrew).map((type) => (
              <button
                key={type}
                className={
                  productChoosenTypes.some(
                    (choosenType) => choosenType === type
                  )
                    ? "button-primary"
                    : "button-secondary"
                }
                onClick={() => {
                  setProductChoosenTypes(
                    productChoosenTypes.some(
                      (choosenType) => choosenType === type
                    )
                      ? productChoosenTypes.filter(
                          (choosenType) => choosenType !== type
                        )
                      : [...productChoosenTypes, type]
                  );
                }}
              >
                {productTypesHebrew[type]}
              </button>
            ))}
            <button
              className={
                filterRemarks.length ? "button-primary" : "button-secondary"
              }
              onClick={() => {
                setIsFilterByRemarksOpen(true);
              }}
            >
              {filterRemarks.length
                ? `סינון לפי הערות (${filterRemarks.length})`
                : "סינון לפי הערות"}
            </button>
          </div>
          <SbsTable
            columnMap={{
              isInMeal: "זה בארוחה",
              numGrams: "כמות",
              title: "מוצר",
              customAmount: "כמות טקסט חופשי",
            }}
            isHaveOptions={true}
            metaData={filterProducts.filter(
              (product) => !product.isIncludeInMeal
            )}
            options={[{ icon: "add", cb: onOptionAddProduct }]}
          />
        </div>
        <div className="popup-footer">
          {/* <button className='button-secondary' onClick={() => setIsAddProductOpen(false)} >לא</button> */}
        </div>
      </div>
      <FilterByRemarksPopup
        isOpen={isFilterByRemarksOpen}
        setIsOpen={setIsFilterByRemarksOpen}
        selectedRemarks={filterRemarks}
        setSelectedRemarks={setFilterRemarks}
      />
    </div>
  );
}
