import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { utilService } from "../../../services/utilService";

export default function YoutubePopup({ askShowYoutube, setAskOpenYoutube }) {
    const [isOpen, setIsOpen] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');

    const updateIframeUrl = () => {
        if (askShowYoutube.video) {
            const isList = askShowYoutube.video.includes('list=');
            if (isList) setIframeUrl(`https://www.youtube.com/embed?listType=playlist&list=${askShowYoutube.video.split('list=')[1]}`);
            else setIframeUrl(`https://www.youtube.com/embed/${askShowYoutube.video.split('watch?v=')[1]}`);
        }
    }


    useEffect(() => {
        if (askShowYoutube) {
            setIsOpen(true);
            updateIframeUrl();
        }
        else setIsOpen(false);
    }, [askShowYoutube])

    return (
        <div onClick={() => setAskOpenYoutube(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>{askShowYoutube?.title}</span>
                    <div className='close-popup' onClick={() => setAskOpenYoutube(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <div className="iframe-container">
                        <iframe className="responsive-iframe" src={iframeUrl} allowFullScreen></iframe>
                    </div>
                </div>
                <div className='popup-footer'>
                    <button className='button-secondary' onClick={() => setAskOpenYoutube(null)} >סגור</button>
                </div>
            </div>
        </div >
    )
}