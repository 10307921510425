import React, { useEffect, useState } from "react";
import { errorService } from "../../services/errorService";
import { productService } from "../../services/productService";
import SbsIcons from "../SbsIcons";
import AddProductPopup from "./AddProductPopup";
import LoadMealPopup from "./LoadMealPopup";

export const MeasureTypes = [
  { value: "Grams", label: "גרם" },
  { value: "Cups", label: "כוסות" },
  { value: "Spoon", label: "כפיות" },
  { value: "Units", label: "יחידות" },
];

export default function MealCreator({ meal, setMeal, isEditable }) {
  const [mealProductsMap, setMealProductsMap] = useState({});
  const [sumProduct, setSumProduct] = useState(productService.getEmpty());
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [isLoadMealOpen, setIsLoadMealOpen] = useState(false);
  const columnMap = {
    title: "שם המוצר",
    // numProtein: "חלבונים",
    // numCarbohydrate: "פחמימות",
    // numFat: "שומנים",
  };
  console.log('mealProductsMap', mealProductsMap)
  const productTypesHebrew = {
    Carbohydrate: "פחמימה",
    Protein: "חלבון",
    Fat: "שומן",
  };

  useEffect(() => {
    const productMap = meal.products.reduce((acc, product) => {
      if (acc[product.type])
        acc[product.type].push({
          ...product,
          numProtein: product.type === "Protein" ? 1 : 0,
          numCarbohydrate: product.type === "Carbohydrate" ? 1 : 0,
          numFat: product.type === "Fat" ? 1 : 0,
        });
      else
        acc[product.type] = [
          {
            ...product,
            numProtein: product.type === "Protein" ? 1 : 0,
            numCarbohydrate: product.type === "Carbohydrate" ? 1 : 0,
            numFat: product.type === "Fat" ? 1 : 0,
          },
        ];
      return acc;
    }, {});
    console.log('meal.products', meal.products);
    setMealProductsMap(productMap);
    updateSumProduct();
  }, [meal]);

  const onAddProduct = (product) => {
    errorService.updateError({
      msg: "פריט נוסף לתפריט",
      type: "success",
      time: Date.now(),
    });
    setMeal((meal) => ({ ...meal, products: [...meal.products, product] }));
  };

  const updateSumProduct = () => {
    const newSumProduct = meal.products.reduce((acc, product) => {
      switch (product.type) {
        case "Protein":
          acc.numProtein += 1;
          break;
        case "Carbohydrate":
          acc.numCarbohydrate += 1;
          break;
        case "Fat":
          acc.numFat += 1;
          break;
      }
      return acc;
    }, productService.getEmpty());
    setSumProduct(newSumProduct);
  };

  const updateProduct = (id, key, value) => {
    const newMealProducts = [...meal.products];
    const index = newMealProducts.findIndex((p) => p.id === id);
    if (index === -1) return;
    console.log("newMealProducts", newMealProducts[index]);
    newMealProducts[index][key] = value;
    setMeal({ ...meal, products: newMealProducts });
  };

  const removeProduct = (productId) => {
    const mealProductIndex = meal.products.findIndex(
      (product) => product.id === productId
    );
    const newProducts = [...meal.products].filter(
      (_, idx) => idx !== mealProductIndex
    );
    setMeal({ ...meal, products: newProducts });
  };

  const loadMeal = (loadedMeal) => {
    setMeal({ ...loadedMeal, title: loadedMeal.title + " העתק", id: "" });
  };

  console.log(mealProductsMap);

  return (
    <div className="meal-creator flex column gap-1 align-start">
      {isEditable && (
        <div className="flex gap-1">
          <button
            onClick={() => setIsAddProductOpen(true)}
            className="button-primary"
          >
            הוסף מוצר
          </button>
          <button
            onClick={() => setIsLoadMealOpen(true)}
            className="button-secondary flex gap-1 align-center"
          >
            טען ארוחה
            <SbsIcons icon="load" />
          </button>
        </div>
      )}
      <table className="sbs-table">
        <tbody>
          <tr>
            {Object.values(columnMap).map((columnName, idx) => (
              <th key={idx}>{columnName}</th>
            ))}
            <th>כמות</th>
            <th>כמות סה"כ</th>
            {isEditable && <th>אופציות</th>}
          </tr>

          {Object.keys(mealProductsMap).map((type) => (
            <React.Fragment key={type}>
              <tr>
                <td colSpan={6} className="table-colspan">
                  {productTypesHebrew[type]}
                </td>
              </tr>
              {mealProductsMap[type].map((product, idx) => {
                return (
                  <tr key={idx}>
                    {Object.keys(columnMap).map((key, idx) => (
                      <td key={key}>{product[key]}</td>
                    ))}
                    <td>
                      <select
                        className="sbs-select"
                        value={product.fixedAmount || product.amount}
                        onChange={({ target }) =>
                          updateProduct(product.id, "fixedAmount", target.value)
                        }
                        disabled={!isEditable}
                      >
                        {[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {product.amount} {product.customAmount}
                    </td>
                    {isEditable && (
                      <td>
                        <button
                          className="button-primary"
                          onClick={() => removeProduct(product.id)}
                        >
                          <SbsIcons icon="delete"></SbsIcons>
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </React.Fragment>
          ))}

          <tr>
            <td colSpan={6} className="table-colspan">
              סך הכל
            </td>
          </tr>
          <tr>
            {Object.keys(columnMap).map((key, idx) => {
              const strings = Object.keys(mealProductsMap).map((type) => {
                const heType = productTypesHebrew[type];
                const products = mealProductsMap[type];

                // calc sum of fixedAmount
                const sum = products.reduce((acc, product) => {
                  return acc + (parseFloat(product.fixedAmount) || 0.5);
                }, 0);

                return `${sum} יחידות ${heType}`;
              });
              return <td key={key}>{strings.join(", ")}</td>;
            })}
          </tr>
        </tbody>
      </table>

      <AddProductPopup
        isAddProductOpen={isAddProductOpen}
        setIsAddProductOpen={setIsAddProductOpen}
        onAddProduct={onAddProduct}
        columnMap={columnMap}
        productTypesHebrew={productTypesHebrew}
        mealProducts={meal.products}
      />

      <LoadMealPopup
        isLoadMealOpen={isLoadMealOpen}
        setIsLoadMealOpen={setIsLoadMealOpen}
        loadMeal={loadMeal}
      />
    </div>
  );
}
