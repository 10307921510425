import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";

export default function PermissionTrainGuard(props) {

    const navigate = useNavigate();
    const [isTrainPermission, setIsTrainPermissions] = useState(authService.getPermissions().some(prem=>prem==='ManageTrains'));

    useEffect(() => {
        if(!isTrainPermission){
            navigate('/')
        } 
    }, [isTrainPermission])
    




    return (
        isTrainPermission &&
        <>
            {props.children}
        </>
    )
}