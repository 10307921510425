import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { motivationalSentenceService } from "../../../services/motivationalSentenceService";
import { paymentService } from "../../../services/paymentService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditPayment, askEditPayment, setAskEditPayment }) {

    const [isOpen, setIsOpen] = useState(false);
    const [newPayment, setNewPayment] = useState(paymentService.getEmpty());
    const [datePickerDate, setDatePickerDate] = useState(utilService.timestampToDatePickerTime(Date.now()));

    useEffect(() => {
        if (askEditPayment) {
            setNewPayment({ ...askEditPayment });
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditPayment])

    useEffect(() => {
        setDatePickerDate(utilService.timestampToDatePickerTime(newPayment.date));
    }, [newPayment])

    useEffect(() => {
        const timestamp = utilService.datePickerTimeToTimestamp(datePickerDate)
        if (timestamp !== newPayment.date) setNewPayment(payment => ({ ...payment, date: timestamp }))
    }, [datePickerDate])




    return (
        <div onClick={() => setAskEditPayment(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    {
                        askEditPayment?.id ? (
                            <span>ערוך את התשלום</span>
                        ) : (
                            <span>צור תשלום חדש</span>
                        )

                    }
                    <div className='close-popup' onClick={() => setAskEditPayment(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <div className="payments-inputs" >
                        <input
                            className="sbs-input"
                            type="date"
                            onKeyDown={(ev) => { ev.preventDefault() }}
                            value={datePickerDate}
                            onChange={({ target }) => setDatePickerDate(target.value)}
                        />

                        <select
                            className="sbs-select"
                            value={newPayment.type}
                            onChange={({ target }) => setNewPayment(payment => ({ ...payment, type: target.value }))}
                        >
                            {/* <option value="">בחר צורת תשלום</option> */}
                            <option value="Cash">מזומן</option>
                            <option value="Check">צ'ק</option>
                            <option value="CreditCard">כרטיס אשראי</option>
                            <option value="BankTransfer">העברה בנקאית</option>
                            <option value="Bit">ביט</option>
                        </select>

                        <input
                            className="sbs-input"
                            type="number"
                            value={newPayment.amount}
                            min={0}
                            onChange={({ target }) => { setNewPayment(payment => ({ ...payment, amount: target.value })) }}
                            placeholder="סכום תשלום"
                        />

                        <textarea
                            className="sbs-input"
                            type="text"
                            value={newPayment.description}
                            onChange={({ target }) => { setNewPayment(payment => ({ ...payment, description: target.value })) }}
                            placeholder="תיאור"
                        />
                    </div>
                </div>
                <div className='popup-footer'>
                    <button className='button-primary' disabled={+newPayment.amount <= 0} onClick={() => editAskedEditPayment(newPayment)} >שמירה</button>
                    {/* <button className='button-secondary' onClick={() => setAskEditPayment(null)} >לא</button> */}
                </div>
            </div>
        </div >
    )
}