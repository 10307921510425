import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { exerciseService } from "../../../services/exerciseService";
import { planService } from "../../../services/planService";
import { utilService } from "../../../services/utilService";
import ExerciseTypesPopup from "./ExerciseTypesPopup";
import LoadTemplatePopup from "./LoadTemplatePopup";

export default function TrainsCreation({
  plan,
  setPlan,
  nextStep,
  customer,
  disableNextStep,
  onChangesSave,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const onNewChangesSave = async () => {
    setIsLoading(true);
    await onChangesSave();
    setIsLoading(false);
    setIsSaved(true);
  };

  const exerciseTypes = exerciseService.getTypes();
  const [openedExerciseTypesTrainId, setOpenedExerciseTypesTrainId] =
    useState("");
  const [isLoadTemlate, setIsLoadTemplate] = useState(false);

  const deleteTrain = (trainIdx) => {
    setPlan((plan) => ({
      ...plan,
      trains: plan.trains.filter((_, idx) => idx != trainIdx),
    }));
  };

  const updatePlanTrainTitle = (title, trainIdx) => {
    setPlan((plan) => {
      const trains = plan.trains.map((train, idx) =>
        idx === trainIdx ? { ...train, title } : train
      );
      return { ...plan, trains };
    });
  };

  const toggleExerciseType = (exerciseType) => {
    let updateExerciseTypesMap = {
      ...plan.trains.find((train) => train.id === openedExerciseTypesTrainId)
        .exerciseTypesMap,
    };
    let updateExeciseTypesOrder = [
      ...plan.trains.find((train) => train.id === openedExerciseTypesTrainId)
        .exerciseTypesOrder,
    ];
    if (
      updateExeciseTypesOrder.find(
        (execiseTypeItem) => execiseTypeItem === exerciseType
      )
    ) {
      delete updateExerciseTypesMap[exerciseType];
      updateExeciseTypesOrder = updateExeciseTypesOrder.filter(
        (execiseTypeItem) => execiseTypeItem !== exerciseType
      );
    } else {
      updateExerciseTypesMap[exerciseType] =
        planService.getEmptyExerciseType(exerciseType);
      updateExeciseTypesOrder = [...updateExeciseTypesOrder, exerciseType];
    }
    const newTrain = {
      ...plan.trains.find((train) => train.id === openedExerciseTypesTrainId),
      exerciseTypesMap: updateExerciseTypesMap,
      exerciseTypesOrder: updateExeciseTypesOrder,
    };
    setPlan({
      ...plan,
      trains: plan.trains.map((train) =>
        train.id === openedExerciseTypesTrainId ? newTrain : train
      ),
    });

    // let updateExerciseTypesKeys = { ...plan.trains[trainIdx].exerciseTypesMap }
    // let exerciseTypesOrder = [];
    // const trainExeciseTypes = Object.keys(plan.trains[trainIdx].exerciseTypesMap);
    // if (trainExeciseTypes.some(exerciseTypeKey => exerciseTypeKey === exerciseType)) {
    //     delete updateExerciseTypesKeys[exerciseType];
    //     exerciseTypesOrder = plan.trains[trainIdx].exerciseTypesOrder.filter(exerciseTypeItem=>exerciseTypeItem.id!=exerciseType)
    // } else {
    //     updateExerciseTypesKeys[exerciseType] = planService.getEmptyExerciseType(exerciseType);
    //     exerciseTypesOrder = [...plan.trains[trainIdx].exerciseTypesOrder,exerciseType]
    // }
    // setPlan(plan => {
    //     const trains = plan.trains.map((train, idx) => idx === trainIdx ? { ...train, exerciseTypesMap: updateExerciseTypesKeys } : train);
    //     return { ...plan, trains }
    // })
  };

  const addTrain = () => {
    setPlan((plan) => ({
      ...plan,
      trains: [...plan.trains, planService.getEmptyTrain()],
    }));
  };

  console.log("train", plan.trains);

  return (
    <div className="trains-view-plan flex column gap-0-5">
      {customer.id && (
        <>
          <span className="sbs-title">
            לקוח: {customer.firstName} {customer.lastName}
          </span>
          <div className="flex column gap-0-5">
            <div className="flex column align-start">
              <span>תאריך התחלת תוכנית</span>
              <input
                className="sbs-input"
                type="date"
                onKeyDown={(ev) => {
                  ev.preventDefault();
                }}
                value={utilService.timestampToDatePickerTime(plan.startDate)}
                onChange={({ target }) => {
                  setPlan((plan) => ({
                    ...plan,
                    startDate: utilService.datePickerTimeToTimestamp(
                      target.value
                    ),
                  }));
                }}
                placeholder="שם משתמש"
              />
            </div>
            <div className="flex column align-start">
              <span>תאריך סיום תוכנית</span>
              <input
                className="sbs-input"
                onKeyDown={(ev) => {
                  ev.preventDefault();
                }}
                type="date"
                value={utilService.timestampToDatePickerTime(plan.endDate)}
                onChange={({ target }) => {
                  setPlan((plan) => ({
                    ...plan,
                    endDate: utilService.datePickerTimeToTimestamp(
                      target.value
                    ),
                  }));
                }}
                placeholder="שם משתמש"
              />
            </div>
          </div>
        </>
      )}

      {!customer.id && (
        <div className="flex column align-start">
          <span>שם התבנית</span>
          <input
            className="sbs-input"
            type="text"
            value={plan.title}
            onChange={({ target }) => setPlan({ ...plan, title: target.value })}
            placeholder="שם התבנית"
          />
        </div>
      )}

      <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
        <div className="flex column align-start">
          <span>זמן אירובי שבועי בדקות</span>
          <input
            className="sbs-input"
            type="number"
            value={plan.weeklyAerobicInMinutes}
            onChange={({ target }) =>
              setPlan({ ...plan, weeklyAerobicInMinutes: +target.value })
            }
            placeholder="כמות דקות"
          />
        </div>
        <div className="flex column align-start">
          <span>כמות אימוני אירובי בשבוע</span>
          <input
            className="sbs-input"
            type="number"
            value={plan.weeklyAerobicNum}
            onChange={({ target }) =>
              setPlan({ ...plan, weeklyAerobicNum: +target.value })
            }
            placeholder="כמות פעמים"
          />
        </div>
      </div>

      <div>
        <span>כמות אימוני כוח בשבוע</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16,
            alignItems: "center",
          }}
        >
          <div className="flex column align-start">
            <input
              className="sbs-input"
              type="number"
              value={plan.weeklyWorkouts}
              onChange={({ target }) =>
                setPlan({ ...plan, weeklyWorkouts: +target.value })
              }
              placeholder="כמות"
              style={{
                width: 65,
              }}
            />
          </div>
          <span>-</span>
          <div className="flex column align-start">
            <input
              className="sbs-input"
              type="number"
              value={plan.weeklyWorkoutsMax}
              onChange={({ target }) =>
                setPlan({ ...plan, weeklyWorkoutsMax: +target.value })
              }
              placeholder="כמות"
              style={{
                width: 65,
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <span>צור אימונים</span>
        <div className="flex column gap-1">
          {plan.trains.map((train, idx) => (
            <div key={idx} className="train flex gap-1">
              <input
                className="sbs-input"
                type="text"
                value={train.title}
                onChange={({ target }) => {
                  updatePlanTrainTitle(target.value, idx);
                }}
                placeholder="שם האימון"
              />

              {/* <button
                // onClick={() => setOpenedExerciseTypesTrainId(train.id)}
                disabled
                className={
                  train.exerciseTypesOrder.length
                    ? "button-secondary"
                    : "button-primary"
                }
              >
                {train.exerciseTypesOrder.length
                  ? train.exerciseTypesOrder
                      .map((type) => exerciseService.getTypeHebrewMap()[type])
                      .join(", ")
                  : "הוסף סוגי תרגילים"}
                כללי
              </button> */}
              <div
                onClick={() => {
                  deleteTrain(idx);
                }}
                className="flex-center pointer white-svg"
              >
                <SbsIcons icon={"close"} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <ExerciseTypesPopup
        openedExerciseTypesTrainId={openedExerciseTypesTrainId}
        setOpenedExerciseTypesTrainId={setOpenedExerciseTypesTrainId}
        toggleExerciseType={toggleExerciseType}
        plan={plan}
        exerciseTypes={exerciseTypes}
      />

      <LoadTemplatePopup
        isLoadTemplate={isLoadTemlate}
        setIsLoadTemplate={setIsLoadTemplate}
        setPlan={setPlan}
      />

      <div className="flex justify-space-between">
        <div className="flex gap-0-5">
          <button className="button-primary" onClick={() => addTrain()}>
            הוסף אימון
          </button>
        </div>
        <div className="flex gap-0-5">
          <button
            className="button-secondary flex gap-0-5 align-center"
            onClick={() => setIsLoadTemplate(true)}
          >
            <span>טען תבנית</span>
            <SbsIcons icon="load" />
          </button>
        </div>
      </div>
      <div className="flex justify-space-between">
        <div />
        <button
          disabled={disableNextStep}
          className="button-primary"
          onClick={() => nextStep()}
        >
          {disableNextStep ? disableNextStep : "עבור לעריכת האימונים"}
        </button>
      </div>

      {plan.id && (
        <button
          disabled={disableNextStep || isLoading}
          onClick={onNewChangesSave}
          className="button-primary mt-1"
        >
          שמור שינויים
        </button>
      )}

      {disableNextStep && (
        <span style={{ color: "#ff0000" }}>{disableNextStep}</span>
      )}
      {/* <div className={`data-situation ${isSaved ? 'updated' : 'not-updated'}`}>
                    <SbsIcons icon={isLoading ? 'update' : 'check'} />
                </div> */}
    </div>
  );
}
