import { useEffect, useState } from 'react';
import SbsIcons from '../../components/SbsIcons';
import SbsTable from '../../components/SbsTable';
import { aerobicService } from '../../services/aerobicService';
import { customerService } from '../../services/customerService';
import AskDeletePopup from './components/AskDeletePopup';
import AskEditPopup from './components/AskEditPopup';
import ImagePopup from './components/ImagePopup';
import YoutubePopup from './components/YoutubePopup';

var searchTimeOut = null;
export default function ManageAerobics(props) {

    const [allAerobics, setAllAerobics] = useState([]);
    const [filterAerobics, setFilterAerobics] = useState([]);
    const [search, setSearch] = useState('');
    const [askDeleteAerobic, setAskDeleteAerobic] = useState(null);
    const [askEditAerobic, setAskEditAerobic] = useState(null);
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);

	const [askEditImage, setAskEditImage] = useState(null);
    const [askShowYoutube, setAskOpenYoutube] = useState(null);

    useEffect(() => {
        document.title = "Step By Step - Aerobics";
    }, [])

    useEffect(() => {
        updateAllAerobics();
    }, [])

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterAerobics();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allAerobics])

    const updateAllAerobics = async () => {
        setIsTableLoad(true);
        const aerobics = await aerobicService.get();
        setAllAerobics(aerobics);
    }

    const updateFilterAerobics = async () => {
        setIsTableLoad(false);
        const filterAerobics = allAerobics
            .filter(aerobic => aerobic.name.includes(search))
        setFilterAerobics(filterAerobics);
    }

    const onOptionEditAerobic = (id) => {
        setAskEditAerobic(allAerobics.find(aerobic => aerobic.id === id));
    }

    const onOptionAskDeleteAerobic = (id) => {
        setAskDeleteAerobic(allAerobics.find(aerobic => aerobic.id === id));
    }

    const editAskedEditAerobic = async (name, description, video) => {
        if (!askEditAerobic.id) addAskedEditAerobic(name, description);
        else {
            const editedAskEditAerobic = { ...askEditAerobic, name, description, video }
            await aerobicService.edit(editedAskEditAerobic);
            updateAllAerobics()
            setAskEditAerobic(null);
        }
    }

    const addAskedEditAerobic = async (name, description, video) => {
        const newAerobic = { name, description, video }
        await aerobicService.add(newAerobic);
        updateAllAerobics();
        setAskEditAerobic(null);
    }

    const deleteAskedDeletedAerobic = async () => {
        await aerobicService.remove(askDeleteAerobic.id);
        updateAllAerobics();
        setAskDeleteAerobic(null);
    }

	const onOptionOpenYoutube = (id) => {
        setAskOpenYoutube(allAerobics.find(aerobics => aerobics.id === id));
    }
    const onOptionOpenImage = (id) => {
        setAskEditImage(allAerobics.find(aerobics => aerobics.id === id));
    }

    return (
        <div className='main-container page'>

            <div className='manage-aerobics-page'>
                <div className='header'>
                    <span className='sbs-title'>ניהול פעולות אירובי</span>
                    <span className='sbs-subtitle'>סה"כ {allAerobics.length} פעולות אירובי</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש פעולות אירובי"
                />

                <SbsTable
                    columnMap={{ "name": "פעילות", "description": 'תיאור' }}
                    isHaveOptions={true}
                    isLoad={isTableLoad}
                    metaData={filterAerobics}
                    options={[
                        { icon: 'delete', cb: onOptionAskDeleteAerobic },
                        { icon: 'edit', cb: onOptionEditAerobic },
						{ icon: 'youtube', cb: onOptionOpenYoutube },
                        { icon: 'image', cb: onOptionOpenImage }
                    ]}
                />

                <AskDeletePopup
                    askDeleteAerobic={askDeleteAerobic}
                    deleteAskedDeletedAerobic={deleteAskedDeletedAerobic}
                    setAskDeleteAerobic={setAskDeleteAerobic}
                />

                <AskEditPopup
                    askEditAerobic={askEditAerobic}
                    editAskedEditAerobic={editAskedEditAerobic}
                    setAskEditAerobic={setAskEditAerobic}
                />

				<YoutubePopup
                    askShowYoutube={askShowYoutube}
                    setAskOpenYoutube={setAskOpenYoutube}
                />

                <ImagePopup
                    askEditImage={askEditImage}
                    setAskEditImage={setAskEditImage}
                    updateExercises={updateAllAerobics}
                />

                <button onClick={() => { setAskEditAerobic({ name: "", description: "" }) }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>

            </div>
        </div>
    )
}