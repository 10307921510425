import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { employeeService } from "../../services/employeeService";
import { utilService } from "../../services/utilService";
import { workClockService } from "../../services/workClockService";

var searchTimeOut = null;
export default function ManageWorkClocks({ }) {

    const [allWorks, setAllWorks] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [filterEmployees, setFilterEmployees] = useState([]);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);

    useEffect(() => {
        document.title = "Step By Step - Workclocks";
    }, [])

    useEffect(() => {
        updateAllWorks();
        updateEmployees();
    }, [])

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterEmployee();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allWorks, allEmployees])

    const updateAllWorks = async () => {
        setIsTableLoad(true);
        const works = await workClockService.get();
        setAllWorks(works);
        setIsTableLoad(false);
    }

    const updateEmployees = async () => {
        const allEmployeesRes = await employeeService.get()
        setAllEmployees(allEmployeesRes)

    }

    const updateFilterEmployee = async () => {
        const currFilterEmployees = allEmployees
            .filter(employee => (
                employee.firstName + ' ' + employee.lastName.includes(search)
            ))
            .map(employee => {
                const fullName = (employee.firstName || employee.lastName) ?
                    employee.firstName + ' ' + employee.lastName :
                    employee.email

                return {
                    ...employee,
                    fullName: fullName,
                    sumWorks: sumEmployeeWorkThisMonth(employee.id)
                }
            })
        setFilterEmployees(currFilterEmployees)
    }

    const editCostumer = (id) => {
        navigate('/view-time-clock/' + id)
    }

    const sumEmployeeWorkThisMonth = (employeeId) => {
        const currDateShard = utilService.getDateShared();
        const sumMs = allWorks.reduce((acc, work) => {
            if (work.employeeId !== employeeId) return acc
            const workDateShard = utilService.getDateShared(work.from)
            if (workDateShard.month !== currDateShard.month || workDateShard.year !== currDateShard.year) return acc
            acc += work.to - work.from
            return acc
        }, 0)
        return utilService.msToHMMSS(sumMs)
    }

    return (
        <div className='main-container page'>
            <div className='manage-works-page'>
                <div className='header'>
                    <span className='sbs-title'>ניהול שעוני עבודה</span>
                    {/* <span className='sbs-subtitle'>סה"כ {allWorks.length} שעוני עבודה</span> */}
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש עובד"
                />

                <SbsTable
                    columnMap={{ "fullName": "שם מלא", "id": "מספר עובד", role: 'תפקיד', "sumWorks": "סך שעות החודש" }}
                    isHaveOptions={true}
                    isLoad={isTableLoad}
                    metaData={filterEmployees}
                    options={[
                        { icon: 'clock', cb: editCostumer },
                    ]}
                ></SbsTable>

                {/* <button onClick={() => { navigate("/view-work") }} className='sbs-flow-btn'><SbsIcons icon="clock" /></button> */}

            </div>
        </div>
    )
}