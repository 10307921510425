import { BehaviorSubject } from "rxjs";
import { errorService } from "./errorService";
import httpService from "./httpService";



let currToken = '';
let currPermissions = null;
const currPermissions$ = new BehaviorSubject([]);
const ENDPOINT = 'auth'


function getToken() {
    const storageToken = sessionStorage.getItem('token');
    if (!currToken && storageToken) currToken = storageToken
    return currToken
}

function getPermissions() {
    const storagePermissions = sessionStorage.getItem('permissions') ? sessionStorage.getItem('permissions').split(',') : [];
    if (!currPermissions && storagePermissions.length) currPermissions = storagePermissions
    currPermissions$.next(!!currPermissions ? currPermissions : [])
    return !!currPermissions ? currPermissions : []
}


function clearToken() {
    currToken = '';
    sessionStorage.setItem('token', '')
    currPermissions = [];
    sessionStorage.setItem('permissions', []);
}

async function login(identifier, password) {
    try {
        const res = await httpService.post(ENDPOINT + '/login', { identifier, password })
        console.log(res.data);
        _setToken(res.data.token);
        _setPermissions(res.data.info.type === 1 ?
            [
                'ManageWorkClocks',
                'ManageUsers',
                'ManageCustomers',
                'ManageNutrition',
                'ManageTasks',
                'ManageQuotes',
                'ManageGroups',
                'ManageTrains'
            ]
            :
            res.data.info.permissions
        );

    } catch {
        errorService.updateError({ msg: 'דואר אלקטרוני או סיסמה אינם נכונים', type: 'danger', time: Date.now() })
    }
}

function _setToken(token) {
    currToken = token;
    sessionStorage.setItem('token', token)
}

function _setPermissions(permissions) {
    currPermissions = permissions;
    sessionStorage.setItem('permissions', permissions)
    currPermissions$.next(permissions)
}

async function sendEmailRecoveryPassword(identifier) {

    try {
        const res = await httpService.put(ENDPOINT + '/recovery/' + identifier)
        errorService.updateError({ msg: 'נשלח אימות לדואר אלקטרוני', type: 'success', time: Date.now() })
    } catch (err) {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function recoveryPassword(token, newPassword) {
    console.log("🚀 ~ file: authService.js ~ line 81 ~ recoveryPassword ~ token, newPassword", token, newPassword)
    try {
        const res = await httpService.put(ENDPOINT + '/recovery', { token, newPassword })
        errorService.updateError({ msg: 'הסיסמא עודכנה', type: 'success', time: Date.now() })
    } catch (err) {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

export const authService = {
    getToken,
    clearToken,
    login,
    getPermissions,
    sendEmailRecoveryPassword,
    recoveryPassword,
    currPermissions$
}