import { async } from "rxjs";
import { errorService } from "./errorService";
import { globalVariable } from "./globalVariable";
import httpService from "./httpService";
import { utilService } from "./utilService";

export const planService = {
  // get,
  remove,
  // // getRandom,
  getEmpty,
  getEmptyTrain,
  getEmptyTrainExercise,
  getEmptyExerciseType,
  getByCustomerId,
  getById,
  edit,
  getCount,
  add,
  dowloandPdf,
};

const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
});

function getOrDamriData(nivTemplate) {
  console.log("hap");
  const { id, title, trains, creatorName, customerID } = nivTemplate;
  const template = {
    id: id + "",
    title,
    createBy: creatorName,
    customerId: customerID,
    trains: trains.map((train) => {
      const { id, title, trainCategories } = train;
      return {
        id: id + "",
        title,
        exerciseTypesMap: trainCategories
          .map((trainCategory) => {
            const { type, trainCategoryExercises } = trainCategory;
            return {
              id: type,
              title: type,
              execiseIds: trainCategoryExercises
                .sort(
                  (trainCategoryExercises, trainCategoryExercises2) =>
                    trainCategoryExercises.index - trainCategoryExercises2.index
                )
                .map(
                  (trainCategoryExercises) => "" + trainCategoryExercises.id
                ),
            };
          })
          .reduce((acc, trainCategory) => {
            acc[trainCategory.id] = trainCategory;
            return acc;
          }, {}),
        trainExercisesMap: trainCategories.reduce((acc, trainCategory) => {
          trainCategory.trainCategoryExercises.forEach(
            (trainCategoryExercise) => {
              const { restInSeconds } = trainCategoryExercise;
              acc[trainCategoryExercise.id] = {
                ...trainCategoryExercise,
                id: "" + trainCategoryExercise.id,
                exerciseID: "" + trainCategoryExercise.exerciseID,
                exerciseId: "" + trainCategoryExercise.exerciseID,
                execiseId: "" + trainCategoryExercise.exerciseID,
                rest: `${leadingZeroFormatter.format(
                  Math.floor(restInSeconds / 60)
                )}:${leadingZeroFormatter.format(restInSeconds % 60)}`,
              };
            }
          );
          return acc;
        }, {}),
        exerciseTypesOrder: trainCategories
          .sort(
            (trainCategory, trainCategory2) =>
              trainCategory.index - trainCategory2.index
          )
          .map((trainCategory) => trainCategory.type),
      };
    }),
  };
  console.log(template);
  return { ...nivTemplate, ...template };
}

function getEmpty() {
  return {
    title: "",
    id: "",
    customerId: "",
    weeklyWorkouts: 3,
    weeklyWorkoutsMax: 0,
    weeklyAerobicInMinutes: 0,
    weeklyAerobicNum: 0,
    trains: [getEmptyTrain()],
    startDate: Date.now(),
    endDate: Date.now() + 1000 * 60 * 60 * 24 * 30,
  };
}

function getEmptyTrain() {
  let updateExerciseTypesMap = {
    Chest: {
      execiseIds: [],
      id: "Chest",
      title: "Chest",
    },
    execiseIds: [],
    id: "Chest",
    title: "Chest",
  };

  let updateExeciseTypesOrder = ["Chest"];

  return {
    id: utilService.genId(),
    title: "",
    trainExercisesMap: {},
    exerciseTypesMap: updateExerciseTypesMap,
    exerciseTypesOrder: updateExeciseTypesOrder,
  };
}

function getEmptyTrainExercise() {
  return {
    id: utilService.genId(),
    execiseId: "",
    rest: "01:00",
    numSet: 3,
    isMax: false,
    numRepeatMin: 12,
	numRepeatMax: 0,
    type: "Normal",
    description: "",
    comment: "",
  };
}

const ENDPOINT = "training/plans";

function getEmptyExerciseType(type) {
  return {
    id: type,
    title: type,
    execiseIds: [],
  };
}

async function getByCustomerId(customerId) {
  // return plans.filter(plan => plan.customerId === customerId).map(plan => ({ ...plan }))
  const res = await httpService.get(ENDPOINT + `/customer/${customerId}`);
  console.log(res.data);
  return res.data;
}

async function getById(planId) {
  const res = await httpService.get(ENDPOINT + `/${planId}`);
  const plan = getOrDamriData(res.data);
  return plan;
}

async function remove(id) {
  try {
    const res = await httpService.delete(ENDPOINT + `/${id}`);
    errorService.updateError({
      msg: "תוכנית האימונים נמחקה",
      type: "warning",
      time: Date.now(),
    });
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function add(plan) {
  try {
    const postPlan = {
      ...plan,
      weeklyAerobicInMinutes: +plan.weeklyAerobicInMinutes,
      customerId: +plan.customerId,
      trains: plan.trains.map((train) => ({
        title: train.title,
        trainCategories: train.exerciseTypesOrder.map((type) => {
          return {
            type: train.exerciseTypesMap[type].title,
            TrainCategoryExercises: train.exerciseTypesMap[type].execiseIds.map(
              (execiseId) => {
                const rest = train.trainExercisesMap[execiseId].rest;
                const [minutes, seconds] = rest.split(":");
                const restTime = +minutes * 60 + +seconds;
                return {
                  exerciseId: train.trainExercisesMap[execiseId].execiseId
                    ? +train.trainExercisesMap[execiseId].execiseId
                    : +(+execiseId),
					numRepeatMin: train.trainExercisesMap[execiseId].numRepeatMin + "",
					numRepeatMax: train.trainExercisesMap[execiseId].numRepeatMax || 0 + "",
                  numSet: +train.trainExercisesMap[execiseId].numSet,
                  isMax: train.trainExercisesMap[execiseId].isMax,
                  type: train.trainExercisesMap[execiseId].type,
                  description: train.trainExercisesMap[execiseId].description,
                  comment: train.trainExercisesMap[execiseId].comment,
                  restInSeconds: restTime,
                  superSet: train.trainExercisesMap[execiseId].superSet
                    ? train.trainExercisesMap[
                        train.trainExercisesMap[execiseId].superSet
                      ]?.execiseId
                    : 0,
                };
              }
            ),
          };
        }),
      })),
    };
    delete postPlan.id;
    const res = await httpService.post(ENDPOINT, postPlan);
    errorService.updateError({
      msg: "תוכנית האימונים נוספה",
      type: "success",
      time: Date.now(),
    });
    return res.data;
  } catch (err) {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
    throw err;
  }
}

async function edit(plan) {
  try {
    const body = {
      ...plan,
      id: +plan.id,
      weeklyAerobicInMinutes: +plan.weeklyAerobicInMinutes,
      customerId: +plan.customerId,
      trains: plan.trains.map((train) => ({
        title: train.title,
        trainCategories: train.exerciseTypesOrder.map((type) => {
          return {
            type: train.exerciseTypesMap[type].title,
            TrainCategoryExercises: train.exerciseTypesMap[type].execiseIds.map(
              (execiseId) => {
                const rest = train.trainExercisesMap[execiseId].rest;
                const [minutes, seconds] = rest.split(":");
                const restTime = +minutes * 60 + +seconds;
                return {
                  exerciseId: +train.trainExercisesMap[execiseId].execiseId,
                  numRepeatMin: train.trainExercisesMap[execiseId].numRepeatMin + "",
				  numRepeatMax: train.trainExercisesMap[execiseId].numRepeatMax || 0 + "",
                  numSet: +train.trainExercisesMap[execiseId].numSet,
                  isMax: train.trainExercisesMap[execiseId].isMax,
                  type: train.trainExercisesMap[execiseId].type,
                  description: train.trainExercisesMap[execiseId].description,
                  comment: train.trainExercisesMap[execiseId].comment,
                  restInSeconds: restTime,
                  superSet: Math.floor(
                    train.trainExercisesMap[execiseId].superSet
                  ) || 0,
                };
              }
            ),
          };
        }),
      })),
    };
    const res = await httpService.put(ENDPOINT, body);
    errorService.updateError({
      msg: "תוכנית האימונים עודכנה",
      type: "success",
      time: Date.now(),
    });
    return res.data;
  } catch (err) {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
    throw err;
  }
}

async function getCount() {
  const res = await httpService.get(ENDPOINT + `/count`);
  const { count, countThisWeek } = res.data;
  return { all: count, lastWeek: countThisWeek };
}

async function dowloandPdf(planId) {
  const pdf = httpService.get(`${ENDPOINT}/pdf/${planId}`);
  window.open(`${globalVariable.BASE_URL}${ENDPOINT}/pdf/${planId}`, "_blank");
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
