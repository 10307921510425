import home from '../assets/svgs/Home.svg'
import employee from '../assets/svgs/Employes.svg'
import customer from '../assets/svgs/Customers.svg'
import training from '../assets/svgs/Training.svg'
import meals from '../assets/svgs/Meals.svg'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import * as IoIcons from 'react-icons/io';
import * as GrIcons from 'react-icons/gr';
import * as GiIcons from 'react-icons/gi';
import * as ImIcons from 'react-icons/im';
import * as BsIcons from 'react-icons/bs';
import * as FiIcons from 'react-icons/fi';
export default function SbsIcons({ icon }) {
    switch (icon) {
		case 'back':
			return <IoIcons.IoIosArrowForward />
        case 'home':
            return <img src={home}></img>
        case 'employee':
            return <img src={employee}></img>
        case 'customer':
            return <img src={customer}></img>
        case 'training2':
            return <img src={training}></img>
        case 'meals':
            return <img src={meals}></img>
        case 'delete':
            return <AiIcons.AiFillDelete />
        case 'edit':
            return <AiIcons.AiFillEdit />
        case 'add':
            return <IoIcons.IoIosAdd />
        case 'arrow-up':
            return <IoIcons.IoMdArrowDropup />
        case 'arrow-down':
            return <IoIcons.IoMdArrowDropdown />
        case 'close':
            return <GrIcons.GrClose />
        case 'personalInformation':
            return <AiIcons.AiOutlineUser />
        case 'training':
            return <GiIcons.GiWeightLiftingUp />
        case 'nutrition':
            return <MdIcons.MdOutlineFoodBank />
        case 'files':
        case 'paycheck':
            return <ImIcons.ImFilesEmpty />
        case 'permissionsManagment':
            return <MdIcons.MdOutlineManageAccounts />
        case 'employeeManagment':
            return <MdIcons.MdOutlineManageAccounts />
        case 'userManagment':
            return <MdIcons.MdOutlineManageAccounts />
        case 'paymentManagment':
            return <MdIcons.MdPayment />
        case 'check':
            return <BsIcons.BsCheckCircle />
        case 'update':
            return <GrIcons.GrUpdate />
        case 'youtube':
            return <FaIcons.FaYoutube />
        case 'load':
            return <AiIcons.AiOutlineCloudUpload />
        case 'users':
            return <FiIcons.FiUsers />
        case 'user':
            return <AiIcons.AiOutlineUser />
        case 'clock':
            return <BsIcons.BsClock />
        case 'date':
            return <BsIcons.BsCalendar2Date />
        case 'play':
            return <BsIcons.BsFillPlayFill />
        case 'pause':
            return <BsIcons.BsPauseFill />
        case 'logout':
            return <FiIcons.FiLogOut />
        case 'log':
            return <AiIcons.AiTwotoneCalendar />
        case 'pdf':
            return <AiIcons.AiOutlineFilePdf />
        case 'image':
            return <BsIcons.BsImage />

        default:
            return <img src={employee}></img>
    }
}