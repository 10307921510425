import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const abdominalService = {
    get,
    remove,
    // getRandom,
    // getEmpty,
    edit,
    add,
	changeImage,
    getImage
}

const ENDPOINT = 'training/abs-exercises'

// function getEmpty() {
//     return {
//         "id": "",
//         "sentence": "",
//         "groups": "",
//     };
// }

async function get() {
    const response = await httpService.get(ENDPOINT)
    return response.data
}

async function remove(id) {
    try {

        const response = await httpService.delete(ENDPOINT + '/' + id)
        errorService.updateError({ msg: 'האימון נמחק', type: 'warning', time: Date.now() })
        return response.data
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(abdominal) {
    try {
        const response = await httpService.put(ENDPOINT, abdominal)
        errorService.updateError({ msg: 'האימון עודכן', type: 'success', time: Date.now() })
        return response.data
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(abdominal) {
    try {

        if (!abdominal.video) abdominal.video = 'https://www.youtube.com/watch?v=FyHo8EQWshc'
        const response = await httpService.post(ENDPOINT, abdominal)
        errorService.updateError({ msg: 'האימון נוסף', type: 'success', time: Date.now() })
        return response.data
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })

    }
}

async function changeImage(id, file) {
    try {
        const body = new FormData();
        body.append('image', file);
        body.append('id', id);
        const res = await httpService.post(`${ENDPOINT}/image`, body);
        errorService.updateError({ msg: 'תמונה עודכנה', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getImage(name) {
    try {
        if (!name) return null;
        const res = await httpService.get(`general/getBase64/${name.replace('/api/general/get_media_file?filename=', '/')}`);
        console.log(res);
        return res.data

    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
