import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { customerService } from "../../services/customerService";
import { utilService } from "../../services/utilService";

var searchTimeOut = null;
export default function ManageCustomers(props) {
  const [allCustomers, setAllCustomers] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [isTableLoad, setIsTableLoad] = useState(false);
  const [isSearchLoad, setIsSearchLoad] = useState(false);
  const [filterBy, setFilterBy] = useState("all");

  const [editFinishDate, setEditFinishDate] = useState(null);
  const [editFinishDateValue, setEditFinishDateValue] = useState(null);
  console.log("editFinishDate", editFinishDate);
  useEffect(() => {
    document.title = "Step By Step - Customers";
  }, []);

  useEffect(() => {
    updateAllCustomers();
  }, []);

  useEffect(() => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
      setIsSearchLoad(true);
    }
    searchTimeOut = setTimeout(() => {
      updateFilterCustomers();
      setIsSearchLoad(false);
    }, 500);
  }, [search, allCustomers, filterBy]);

  const updateAllCustomers = async () => {
    setIsTableLoad(true);
    const customers = await customerService.get();
    setAllCustomers(
      customers.map((customer) => ({
        ...customer,
        lastDateForPlansDate: customer.lastDateForPlans
          ? new Date(customer.lastDateForPlans).toLocaleDateString()
          : "",
      }))
    );
  };

  const updateFilterCustomers = async () => {
    setIsTableLoad(false);
    const filterCustomers = allCustomers
      .map((customer) => ({
        ...customer,
        status: customer.isActive
          ? customer.isSuspended
            ? "מושהה"
            : "פעיל"
          : "לא פעיל",
        type: customer.isPremium ? "פרימיום" : "בסיסי",
        fullName: customer.firstName + " " + customer.lastName,
      }))
      .filter((customer) => {
        console.log(customer);
        if (filterBy === "all") return customer.fullName.includes(search);
        else if (filterBy === "active")
          return customer.fullName.includes(search) && customer.isActive;
        else if (filterBy === "inactive")
          return customer.fullName.includes(search) && !customer.isActive;
        else if (filterBy === "suspense")
          return customer.fullName.includes(search) && customer.isSuspended;
      });
    setFilterCustomers(filterCustomers);
  };

  const editCostumer = (id) => {
    navigate("/view-customer/" + id);
  };

  const editCostumerFinishDate = (id) => {
    setEditFinishDate(allCustomers.find((customer) => customer.id === id));
    setEditFinishDateValue(
      utilService.timestampToDatePickerTime(
        allCustomers.find((customer) => customer.id === id).lastDateForPlans
      )
    );
  };

  const handleChangeFinishDate = async () => {
    await customerService.changePlansEndDate(
      editFinishDate.id,
      utilService.datePickerTimeToTimestamp(editFinishDateValue)
    );
    setEditFinishDate(null);
    updateAllCustomers();
  };

  return (
    <div className="main-container page">
      <div className="manage-customers-page">
        <div className="header">
          <span className="sbs-title">ניהול לקוחות</span>
          <span className="sbs-subtitle">
            סה"כ {allCustomers.length} לקוחות
          </span>
        </div>

        <div className="flex gap-1">
          <input
            className={`sbs-input search ${isSearchLoad ? "load" : "no-load"}`}
            type="text"
            value={search}
            onChange={({ target }) => {
              setSearch(target.value);
            }}
            placeholder="חפש לקוח"
          />
          <select
            className="sbs-select"
            value={filterBy}
            onChange={({ target }) => setFilterBy(target.value)}
          >
            <option value={"all"}>הכל</option>
            <option value={"active"}>פעיל</option>
            <option value={"inactive"}>לא פעיל</option>
            <option value={"suspense"}>מושהה</option>
          </select>
        </div>

        <SbsTable
          columnMap={{
            fullName: "שם מלא",
            status: "סטטוס",
            phone: "טלפון",
            lastDateForPlansDate: "סיום תוכנית",
          }}
          isLoad={isTableLoad}
          isHaveOptions={true}
          metaData={filterCustomers}
          isHaveReorder={true}
          options={[
            { icon: "edit", cb: editCostumer },
            { icon: "clock", cb: editCostumerFinishDate },
          ]}
        />

        <div
          onClick={() => setEditFinishDate(false)}
          className={
            editFinishDate
              ? "sbs-popup-container open"
              : "sbs-popup-container close"
          }
        >
          <div
            onClick={(ev) => utilService.stopPropagation(ev)}
            className="sbs-popup"
          >
            <div className="popup-header">
              <span>עריכת תאריך סיום</span>
              <div
                className="close-popup"
                onClick={() => setEditFinishDate(null)}
              >
                <SbsIcons icon="close"></SbsIcons>
              </div>
            </div>
            <div className="popup-body">
              <input
                className="sbs-input"
                type="date"
                onKeyDown={(ev) => {
                  ev.preventDefault();
                }}
                value={(editFinishDateValue && editFinishDateValue) || ""}
                onChange={({ target }) => {
                  setEditFinishDateValue(target.value);
                }}
              />
            </div>
            <div className="popup-footer">
              <button
                className="button-primary"
                onClick={() => handleChangeFinishDate()}
              >
                שמירה
              </button>
              {/* <button className='button-secondary' onClick={() => setAskDeleteExercises(null)} >לא</button> */}
            </div>
          </div>
        </div>

        <button
          onClick={() => {
            navigate("/view-customer");
          }}
          className="sbs-flow-btn"
        >
          <SbsIcons icon="add" />
        </button>
      </div>
    </div>
  );
}
