import { errorService } from "./errorService";
import httpService from "./httpService";
import { utilService } from "./utilService";

export const mealService = {
  get,
  remove,
  edit,
  add,
  getById,
  getEmpty,
  toOrData,
  toNivData,
  reOrder,
};

let meals = [];

const ENDPOINT = "nutrition/meal_templates";

function getEmpty() {
  return {
    id: "",
    title: "",
    type: "Breakfast",
    description: "",
    products: [],
  };
}

function toOrData(meal) {
  const { id, title, type, description } = meal;
  console.log('meal', meal)
  const products = meal.items.map(({ item, amount }) => {
    return {
      id: item.id,
      title: item.name,
      type: item.type,
      numCalorie: item.calories,
      numCarbohydrate: item.carbs,
      numFat: item.fats,
      numProtein: item.proteins,
      numFiber: item.fiber,
      remarks: item.remarks,
      numSodium: item.sodiums,
      amount: item.amount,
	  fixedAmount: amount,
    };
  });
  return {
    id,
    title,
    type,
    description,
    products,
  };
}

function toNivData(meal) {
  const { title, type, description, products } = meal;
  return {
    title,
    type,
    description,
    items: products.map((product) => ({
      id: product.id,
      amount: parseFloat(product.fixedAmount || 1),
    })),
  };
}

async function get() {
  const res = await httpService.get(ENDPOINT);
  const meals = res.data.map((meal) => {
    console.log(meal);
    const { id, title, type, description } = meal;
    const products = meal.items.map((item) => {
      return {
        id: item.id,
        title: item.name,
        type: item.type,
        numCalorie: item.calories,
        numCarbohydrate: item.carbs,
        numFat: item.fats,
        numProtein: item.proteins,
        numFiber: item.fiber,
        remarks: item.remarks,
        numSodium: item.sodiums,
        amount: item.amount,
      };
    });
    return {
      id,
      title,
      type,
      description,
      products,
    };
  });
  return meals;
}

async function remove(id) {
  try {
    const res = await httpService.delete(ENDPOINT + `/${id}`);
    errorService.updateError({
      msg: "ארוחה נמחקה",
      type: "warning",
      time: Date.now(),
    });
    return res;
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function edit(editedMeal) {
  try {
    const { id, title, type, description, products } = editedMeal;
	console.log(products)
    const body = {
      id,
      title,
      type,
      description,
      items: products.map((product) => ({
        id: product.id,
        amount: parseFloat(product.fixedAmount || 1),
      })),
    };
    const res = await httpService.put(ENDPOINT, body);
    errorService.updateError({
      msg: "הארוחה עודכנה",
      type: "success",
      time: Date.now(),
    });
    return res;
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function add(newMeal) {
  try {
    const { title, type, description, products } = newMeal;
    const body = {
      title,
      type,
      description,
      items: products.map((product) => ({
        id: product.id,
        amount: parseFloat(product.fixedAmount || 1),
      })),
    };
    const res = await httpService.post(ENDPOINT, body);
    errorService.updateError({
      msg: "הארוחה נוספה",
      type: "success",
      time: Date.now(),
    });
    return res;
  } catch {
    errorService.updateError({
      msg: "משהו קרה",
      type: "danger",
      time: Date.now(),
    });
  }
}

async function getById(templateid) {
  const res = await httpService.get(ENDPOINT + `/${templateid}`);
  console.log("RESDATA", res.data);
  const { id, title, type, description, items } = res.data;
  const products = items.map((item) => {
    return {
      id: item.id,
      title: item.name,
      type: item.type,
      numCalorie: item.calories,
      numCarbohydrate: item.carbs,
      numFat: item.fats,
      numProtein: item.proteins,
      numFiber: item.fiber,
      remarks: item.remarks,
      numSodium: item.sodiums,
      amount: item.amount,
    };
  });
  const meal = {
    id,
    title,
    type,
    description,
    products,
  };
  return meal;
}

async function reOrder(order) {
  const res = await httpService.put(ENDPOINT + `/reorder`, {
    MealIDs: order,
  });

  return res.data;
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
