import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { async } from "rxjs";
import SbsTable from "../../components/SbsTable";
import httpService from "../../services/httpService";
import { mealService } from "../../services/mealService";
import { menuTemplateService } from "../../services/menuTemplateService";

export function ViewMenuTemplate() {
    const [menuTemplate, setMenuTemplate] = useState(menuTemplateService.getEmpty());
    const [allMeals, setAllMeals] = useState([]);
    const [menuMeals, setMenuMeals] = useState([]);
    const [allMealsLoad, setAllMealsLoad] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const { templateId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        loadMeals();
    }, [])

    useEffect(() => {
        getMealTemplate();
    }, [templateId])




    useEffect(() => {
        setMenuMeals(
            menuTemplate.mealsId.map(id => allMeals.find(meal => meal.id === id)).filter(meal => meal)
        )
    }, [menuTemplate, allMeals])

    const getMealTemplate = async () => {
        if (!templateId) return;
        const menuTemplate = await menuTemplateService.getById(templateId);
        console.log(menuTemplate);
        setMenuTemplate(menuTemplate);
    }


    const loadMeals = async () => {
        const meals = await mealService.get();
        console.log(meals);
        setAllMeals(meals)
    }

    const onOptionAskAddMeal = (id) => {
        setMenuTemplate(prev => ({
            ...prev,
            mealsId: [
                ...prev.mealsId,
                id
            ]
        }))
    }
    const onOptionAskRemoveMeal = (id) => {
        console.log(id);
        const idIndex = menuTemplate.mealsId.findIndex(fId => fId === id);
        setMenuTemplate(prev => {
            return {
                ...prev,
                mealsId: prev.mealsId.filter((_, idx) => idx !== idIndex)
            }
        })
    }

    const onSave = async () => {
        setIsLoad(true);
        if (!menuTemplate.id) await menuTemplateService.add(menuTemplate)
        else await menuTemplateService.edit(menuTemplate)
        setIsLoad(false);
        navigate('/manage-menu-templates')
    }

    console.log(menuMeals);
    console.log(menuTemplate);

    return (
        <div className='main-container page'>
            <div className='view-menu-page flex column gap-1'>
                <div className="flex column" >
                    <span>שם התפריט</span>
                    <div>
                        <input
                            className='sbs-input'
                            type="text"
                            value={menuTemplate?.title}
                            onChange={({ target }) => { setMenuTemplate(prev => ({ ...prev, title: target.value })) }}
                            placeholder="תיאור התפריט"
                        />

                    </div>
                </div>
                <div className="flex column" >
                    <span>תיאור</span>
                    <div>
                        <textarea
                            className='sbs-input'
                            type="text"
                            value={menuTemplate.description}
                            onChange={({ target }) => { setMenuTemplate(prev => ({ ...prev, description: target.value })) }}
                            placeholder="שם התפריט"
                        />
                    </div>
                </div>

                <SbsTable
                    columnMap={{
                        title: "שם הארוחה",
                        description: "תיאור",
                    }}
                    isHaveOptions={true}
                    isLoad={allMealsLoad}
                    metaData={menuMeals}
                    options={[
                        { icon: 'delete', cb: onOptionAskRemoveMeal },
                    ]}
                />

                <SbsTable
                    columnMap={{
                        title: "שם הארוחה",
                        description: "תיאור",
                    }}
                    isHaveOptions={true}
                    isLoad={allMealsLoad}
                    metaData={allMeals}
                    options={[
                        { icon: 'add', cb: onOptionAskAddMeal },
                    ]}
                />

                <button
                    onClick={(() => { onSave() })}
                    className="button-primary"
                    disabled={isLoad || !menuTemplate?.title}
                >
                    שמור ארוחה
                </button>


            </div>
        </div>
    )
}