import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { utilService } from "../../../services/utilService";
import uploadImg from '../../../assets/imgs/upload-img.png'
import { salaryService } from "../../../services/salaryService";
import { globalVariable } from "../../../services/globalVariable";


export default function AskShowPaycheckPopup({ askShowPaycheck, setAskShowPaycheck }) {

    const [isOpen, setIsOpen] = useState(false);
    console.log(askShowPaycheck);


    useEffect(() => {
        if (askShowPaycheck) setIsOpen(true);
        else setIsOpen(false);
    }, [askShowPaycheck])

    const onUploadFiles = async (ev) => {
        console.log(ev.target.files);
        const file = ev.target.files[0];
        await salaryService.addPaycheck(askShowPaycheck.id, file);
        setAskShowPaycheck(null);
    }

    const dowlandFile = async () => {
        const url = `${globalVariable.BASE_URL}users/user_salaries/${askShowPaycheck.id}/${askShowPaycheck.file}`;
        const aElement = document.createElement('a');
        aElement.href = url;
        aElement.target = '_blank';
        aElement.download = url;
        document.body.appendChild(aElement);
        aElement.click();
        document.body.removeChild(aElement);
        setAskShowPaycheck(null);
    }

    const deleteFile = async () => {
        await salaryService.removePaycheck(askShowPaycheck.id);
        setAskShowPaycheck(null);
    }

    return (
        <div onClick={() => setAskShowPaycheck(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>תלוש שכר</span>
                    <div className='close-popup' onClick={() => setAskShowPaycheck(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                {
                    askShowPaycheck &&
                    <div className='popup-body'>
                        {
                            !askShowPaycheck.file ? (

                                <div className="files-upload-container">
                                    <div className="not-file-upload">
                                        <img className="empty-image" src={uploadImg} alt="upload image" />
                                        <span>בחר קבצים <span>או גרור אותם לכאן</span></span>
                                    </div>
                                    <input type="file" multiple={false} className="files-upload" onChange={onUploadFiles} />
                                </div>
                            ) : (
                                <div className="flex gap-1" >
                                    <button onClick={dowlandFile} className="button-primary" >הורד</button>
                                    <button onClick={deleteFile} className="button-secondary">מחיקה</button>
                                </div>
                            )

                        }
                    </div>
                }
            </div>
        </div>
    )
}