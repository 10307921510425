import _ from "lodash";
import { utilService } from "../../../services/utilService";

const SuperSetModal = ({
  multiSetModal,
  setMultiSetModal,
  plan,
  execises,
  setPlan,
}) => {
  const { trainExeciseId, trainId } = multiSetModal;

  const updateTrain = plan.trains.find((train) => train.id === trainId);
  if (!updateTrain) return <div></div>;

  const excs = Object.values(updateTrain?.trainExercisesMap || {}) || [];
  const map =
    excs.reverse().filter((trainExecise) => trainExecise.type === "Normal") ||
    [];

  console.log(map, execises);

  const firstType = updateTrain.exerciseTypesOrder[0];
  const execiseIds = updateTrain.exerciseTypesMap[firstType].execiseIds;

  const handeChoose = (id, newId) => {
    setPlan((plan) => {
      const { trainExercisesMap } = updateTrain;

      const newTrainExercisesMap = swapObjectsById(
        trainExercisesMap,
        id,
        newId,
        trainExeciseId
      );

      let ids = execiseIds;
      if (newId) {
        ids = ids.filter((idd) => idd !== id);
        ids = insert(ids, ids.findIndex((id) => id === trainExeciseId) + 1, id);
      } else {
        ids = ids.filter((idd) => idd !== id);
        ids = [...ids, id];
      }

	  console.log("DEV", {
		ids,
		newTrainExercisesMap
	  });

      const newTrains = plan.trains.map((trainItem) =>
        trainItem.id === trainId
          ? {
              ...trainItem,
              trainExercisesMap: newTrainExercisesMap,
              exerciseTypesMap: {
                ...trainItem.exerciseTypesMap,
                [firstType]: {
                  ...trainItem.exerciseTypesMap[firstType],
                  execiseIds: ids,
                },
              },
            }
          : trainItem
      );

      return {
        ...plan,
        trains: newTrains,
      };
    });

    setMultiSetModal(false);
  };

  const handleCancel = () => {
    setPlan((plan) => {
      const newTrainExercisesMap = plan.trains.find(
        (train) => train.id === trainId
      ).trainExercisesMap;
      newTrainExercisesMap[trainExeciseId].type = "Normal";
      newTrainExercisesMap[trainExeciseId].superSet = 0;

      const newTrains = plan.trains.map((trainItem) =>
        trainItem.id === trainId
          ? {
              ...trainItem,
              trainExercisesMap: newTrainExercisesMap,
            }
          : trainItem
      );

      return {
        ...plan,
        trains: newTrains,
      };
    });

    setMultiSetModal(false);
  };

  return (
    <div
      onClick={handleCancel}
      className={
        !!multiSetModal
          ? "sbs-popup-container open"
          : "sbs-popup-container close"
      }
    >
      <div
        onClick={(ev) => utilService.stopPropagation(ev)}
        className="sbs-popup"
      >
        <div className="popup-header">
          <span>בחירת תרגיל שני</span>
        </div>
        <div className="popup-body excercises">
          {map &&
            map.map((exc) => (
              <div
                className="excercise"
                key={exc.id}
                onClick={() => {
                  console.log("DEV", exc);
                  //get the closest object id
                  let newId = execiseIds.findIndex(
                    (id) => id === trainExeciseId
                  );
                  // Last item
                  if (newId === execiseIds.length - 1) {
                    handeChoose(exc.id.toString(), null);
                  } else {
                    newId = execiseIds[newId + 1];

                    console.log("oldId", exc.id, "newId", newId, execiseIds);
                    handeChoose(exc.id.toString(), newId);
                  }
                }}
              >
                {execises.find((e) => e.id === parseInt(exc.execiseId))?.title}
              </div>
            ))}
        </div>
        <div className="popup-footer">
          <button className={"button-primary"} onClick={handleCancel}>
            ביטול
          </button>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-extend-native
const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

function swapObjectsById(obj, id1, id2, trainExeciseId) {
  console.log("or the king", {
    obj,
    id1,
    id2,
    trainExeciseId,
  });

  obj[id1].type = "SuperSet";
  obj[id1].superSet = trainExeciseId;
//   obj[id1].superSetChild = true;

  obj[trainExeciseId].superSet = id1;

  return obj;

  if (!id2) {
    let array = _.toPairs(obj);

    console.log("DEV", array, id1);

    const oldObj = {
      ...array.find((item) => item[0] === id1)[1],
    };

    console.log("DEV, removed obj", oldObj);

    array = [...array.filter((item) => item[0] !== id1)];

    console.log("DEV, after remove", array);

    const newTrain = {
      ...oldObj,
      type: "SuperSet",
      superSet: trainExeciseId, // the id of the first excercise
      superSetChild: true,
    };

    console.log("DEV DEV", newTrain);
    const currentIndex = array.findIndex((item) => item[0] === trainExeciseId);
    console.log("DEV currentIndex", currentIndex);

    array[currentIndex][1].superSet = id1;

    const newItem = [id1, newTrain];
    console.log("DEV newItem", newItem);

    array = insert(array, currentIndex + 1, newItem);
    console.log("DEV array", array);

    // const index = array.findIndex((item) => item[1].id === id1);
    // array[index] = [trainExeciseId, newTrain];
    // console.log("DEV index", index, array[index], array);

    // const linkedExc = newObj[trainExeciseId];
    // linkedExc.superSet = newTrain.id;

    console.log("dEV", [...array], _.fromPairs([...array]));
    return _.fromPairs([...array]);
  } else {
    // Retrieve the objects associated with the provided IDs
    const object1 = obj[id1];
    const object2 = obj[id2];

    console.log("object1", object1, id1);
    console.log("object2", object2, id2);

    const linkedExc = obj[trainExeciseId];
    console.log("linkedExc", linkedExc);

    // Check if both objects exist
    if (object1 && object2) {
      // Swap the objects
      obj[id1] = object2;
      obj[id2] = { ...object1, type: "SuperSet", superSet: trainExeciseId };
      linkedExc.superSet = object1.id;
      console.log("plan.trains", object1, obj);
    } else {
      console.log("obj", obj);
    }

    return obj;
  }
}

export default SuperSetModal;
