import { MdEmojiNature } from "react-icons/md";
import MealCreator from "../../../components/meal-creator/MealCreator";
import { utilService } from "../../../services/utilService";

export default function MenuSummary({
    menu,
    customer,
    previousStep,
    onSave
}) {




    return (
        <div>
            <div className="flex column gap-0-5">
                <span className="sbs-title">סיכום תפריט  {menu.title}</span>
                <span className="sbs-subtitle">{menu.description}</span>
                <span>תפריט חדש ללקוח {customer.firstName} {customer.lastName}</span>
                <span>תאריך תחילת תפריט {utilService.timestampToDDMMYYYY(menu.startDate)}</span>
                <span>תאריך סיום תפריט {utilService.timestampToDDMMYYYY(menu.endDate)}</span>
                <span>כמות מים ביום  {menu.dailyWaterInLiter} ליטרים</span>
            </div>
            <div className="divider" ></div>
            <div className="flex column gap-1">
                {
                    menu.meals.map((meal, idx) => (
                        <div className="flex column gap-1" key={idx}>
                            <span className="sbs-title" >{meal.title}</span>
                            <MealCreator
                                meal={meal}
                                setMeal={() => { }}
                                isEditable={false}
                            />
                        </div>
                    ))
                }
                <div className="flex column" >
                    <button onClick={onSave} className="button-primary" >שמור תפריט</button>
                </div>
                <div className="flex gap-1" >
                    <button onClick={() => { previousStep() }} className="button-secondary" >חזור לעריכת ארוחות</button>
                </div>
            </div>
        </div>
    )
}