import { errorService } from './errorService';
import httpService from './httpService'
import { utilService } from './utilService';

export const aerobicService = {
    get,
    remove,
    // getRandom,
    // getEmpty,
    edit,
    add,
	changeImage,
    getImage
}

const ENDPOINT = 'training/aerobic-exercises'

let aerobics = [
    {
        id: 'a101',
        title: 'ריצה',
        description: 'לרוץ'
    },
    {
        id: 'a102',
        title: 'אופניים',
        description: 'לדווש באופניים'
    },
]

// function getEmpty() {
//     return {
//         "id": "",
//         "sentence": "",
//         "groups": "",
//     };
// }

async function get() {
    const response = await httpService.get(ENDPOINT)
    return response.data
}

async function remove(id) {
    try {

        const response = await httpService.delete(ENDPOINT + '/' + id)
        errorService.updateError({ msg: 'אימון האירובי נמחק', type: 'warning', time: Date.now() })
        return response.data
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function edit(aerobic) {
    try {
        const response = await httpService.put(ENDPOINT, aerobic)
        errorService.updateError({ msg: 'אימון האירובי עודכן', type: 'success', time: Date.now() })
        return response.data
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function add(aerobic) {
    try {

        if (!aerobic.video) aerobic.video = 'https://www.youtube.com/watch?v=FyHo8EQWshc'
        const response = await httpService.post(ENDPOINT, aerobic)
        errorService.updateError({ msg: 'אימון האירובי נוסף', type: 'success', time: Date.now() })
        return response.data
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })

    }
}

async function changeImage(id, file) {
    try {
        const body = new FormData();
        body.append('image', file);
        body.append('id', id);
        const res = await httpService.post(`${ENDPOINT}/image`, body);
        errorService.updateError({ msg: 'תמונה עודכנה', type: 'success', time: Date.now() })
        return res;
    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

async function getImage(name) {
    try {
        if (!name) return null;
        const res = await httpService.get(`general/getBase64/${name.replace('/api/general/get_media_file?filename=', '/')}`);
        console.log(res);
        return res.data

    } catch {
        errorService.updateError({ msg: 'משהו קרה', type: 'danger', time: Date.now() })
    }
}

// async function getRandom() {
//     return motivationalSentences[utilService.getRandomIntInclusive(0, motivationalSentences.length - 1)];
// }
