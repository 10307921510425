import httpService from "./httpService"

export const logService = {
    get
}

const ENDPOINT = 'customers/tracking'


async function get(costumerId) {
    const res = await httpService.get(ENDPOINT + '/' + costumerId)
    return res.data
}