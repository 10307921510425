import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";
import SbsTable from "../../components/SbsTable";
import { employeeService } from "../../services/employeeService";

var searchTimeOut = null;
export default function ManageEmployees(props) {

    const [allEmployees, setAllEmployees] = useState([]);
    const [filterEmployees, setFilterEmployees] = useState([]);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const [isTableLoad, setIsTableLoad] = useState(false);
    const [isSearchLoad, setIsSearchLoad] = useState(false);

    useEffect(() => {
        document.title = "Step By Step - Employees";
    }, [])

    useEffect(() => {
        updateAllEmployees();
    }, [])

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
            setIsSearchLoad(true);
        }
        searchTimeOut = setTimeout(() => {
            updateFilterEmployees();
            setIsSearchLoad(false);
        }, 500)
    }, [search, allEmployees])

    const updateAllEmployees = async () => {
        setIsTableLoad(true);
        const employees = await employeeService.get();
        setAllEmployees(employees);
    }

    const updateFilterEmployees = async () => {
        setIsTableLoad(false);
        const filterEmployees = allEmployees
            .map(employee => ({
                ...employee,
                status: employee.isActive ? 'פעיל' : 'לא פעיל',
                type: employee.isPremium ? 'פרימיום' : 'בסיסי',
                fullName: employee.firstName + ' ' + employee.lastName
            }))
            .filter(employee => employee.fullName.includes(search))
        setFilterEmployees(filterEmployees);
    }

    const editCostumer = (id) => {
        navigate('/view-employee/' + id)
    }

    return (
        <div className='main-container page'>
            <div className='manage-employees-page'>
                <div className='header'>
                    <span className='sbs-title'>ניהול עובדים</span>
                    <span className='sbs-subtitle'>סה"כ {allEmployees.length} עובדים</span>
                </div>

                <input
                    className={`sbs-input search ${isSearchLoad ? 'load' : 'no-load'}`}
                    type="text"
                    value={search}
                    onChange={({ target }) => { setSearch(target.value) }}
                    placeholder="חפש לקוח"
                />

                <SbsTable
                    columnMap={{ "email": 'כתובת דוא"ל', "fullName": "שם מלא", role: 'תפקיד', "status": "סטטוס", "phone": "טלפון" }}
                    isLoad={isTableLoad}
                    isHaveOptions={true}
                    metaData={filterEmployees.filter(employee => employee.id !== 11)}
                    options={[
                        { icon: 'edit', cb: editCostumer },
                    ]}
                ></SbsTable>

                <button onClick={() => { navigate("/view-employee") }} className='sbs-flow-btn'><SbsIcons icon="add" /></button>

            </div>
        </div>
    )
}