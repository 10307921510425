import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { motivationalSentenceService } from "../../../services/motivationalSentenceService";
import { utilService } from "../../../services/utilService";

export default function AskEditPopup({ editAskedEditSentence, askEditSentence, setAskEditSentence, groups }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isSaveLoad, setIsSaveLoad] = useState(false);
    const [newSentenceText, setNewSentenceText] = useState("");
    const [newSentenceGroupIds, setNewSentenceGroupIds] = useState([]);

    const onSave = () => {
        setIsSaveLoad(true);
        editAskedEditSentence(newSentenceText, newSentenceGroupIds)
        setAskEditSentence(null)
        setIsSaveLoad(false)
    }


    useEffect(() => {
        if (askEditSentence) {
            setNewSentenceText(askEditSentence.sentence);
            setNewSentenceGroupIds(askEditSentence.groups);
            setIsOpen(true);
        }
        else setIsOpen(false);
    }, [askEditSentence])

    const toggleGroup = (groupId) => {
        let updateSentenceGroupIds = null;
        if (newSentenceGroupIds.some(groupIdItem => groupIdItem === groupId)) {
            updateSentenceGroupIds = newSentenceGroupIds.filter(groupIdItem => groupIdItem !== groupId);
        } else updateSentenceGroupIds = [...newSentenceGroupIds, groupId];
        setNewSentenceGroupIds(updateSentenceGroupIds);
    }



    return (
        <div onClick={() => setAskEditSentence(null)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utilService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    {
                        askEditSentence?.id ? (
                            <span>ערוך את {askEditSentence?.sentence}</span>
                        ) : (
                            <span>צור משפט מוטיבציה</span>
                        )

                    }
                    <div className='close-popup' onClick={() => setAskEditSentence(null)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    <input
                        className='sbs-input'
                        type="text"
                        value={newSentenceText}
                        onChange={({ target }) => { setNewSentenceText(target.value) }}
                        placeholder="משפט"
                    />

                    <form className="groups-container">
                        {
                            groups.map(group => (
                                <div key={group.id} className="group sbs-checkbox-container">
                                    <input
                                        id={group.id}
                                        name={group.id}
                                        type="checkbox"
                                        checked={newSentenceGroupIds.some(groupId => groupId === group.id)}
                                        onChange={({ target }) => toggleGroup(group.id)}
                                    />
                                    <label htmlFor={group.id}>{group.title}</label>
                                </div>
                            ))
                        }
                    </form>

                </div>
                <div className='popup-footer'>
                    <button disabled={!newSentenceText} className='button-primary' onClick={() => onSave()} >שמירה</button>
                    {/* <button className='button-secondary' onClick={() => setAskEditSentence(null)} >לא</button> */}
                </div>
            </div>
        </div>
    )
}